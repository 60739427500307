import { ClaimsEnum, Difficulty, PeriodsEnum, TriggersOperation, Visibility } from 'types';
import { QuestFields } from 'types/Forms';

export const TriggersUsersState = [
  { value: 'OR', label: 'At least one' },
  { value: 'AND', label: 'All of them' },
];

export const DaysState = [
  { value: PeriodsEnum.days, label: 'Days' },
  { value: PeriodsEnum.weeks, label: 'Weeks' },
  { value: PeriodsEnum.months, label: 'Months' },
];

export const PeriodsState = [
  { value: 'within_last', label: 'Within the last' },
  { value: 'ever', label: 'Ever' },
];

export const PerformState = [
  { value: 'true', label: 'Has been performed' },
  { value: 'false', label: 'Has not been performed' },
];

export const TriggersMock = [
  {
    name: '',
    perform: 'true',
    least: '1',
    times: 'within_last',
    period_number: '',
    period: 'days',
  },
];

export const ClaimTypeMock = [
  {
    id: 1,
    title: 'Instant enrollment',
    value: ClaimsEnum.auto,
  },
  {
    id: 2,
    title: 'Manual claim',
    value: ClaimsEnum.manual,
  },
];

export const RewardsMock = [
  {
    reward_type: '',
    claim_type: 'AUTO',
  },
];

export const MetadataMock = [
  {
    key: '',
    value: '',
  },
];

export const WebhookMock = [
  {
    value: '',
  },
];

export const RadioDiffMock = [
  {
    id: 1,
    title: 'Easy',
    value: Difficulty.easy,
  },
  {
    id: 2,
    title: 'Medium',
    value: Difficulty.medium,
  },
  {
    id: 3,
    title: 'Hard',
    value: Difficulty.hard,
  },
];

export const RadioVisibilityMock = [
  {
    id: 6,
    title: 'Public',
    value: Visibility.public,
  },
  {
    id: 9,
    title: 'Hidden',
    value: Visibility.hidden,
  },
];

export const RadioDurationMock = [
  {
    id: 20394283,
    title: 'Set closing date',
    value: 'closing_date',
  },
  {
    id: 2098,
    title: 'Perpetual',
    value: 'perpetual',
  },
];
export const DefaultQuestState = {
  logo: '',
  sprite: '',
  name: '',
  shortDescription: '',
  description: '',
  difficulty: Difficulty.easy,
  topics: [],
  visibility: Visibility.public,
  completionsLimit: '',
  duration: 'perpetual',
  startDate: new Date(),
  endDate: null,
  availableRegions: [],
  logicalOperator: TriggersOperation.and,
  triggers_list: TriggersMock,
  rewards_list: [],
  metadata_list: [],
  webhook_list: [],
} as QuestFields;
