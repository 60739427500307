import { configureStore, ThunkAction } from '@reduxjs/toolkit';
import { Action } from 'redux';

import project from './project';
import user from './user';

const makeStore = () =>
  configureStore({
    reducer: {
      user,
      project,
    },
    devTools: true,
  });

const store = makeStore();

export type AppStore = ReturnType<typeof makeStore>;
export type AppState = ReturnType<AppStore['getState']>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, AppState, unknown, Action>;

// export const wrapper = createWrapper<AppStore>(makeStore);

export default store;
