import React from 'react';
import { useNavigate } from 'react-router-dom';

import { IProject } from 'hooks/useProjects';

import styles from './ProjectCard.module.scss';

export interface IProjectCardProps {
  data: IProject;
}

const ProjectCard = ({ data }: IProjectCardProps) => {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => {
        navigate(`/project/${data.id}/quests`);
      }}
      className={styles.card}
    >
      <div className={styles.card__preview}>
        {data.cover ? (
          <div
            className={styles.card__preview__image}
            style={{ backgroundImage: `url(${data.cover})` }}
          />
        ) : null}
        <p className={styles.card__preview__id}>ID {data.xsollaProjectId}</p>
      </div>
      <div className={styles.card__data}>
        <p className={styles.card__data__title}>{data.title}</p>
        <p className={styles.card__data__content}>{data.content}</p>
        <p className={styles.card__data__id}>ID {data.xsollaProjectId}</p>
      </div>
      <div className={styles.card__info}>
        <div className={styles.card__info__top}>
          <div className={styles.stat}>
            <p className={styles.stat__value}>{data.numberQuests}</p>
            <p className={styles.stat__title}>
              active {data.numberQuests === 1 ? 'quest' : 'quests'}
            </p>
          </div>
        </div>
        <div className={styles.card__info__bottom}>
          <div className={styles.stat}>
            <p className={styles.stat__value}>{data.numberUsers}</p>
            <p className={styles.stat__title}>{data.numberUsers === 1 ? 'user' : 'users'}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProjectCard;
