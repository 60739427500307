import throttle from 'lodash/throttle';
import uniqBy from 'lodash/uniqBy';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { ApiMethod, ApiUrlGet } from '../types/Api';

import useApi from './useApi';
import { IProject, IProjectsResponse } from './useProjects';

interface IUserProjectsHook {
  userId: string;
  params?: object;
  validation?: boolean;
}

export default function useUserProjects({ userId, params = {}, validation }: IUserProjectsHook) {
  const [limit] = useState(20);
  const [offset, setOffset] = useState(0);
  const [items, setItems] = useState<IProject[]>([]);
  const [hasMore, setHasMore] = useState(false);

  const { data, sendRequest, error, resetData, isLoading } = useApi<IProjectsResponse>({
    method: ApiMethod.get,
    url: ApiUrlGet.userProjects.replace(':id', userId),
    options: { params: { limit, offset, ...params }, validation },
  });

  useEffect(() => {
    if (data?.data && data?.data?.length > 0) {
      setItems(offset === 0 ? data.data : uniqBy([...items, ...data.data], 'id'));

      setHasMore(data.data.length === limit);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, limit]);

  const handleScroll = useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        if (scrollHeight - scrollTop - clientHeight < 100 && !isLoading) {
          if (hasMore) {
            setOffset(offset + limit);
          }
        }
      }
    },
    [isLoading, hasMore, offset, limit],
  );

  const throttledHandleScroll = useMemo(() => throttle(handleScroll, 200), [handleScroll]);

  const reload = () => {
    setItems([]);
    resetData();
    setOffset(0);
    setHasMore(true);
    sendRequest({});
  };

  return {
    data,
    items,
    error,
    isLoading,
    sendRequest,
    handleScrollLoad: throttledHandleScroll,
    reload,
  };
}
