import throttle from 'lodash/throttle';
import uniqBy from 'lodash/uniqBy';
import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  StorePeriodType,
  RecurrentScheduleType,
  ExpirationPeriodType,
  PriceType,
  VCPriceType,
} from 'types/Reward';

import { ApiMethod, ApiUrlGet } from '../types/Api';

import useApi from './useApi';

export interface IVirtualItem {
  id: string;
  sku: string;
  name: {
    en: string;
  };
  type: string;
  description: {
    en: string;
  };
  image_url: string;
  long_description: string | null;
  attributes: [];
  is_free: boolean;
  order: number;
  groups: [];
  regional_prices: [];
  prices: PriceType[];
  media_list: [];
  vc_prices: VCPriceType[];
  is_enabled: boolean;
  is_show_in_store: boolean;
  regions: [];
  limits: {
    per_user: {
      total?: number;
      available?: number;
      reserved?: number;
      sold?: number;
    } | null;
    per_item: {
      total?: number;
      available?: number;
      reserved?: number;
      sold?: number;
    } | null;
    recurrent_schedule: RecurrentScheduleType;
  };
  periods: StorePeriodType[];
  inventory_options: {
    consumable: boolean;
    expiration_period: ExpirationPeriodType | null;
  };
  vc_pricing?: boolean;
  limits_enable?: boolean;
  default_currency?: string;
}

export type IVirtualCurrency = IVirtualItem;

export interface IVirtualItemsResponse {
  items: IVirtualItem[];
}

interface IVirtualItemsHook {
  projectId: string;
  params?: object;
  validation?: boolean;
}

export default function useVirtualItems({ projectId, params = {}, validation }: IVirtualItemsHook) {
  const [limit] = useState(20);
  const [offset, setOffset] = useState(0);
  const [items, setItems] = useState<IVirtualCurrency[]>([]);
  const [hasMore, setHasMore] = useState(false);
  const { data, error, sendRequest, resetData, isLoading } = useApi<IVirtualItemsResponse>({
    method: ApiMethod.get,
    url: ApiUrlGet.virtualItems.replace(':id', projectId),
    options: { params: { limit, offset, ...params }, validation },
  });

  useEffect(() => {
    if (data?.items && data?.items?.length > 0) {
      setItems(offset === 0 ? data.items : uniqBy([...items, ...data.items], 'sku'));

      setHasMore(data.items.length === limit);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, limit]);

  const handleScroll = useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        if (scrollHeight - scrollTop - clientHeight < 100 && !isLoading) {
          if (hasMore) {
            setOffset(offset + limit);
          }
        }
      }
    },
    [isLoading, hasMore, offset, limit],
  );

  const throttledHandleScroll = useMemo(() => throttle(handleScroll, 200), [handleScroll]);

  const reload = () => {
    setItems([]);
    resetData();
    setOffset(0);
    setHasMore(true);
  };

  return {
    data,
    items,
    error,
    handleScrollLoad: throttledHandleScroll,
    sendRequest,
    isLoading,
    reload,
  };
}
