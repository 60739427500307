import { UserRole } from 'types';

export enum AuthApiUrlGet {
  profile = '/auth/user/me',
}

export enum AuthApiUrlPost {
  login = '/auth/login',
  logout = '/auth/logout',
  verify = '/auth/verify',
  forgotPassword = '/auth/forgot-password',
  resetPassword = '/auth/reset-password',
  signUp = '/auth/register',
  ownerVerify = '/auth/verify/owner',
}

export enum AuthApiUrlPut {
  unknown = 'unknown',
}

export enum AuthApiUrlDelete {
  logout = '/auth/logout',
}

export interface IUserProfile {
  // @TODO: extend
  id: number;
  role: UserRole;
  email: string;
}

export interface ILoginRequestBody {
  email: string;
  password: string;
  rememberMe: boolean;
}

export interface IVerifyRequestBody {
  password: string;
  confirmedPassword: string;
  email: string;
  inviteToken: string;
}

export interface ISignUpRequestBody {
  email: string;
  password: string;
  confirmedPassword: string;
}

export interface IResetPasswordRequestBody {
  password: string;
  confirmedPassword: string;
  email: string;
  resetToken: string;
}

export interface IForgotPasswordRequestBody {
  email: string;
  error?: any;
}

export interface IOwnerVerifyRequestBody {
  xsollaApiKey: string;
  merchantId: number;
  token: string;
}

export type IImportXsollProjectsRequestBody = {
  xsollaProjectIds: number[];
};
