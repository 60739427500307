import { TrashIcon } from '@radix-ui/react-icons';
import classnames from 'classnames';
import React from 'react';
import { Control, UseFormWatch, FieldArray } from 'react-hook-form';

import Label from 'components/common/Label';
import Input from 'components/common/NewInput';
import SingleSelect from 'components/common/SingleSelect';
import { Fields, QuestFieldsName, QuestTrigger } from 'types/Forms';

import { DaysState, PeriodsState, PerformState } from '../../../mock';

import styles from './TriggerItem.module.scss';

type TriggersProps = {
  control: Control<any>;
  watch: UseFormWatch<Fields>;
  index: number;
  update: (index: number, value: Partial<FieldArray<QuestTrigger, never>>) => void;
  remove: (index: number) => void;
  canRemove: boolean;
  item: QuestTrigger;
};

export const TriggerItem: React.FC<TriggersProps> = ({
  watch,
  index,
  update,
  remove,
  canRemove,
  control,
  item,
}) => {
  const triggers = watch(QuestFieldsName.triggersList);
  const onUpdate = (id: number, i: QuestTrigger, key: string, value: string) => {
    update(id, {
      ...i,
      [key]: value,
      name: triggers[id]?.name,
      least: triggers[id]?.least,
      period_number: triggers[id]?.period_number,
    });
  };

  const onRemove = (id: number) => {
    remove(id);
  };

  return (
    <div className={styles.trigger}>
      <div className={styles.trigger__header}>
        <p>TRIGGER {index + 1}</p>
        {canRemove && (
          <TrashIcon color='#0073F7' width={18} height={18} onClick={() => onRemove(index)} />
        )}
      </div>
      <Label label='Event details' />
      <div className={classnames(styles.trigger__content, styles['trigger__content--col-2'])}>
        <Input
          placeholder='Enter event key'
          control={control}
          name={`${QuestFieldsName.triggersList}.${index}.name`}
        />
        <SingleSelect
          update={(value) => onUpdate(index, item, 'perform', value)}
          options={PerformState}
          defaultValue={item.perform}
        />
      </div>
      <div className={classnames(styles.trigger__content, styles['trigger__content--col-6'])}>
        <p>At least</p>
        <Input
          control={control}
          name={`${QuestFieldsName.triggersList}.${index}.least`}
          type='number'
          placeholder='Number'
        />
        <p>Times</p>
        <SingleSelect
          update={(value) => onUpdate(index, item, 'times', value)}
          options={PeriodsState}
          defaultValue={item.times}
        />
        {triggers[index]?.times !== 'ever' && (
          <>
            <Input
              control={control}
              name={`${QuestFieldsName.triggersList}.${index}.period_number`}
              type='number'
              placeholder='Number'
            />
            <SingleSelect
              update={(value) => onUpdate(index, item, 'period', value)}
              options={DaysState}
              defaultValue={item.period}
            />
          </>
        )}
      </div>
    </div>
  );
};
