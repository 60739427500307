import { Difficulty, Visibility, UserRole } from 'types';
import {
  PriceType,
  LimitsType,
  StorePeriodType,
  ExpirationPeriodType,
  VCPriceType,
} from 'types/Reward';

export type Fields = {
  quests: QuestFields;
  virtualItem: VirtualItemFields;
  virtualCurrency: VirtualCurrencyFields;
  questsTable: QuestTableFields;
  project: ProjectFields;
  projectUpdate: ProjectUpdateFields;
  userInvite: UserInviteFields;
  addProjectToUser: AddProjectToUserFields;
};

export type AddProjectToUserFields = {
  projectId: string;
  role: UserRole;
};

export type CheckboxGroupItem = {
  checked: boolean | number;
  label: string;
  id: number;
  code?: string;
  continent?: string;
  parent?: string;
  countryId?: string;
};

export type QuestFields = {
  logo: string;
  sprite: string;
  name: string;
  shortDescription: string;
  description: string;
  difficulty: Difficulty | '';
  topics: CheckboxGroupItem[];
  visibility: Visibility;
  completionsLimit: string;
  duration: string;
  startDate: Date | null;
  endDate: Date | null;
  availableRegions: CheckboxGroupItem[];
  logicalOperator: string;
  triggers_list: QuestTrigger[];
  rewards_list: QuestReward[];
  metadata_list: QuestMetadata[];
  webhook_list: QuestWebhook[];
};

export type QuestMetadata = {
  key: string;
  value: string;
  id?: string;
};

export type QuestWebhook = {
  id?: string;
  value: string;
};

export type VirtualItemFields = {
  image_url?: string;
  sku: string;
  name: {
    en: string;
  };
  groups?: string[];
  description: {
    en: string;
  };

  inventory_options: {
    consumable: boolean;
    expiration_period?: ExpirationPeriodType | null;
  };
  // usage: string;
  is_free: boolean;
  prices: PriceType[];
  vc_pricing?: boolean;
  vc_prices: VCPriceType[];
  limits_enable?: boolean;
  limits: LimitsType;
  default_currency?: string;
  default_virtual_currency?: string;
  is_show_in_store: boolean;
  periods: StorePeriodType[];
  timezone?: string;
  storeTimezone?: string;
};

export type VirtualCurrencyFields = {
  image_url?: string;
  sku: string;
  name: {
    en: string;
  };
  is_hard: boolean;
  itemAttribute?: string;
  prices: PriceType[];
  vc_prices?: VCPriceType[];
};

export type RewardTrigger = {
  name: string;
  perform: string;
  least: string;
  times: string;
  period: string;
  period_number: string;
  id?: string;
};

export type QuestTrigger = {
  name: string;
  perform: string;
  least: string;
  times: string;
  period: string;
  period_number: string;
  id?: string;
};

export type QuestReward = {
  rewardId?: any;
  reward_type: string;
  claim_type: string;
  id?: string;
  custom?: string;
};

export type ProjectFields = {
  cover?: string;
  title: string;
  content?: string;
  xsollaProjectId: string;
  users: User[];
};

export type ProjectUpdateFields = {
  cover?: string;
  title: string;
  content?: string;
};

export type User = {
  email: string;
  role: UserRole;
};

export type QuestTableFields = {
  name: string;
};

export type UserInviteFields = {
  projectId: string;
  users: any;
};

export enum QuestFieldsName {
  sprite = 'quests.sprite',
  logo = 'quests.logo',
  topics = 'quests.topics',
  name = 'quests.name',
  shortDescription = 'quests.shortDescription',
  description = 'quests.description',
  difficulty = 'quests.difficulty',
  visibility = 'quests.visibility',
  completionsLimit = 'quests.completionsLimit',
  duration = 'quests.duration',
  startDate = 'quests.startDate',
  endDate = 'quests.endDate',
  availableRegions = 'quests.availableRegions',
  logicalOperator = 'quests.logicalOperator',
  triggersList = 'quests.triggers_list',
  rewardsList = 'quests.rewards_list',
  metadataList = 'quests.metadata_list',
  webHookList = 'quests.webhook_list',
}
