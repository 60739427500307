import axios, { AxiosInstance, AxiosResponse } from 'axios';

import { IErrorResponse } from '../types';
import { IAxiosMethods } from '../types/Api';

import { setupInterceptorsTo } from './interceptors';

export const headers = {
  'X-DeviceType': 'Web',
  'Content-Type': 'application/json',
  'X-Language': 'EN',
};

export const instance: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  timeout: 30000,
  withCredentials: true,
  headers,
});

setupInterceptorsTo(instance);

const responseBody = (response: AxiosResponse) => response;
const error = (err: IErrorResponse) => err;

const requests: IAxiosMethods = {
  get: async (url, options) => instance.get(url, options).then(responseBody).catch(error),
  post: async (url, body, options) =>
    instance.post(url, body, options).then(responseBody).catch(error),
  patch: async (url, body, options) =>
    instance.patch(url, body, options).then(responseBody).catch(error),
  put: async (url, body, options) =>
    instance.put(url, body, options).then(responseBody).catch(error),
  delete: async (url, options) => instance.delete(url, options).then(responseBody).catch(error),
};

const axiosSetup: IAxiosMethods = {
  ...requests,
};

export default axiosSetup;
