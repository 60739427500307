import React, { useState } from 'react';
import { Control, useFieldArray, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { ImageListType } from 'react-images-uploading';
import { v4 as uuidv4 } from 'uuid';

import Hint from 'components/common/Hint';
import ImageUploader from 'components/common/ImageUploader';
import Label from 'components/common/Label';
import Input from 'components/common/NewInput';
import SingleSelect from 'components/common/SingleSelect';
import Switch from 'components/common/Switch';
import useCurrencies from 'hooks/useCurrencies';
import { CountryCurrencyItem } from 'pages/createVirtualItem/components/priceSettings/countryCurrencies';
import { CurrencyItem } from 'pages/createVirtualItem/components/priceSettings/currencies';
import {
  CountryBasedCurrenciesMock,
  ItemPropertyTypeMock,
  getMockCurrency,
  DefaultCurrency,
} from 'pages/createVirtualItem/mock';
import { Fields } from 'types/Forms';
import { PriceType } from 'types/Reward';

import styles from './Information.module.scss';

type InformationProps = {
  control: Control<Fields>;
  watch: UseFormWatch<Fields>;
  setValue: UseFormSetValue<Fields>;
  logoImage: ImageListType | [];
  setLogoImage: (i: ImageListType) => void;
  spriteImage: ImageListType | [];
  setSpriteImage: (i: ImageListType) => void;
  isEdit?: boolean;
  errors?: any;
};

export const Information: React.FC<InformationProps> = ({
  control,
  isEdit,
  watch,
  setValue,
  errors,
}) => {
  const liItems = [<li key='1'>Use English</li>];
  const virtualCurrency = watch('virtualCurrency');
  const prices = virtualCurrency.prices;
  const currencyList = prices.filter((price) => price.country_iso === undefined);
  const countryBasedCurrencyList = prices.filter((price) => price.country_iso !== undefined);

  const { data: currencies } = useCurrencies();
  const [defaultCurrency] = useState<string>(DefaultCurrency);

  const { update, fields, insert, remove } = useFieldArray({
    control,
    name: 'virtualCurrency.prices',
  });
  const pricesData = fields as PriceType[];

  const onAddTrigger = (country: boolean, currency?: string) => {
    const price = country ? CountryBasedCurrenciesMock[0] : getMockCurrency(currency);
    insert(pricesData.length, { ...price, id: uuidv4() });
  };

  const onUpdate = (id: string, i: PriceType, key: string, value: string) => {
    const index = prices.findIndex((item) => item.id === id);

    update(index, {
      ...i,
      [key]: value,
      amount: prices[index]?.amount,
    });
  };

  const onRemove = (id: string) => {
    const index = prices.findIndex((item) => item.id === id);
    remove(index);
  };

  return (
    <div className={styles.info}>
      <p className={styles.info__title}>Basic settings</p>
      <div className={styles.info__content}>
        <div className={styles.info__content__image_uploader}>
          <div>
            <Label label='Image' />
            <ImageUploader
              name='virtualCurrency.image_url'
              title='Upload image'
              setValue={setValue}
              watch={watch}
              acceptType={['png', 'jpg', 'jpeg', 'webp']}
              maxFileSize={4e6}
            />
            <Hint>Use .png, .jpg or .webp file / 4MB max size</Hint>
          </div>
        </div>

        <Input
          label='SKU'
          name='virtualCurrency.sku'
          control={control}
          disabled={isEdit}
          placeholder='Enter reward SKU'
        />

        <div>
          <Input
            label='Currency name'
            name='virtualCurrency.name.en'
            control={control}
            placeholder='Enter currency name'
          />
          <ul className={styles.info__content__helper}>{liItems} </ul>
        </div>

        <div className={styles.info__content}>
          <div className={styles.wrapper}>
            <CurrencyItem
              control={control}
              watch={watch}
              update={onUpdate}
              remove={onRemove}
              prices={prices}
              currencyList={currencyList}
              addPrice={onAddTrigger}
              currencies={currencies || []}
              formKey='virtualCurrency'
              label='Price of one unit of virtual currency'
              errors={errors.virtualCurrency?.prices}
            />
          </div>
        </div>

        <div className={styles.info__content}>
          <div className={styles.wrapper}>
            <Label label='Default currency' />
            <SingleSelect
              disabled={true}
              update={(value) => setValue('virtualItem.default_currency', value)}
              options={currencies || []}
              placeholder='Select currency'
              defaultValue={defaultCurrency}
            />
          </div>
        </div>

        <div className={styles.info__content}>
          <div className={styles.wrapper}>
            <CountryCurrencyItem
              control={control}
              watch={watch}
              update={onUpdate}
              remove={onRemove}
              prices={prices}
              currencyList={countryBasedCurrencyList}
              addPrice={onAddTrigger}
              currencies={currencies || []}
              formKey='virtualCurrency'
              errors={errors.virtualCurrency?.prices}
            />
          </div>
        </div>

        <div>
          <Label label='Hard virtual currency' />
          <Switch
            label='Off'
            name={'virtualCurrency.is_hard'}
            control={control}
            value={virtualCurrency.is_hard}
          />
        </div>

        <div>
          <Label label='Item attribute (optional)' />
          <SingleSelect
            disabled={true}
            update={(value) => setValue('virtualCurrency.itemAttribute', value)}
            options={ItemPropertyTypeMock}
            placeholder='Select attribute'
            defaultValue='value'
          />
        </div>
      </div>
    </div>
  );
};
