import classnames from 'classnames';
import React from 'react';

import OptionalTooltip from 'components/common/OptionalTooltip';

import styles from './Label.module.scss';

type LabelProps = {
  label: string;
  optional?: string;
  className?: string;
};

const Label: React.FC<LabelProps> = ({ label, optional, className }) => {
  return (
    <div className={classnames(styles.label, className)}>
      {label}
      {!!optional && <OptionalTooltip text={optional} />}
    </div>
  );
};

export default Label;
