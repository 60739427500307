export const updateUTCOffset = (timeWithOffset: string, newOffset: string) => {
  const timeWithoutOffset = timeWithOffset.slice(0, 8);
  return `${timeWithoutOffset}${newOffset}`;
};
export const getUTCOffset = (timeWithOffset: string) => {
  return timeWithOffset.slice(8);
};

export const getTime = (timeWithOffset: string) => {
  return timeWithOffset.slice(0, 8);
};

export const getUTCOffsetForDate = (timeWithOffset: string) => {
  return timeWithOffset.slice(19);
};

export const updateUTCOffsetDateFormat = (dateString: string, timezoneOffset: string) => {
  const originalDate = new Date(dateString);
  const year = originalDate.getFullYear().toString().padStart(4, '0');
  const month = (originalDate.getMonth() + 1).toString().padStart(2, '0');
  const day = originalDate.getDate().toString().padStart(2, '0');

  const updatedDateString = `${year}-${month}-${day}T00:00:00${timezoneOffset}`;

  return updatedDateString;
};
