import { ApiMethod, ApiUrlGet } from '../types/Api';
import { CheckboxGroupItem } from '../types/Forms';

import useApi from './useApi';

export type Country = {
  code: string;
  continent: string;
  id: string;
  title: string;
};

export interface ICountriesResponse {
  [region: string]: Country[];
}

const convertRegion = (obj: ICountriesResponse): CheckboxGroupItem[] => {
  return obj.all.map((item: Country, index) => ({
    label: item.title,
    code: item.code,
    id: index,
    checked: false,
    continent: item.continent,
    countryId: item.id,
  }));
};

export default function useCountries() {
  const {
    data: obj,
    error,
    isLoading,
  } = useApi<ICountriesResponse>({
    method: ApiMethod.get,
    url: ApiUrlGet.countries,
  });
  const regions = obj && convertRegion(obj);

  return { data: obj, error, isLoading, regions };
}
