import { ApiMethod, ApiUrlGet } from '../types/Api';

import useApi from './useApi';

enum IXsollaProjectProductStatus {
  connected = 'connected',
  disconnected = 'disconnected',
}

interface IXsollaProjectProduct {
  status: IXsollaProjectProductStatus;
  order: number;
}

export interface IXsollaProject {
  id: number;
  name: string;
  protocolKey: string;
  enabled: boolean;
  cardRecurring: boolean;
  user_billing_enabled: boolean;
  img: string | null;
  description: '';
  is_universal_item_enabled: boolean;
  components: { items: { status: string; live: boolean; custom_name: [] } };
  send_json_to_paystation: boolean;
  products: {
    store: IXsollaProjectProduct;
    subscriptions: IXsollaProjectProduct;
    site_builder: IXsollaProjectProduct;
    login: IXsollaProjectProduct;
    launcher: IXsollaProjectProduct;
    pay_station: IXsollaProjectProduct;
    funding: IXsollaProjectProduct;
    xsolla_connect: IXsollaProjectProduct;
    payouts: IXsollaProjectProduct;
    partner_network: IXsollaProjectProduct;
    anti_fraud: IXsollaProjectProduct;
  };
}

export default function useXsollaProjects() {
  const { data, sendRequest, error, isLoading } = useApi<IXsollaProject[]>({
    method: ApiMethod.get,
    url: ApiUrlGet.xsollaProjects,
  });

  return { data, sendRequest, error, isLoading };
}
