import { TrashIcon } from '@radix-ui/react-icons';
import {
  ColumnFiltersState,
  createColumnHelper,
  getCoreRowModel,
  getFacetedMinMaxValues,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import dayjs from 'dayjs';
import throttle from 'lodash/throttle';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Button from 'components/common/Button';
import ConfirmDialog from 'components/common/ConfirmDialog';
import DropdownButton from 'components/common/DropdownButton';
import PageTitle from 'components/common/PageTitle';
import Status from 'components/common/Status';
import Table from 'components/common/Table';
import Tabs from 'components/common/Tabs';
import Tag from 'components/common/Tag';
import { useAppDispatch } from 'hooks/storeHooks';
import useQuests, { IQuest } from 'hooks/useQuests';
import PageLayout from 'layout/PageLayout';
import { deleteQuest } from 'store/project';
import { IQuestReward, RewardTypeText } from 'types';

import styles from './Quests.module.scss';

const Quests = () => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const projectId = params.id || '';
  const navigate = useNavigate();
  const [questParams, setParams] = useState({});
  const { data, items, handleScrollLoad, isLoading, reload } = useQuests({
    projectId,
    params: questParams,
    validation: true,
  });
  const [removePopupOpen, setRemovePopupOpen] = useState(false);
  const [questIdToRemove, setQuestIdToRemove] = useState<string | undefined>('');
  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState('');

  const columnHelper = createColumnHelper<IQuest>();

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        cell: (info) => <b>{info.getValue()}</b>,
        header: () => <span>Quest name</span>,
        enableColumnFilter: true,
      }),
      columnHelper.accessor('startDate', {
        cell: (info) => <b>{dayjs(info.getValue()).format('MM / DD / YYYY')}</b>,
        header: () => <span>Issuing date</span>,
        enableColumnFilter: false,
      }),
      columnHelper.accessor('endDate', {
        cell: (info) => (
          <b>{info.getValue() ? dayjs(info.getValue()).format('MM / DD / YYYY') : ''}</b>
        ),
        header: () => <span>Closing date</span>,
        enableColumnFilter: false,
      }),
      columnHelper.accessor((row) => row.status, {
        id: 'status',
        cell: (info) => <Status value={info.getValue()} />,
        header: () => <span>Status</span>,
        enableColumnFilter: false,
      }),
      columnHelper.accessor((row) => row.rewards, {
        id: 'rewardType',
        cell: (info) => (
          <span>
            {info
              .getValue()
              .map((reward: IQuestReward) => RewardTypeText[reward.rewardType])
              .join('; ')}
          </span>
        ),
        header: () => <span>Reward type</span>,
        enableColumnFilter: false,
      }),
      columnHelper.accessor('topics', {
        cell: (info) => (
          <div className='flex-row'>
            <Tag isShort values={info.getValue()?.map((topic) => topic.title)} />
          </div>
        ),
        header: () => <span>Tags</span>,
        enableColumnFilter: false,
      }),
      columnHelper.display({
        id: 'id',
        cell: (info) => (
          <DropdownButton
            items={[
              {
                title: 'Edit quest',
                action: () => navigate(`/project/${projectId}/quests/${info.row.original.id}`),
              },
              undefined,
              {
                title: 'Remove quest',
                action: () => {
                  setRemovePopupOpen(true);
                  setQuestIdToRemove(info.row.original.id);
                },
                right: <TrashIcon />,
                styles: { color: '#E03131' },
              },
            ]}
            title='Manage'
            editable={true}
          />
        ),
        enableColumnFilter: false,
      }),
    ],
    [columnHelper, navigate, projectId],
  );

  const handleCreateQuest = () => navigate(`/project/${projectId}/quests/create`);

  const handleDeleteQuest = () => {
    if (!questIdToRemove) return;

    return dispatch(deleteQuest({ projectId, questId: questIdToRemove })).then(() => {
      setRemovePopupOpen(false);
      reload();
    });
  };

  const table = useReactTable({
    data: items || [],
    columns,
    state: {
      sorting,
      columnFilters,
      globalFilter,
    },
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
  });
  const tableState = table.getState();

  const list = useMemo(
    () => [
      {
        title: 'Table list',
        content: (
          <div
            className={styles.content}
            onScroll={(e) => handleScrollLoad(e.target as HTMLDivElement)}
          >
            <Table emptyText='There are no quests to display' isLoading={isLoading} table={table} />
          </div>
        ),
      },
      { title: 'Calendar', content: <p>Calendar</p> },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [table, items, isLoading, sorting],
  );

  const sendRequestWithFilters = useCallback(() => {
    const state = table.getState();
    const nameFilter = state.columnFilters.find((filter) => filter.id === 'name');

    setParams({ name: nameFilter?.value });
  }, [table, setParams]);

  const throttledSendRequest = useMemo(
    () => throttle(sendRequestWithFilters, 500),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    throttledSendRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableState.columnFilters]);

  return (
    <PageLayout>
      <PageTitle
        title='Quests'
        count={data?.total}
        action={
          <Button onClick={handleCreateQuest} variant='primary'>
            Create a quest
          </Button>
        }
      />
      <ConfirmDialog
        onClose={() => {
          setRemovePopupOpen(false);
          setQuestIdToRemove(undefined);
        }}
        open={removePopupOpen}
        title='Remove quest'
        primaryButton='Remove'
        onPrimaryButtonClick={handleDeleteQuest}
        secondaryButton='Cancel'
        description='By removing this quest from the project you will loose all access to this quest'
      />
      <Tabs list={list} />
    </PageLayout>
  );
};

export default Quests;
