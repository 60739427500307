import { VirtualItemFields } from 'types/Forms';
import {
  CostType,
  ItemProperty,
  ItemPropertyText,
  PurchaseFrequencyType,
  PeriodType,
  PeriodTypeText,
  DurationType,
  DurationTypeText,
  PurchaseFrequencyTypeText,
  IExpirationPeriodType,
} from 'types/Reward';
export const DefaultTime = '14:00:00';
export const DefaultTimezone = '+02:00';
export const DefaultCurrency = 'USD';

export const getMockCurrency = (currency?: string) => {
  return {
    currency: currency || '',
    amount: undefined,
    is_enabled: true,
    is_default: true,
  };
};

export const getMockVirtualCurrency = (virtualCurrency?: string) => {
  return {
    sku: virtualCurrency || '',
    amount: undefined,
    is_enabled: true,
    is_default: true,
  };
};

export const CountryBasedCurrenciesMock = [
  {
    currency: '',
    amount: undefined,
    is_enabled: true,
    country_iso: '',
    is_default: true,
  },
];

export const ItemAttributeState = [
  { value: 'external_id', label: 'External ID' },
  { value: 'attribute_name', label: 'Attribute name' },
];

export const RadioPriceTypeMock = [
  {
    id: 1,
    title: 'Paid item',
    value: CostType.paid,
  },
  {
    id: 2,
    title: 'Free item',
    value: CostType.free,
  },
];

export const RadioPeriodTypeMock = [
  {
    id: 1,
    title: PeriodTypeText[PeriodType.always],
    value: PeriodType.always,
  },
  {
    id: 2,
    title: PeriodTypeText[PeriodType.time_period],
    value: PeriodType.time_period,
  },
];

export const RadioDurationTypeMock = [
  {
    id: 1,
    title: DurationTypeText[DurationType.closing_date],
    value: DurationType.closing_date,
  },
  {
    id: 2,
    title: DurationTypeText[DurationType.perpetual],
    value: DurationType.perpetual,
  },
];

export const ItemPropertyTypeMock = [
  {
    id: 1,
    label: ItemPropertyText[ItemProperty.consumable],
    value: ItemProperty.consumable,
  },
  {
    id: 2,
    label: ItemPropertyText[ItemProperty.nonconsumable],
    value: ItemProperty.nonconsumable,
  },
  {
    id: 3,
    label: ItemPropertyText[ItemProperty.non_renewing_subscription],
    value: ItemProperty.non_renewing_subscription,
  },
];

export const PurchaseFrequencyTypeTypeMock = [
  {
    id: 1,
    label: PurchaseFrequencyTypeText[PurchaseFrequencyType.none],
    value: PurchaseFrequencyType.none,
  },
  {
    id: 2,
    label: PurchaseFrequencyTypeText[PurchaseFrequencyType.daily],
    value: PurchaseFrequencyType.daily,
  },
  {
    id: 3,
    label: PurchaseFrequencyTypeText[PurchaseFrequencyType.weekly],
    value: PurchaseFrequencyType.weekly,
  },
  {
    id: 4,
    label: PurchaseFrequencyTypeText[PurchaseFrequencyType.monthly],
    value: PurchaseFrequencyType.monthly,
  },
];

export const IExpirationPeriodTypeMock = [
  {
    id: 1,
    label: IExpirationPeriodType.days.charAt(0).toUpperCase() + IExpirationPeriodType.days.slice(1),
    value: IExpirationPeriodType.days,
  },
  {
    id: 2,
    label:
      IExpirationPeriodType.months.charAt(0).toUpperCase() + IExpirationPeriodType.months.slice(1),
    value: IExpirationPeriodType.months,
  },
  {
    id: 3,
    label:
      IExpirationPeriodType.years.charAt(0).toUpperCase() + IExpirationPeriodType.years.slice(1),
    value: IExpirationPeriodType.years,
  },
];

export const SelectedTimeTypeMock = [
  { label: '1 AM', value: '01:00:00' },
  { label: '2 AM', value: '02:00:00' },
  { label: '3 AM', value: '03:00:00' },
  { label: '4 AM', value: '04:00:00' },
  { label: '5 AM', value: '05:00:00' },
  { label: '6 AM', value: '06:00:00' },
  { label: '7 AM', value: '07:00:00' },
  { label: '8 AM', value: '08:00:00' },
  { label: '9 AM', value: '09:00:00' },
  { label: '10 AM', value: '10:00:00' },
  { label: '11 AM', value: '11:00:00' },
  { label: '12 PM', value: '12:00:00' },
  { label: '1 PM', value: '13:00:00' },
  { label: '2 PM', value: '14:00:00' },
  { label: '3 PM', value: '15:00:00' },
  { label: '4 PM', value: '16:00:00' },
  { label: '5 PM', value: '17:00:00' },
  { label: '6 PM', value: '18:00:00' },
  { label: '7 PM', value: '19:00:00' },
  { label: '8 PM', value: '20:00:00' },
  { label: '9 PM', value: '21:00:00' },
  { label: '10 PM', value: '22:00:00' },
  { label: '11 PM', value: '23:00:00' },
  { label: '12 AM', value: '00:00:00' },
];

export const PurchaseWeeklyDayTypeMock = [
  {
    id: 1,
    label: 'Monday',
    value: '1',
  },
  {
    id: 2,
    label: 'Tuesday',
    value: '2',
  },
  {
    id: 3,
    label: 'Wednesday',
    value: '3',
  },
  {
    id: 4,
    label: 'Thursday',
    value: '4',
  },
  {
    id: 5,
    label: 'Friday',
    value: '5',
  },
  {
    id: 6,
    label: 'Saturday',
    value: '6',
  },
  {
    id: 7,
    label: 'Sunday',
    value: '7',
  },
];

export const PurchaseMonthDayTypeMock = Array.from({ length: 31 }, (_, i) => {
  const label = (i + 1).toString();
  const value = (i + 1).toString();
  return { label, value };
});

export const DefaultRewardState = {
  sku: '',
  groups: [],
  name: {
    en: '',
  },
  description: {
    en: '',
  },
  inventory_options: {
    consumable: true,
  },
  // usage: '',
  is_free: false,
  prices: [],
  vc_prices: [],
  vc_pricing: false,
  limits_enable: false,
  limits: {
    per_user: null,
    recurrent_schedule: null,
  },
  is_show_in_store: true,
  periods: [],
  default_currency: 'USD',
} as VirtualItemFields;
