import { yupResolver } from '@hookform/resolvers/yup';
import * as Form from '@radix-ui/react-form';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import Button from 'components/common/Button';
import Input from 'components/common/NewInput';
import { useAppDispatch } from 'hooks/storeHooks';
import AuthLayout from 'layout/authLayout/index';
import { fetchProfile, ownerVerify } from 'store/user';
import { IOwnerVerifyRequestBody } from 'types/Auth';

import styles from './SignUp.module.scss';
import Schema from './validations/signUpConfirmation.validation';

const SignUpVerify: React.FC = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<IOwnerVerifyRequestBody>({
    resolver: yupResolver(Schema),
    defaultValues: {
      token: id,
    },
  });

  const onSubmit = handleSubmit((data) => {
    return dispatch(ownerVerify(data)).then((res: any) => {
      if (res?.payload) {
        dispatch(fetchProfile());
      }
    });
  });

  return (
    <AuthLayout loading={isSubmitting}>
      <h1 className={styles.verify__title}>Welcome!</h1>
      <div className={styles.verify__description}>
        To complete your account, enter merchant ID and API key
      </div>
      <Form.Root onSubmit={onSubmit} className={styles.sign_up__form}>
        <Input
          label='Merchant ID'
          name='merchantId'
          control={control}
          placeholder='Enter Merchant ID'
          error={errors.merchantId}
          maxlength={9}
        />

        <div className={styles.verify__input_margin}>
          <Input
            label='API key'
            name='xsollaApiKey'
            control={control}
            placeholder='Enter API key'
            error={errors.xsollaApiKey}
          />
        </div>

        <Form.Submit asChild>
          <Button
            disabled={!!Object.keys(errors).length}
            variant='primary'
            className={styles.verify__submit}
          >
            Submit
          </Button>
        </Form.Submit>
      </Form.Root>
    </AuthLayout>
  );
};

export default SignUpVerify;
