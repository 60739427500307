import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { ImageListType } from 'react-images-uploading';
import { useNavigate, useParams } from 'react-router-dom';

import Button from 'components/common/Button';
import PageTitle from 'components/common/PageTitle';
import { useAppDispatch } from 'hooks/storeHooks';
import useVirtualCurrency, { convertData } from 'hooks/useVirtualCurrency';
import PageLayout from 'layout/PageLayout';
import { createVirtualCurrency, updateVirtualCurrency } from 'store/reward';
import { Fields } from 'types/Forms';

import { Information } from './components/information';
import styles from './CreateVirtualCurrency.module.scss';
import { DefaultCurrencyState } from './mock';
import Schema from './validation';

const CreateVirtualCurrency: React.FC<{ isEdit?: boolean }> = ({ isEdit }) => {
  const { id: projectId = '', sku = '' } = useParams();

  const {
    control,
    watch,
    reset,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<Fields>({
    resolver: yupResolver(Schema),
    defaultValues: {
      virtualCurrency: DefaultCurrencyState,
    },
  });

  const [logoImage, setLogoImage] = React.useState<ImageListType>([]);
  const [spriteImage, setSpriteImage] = React.useState<ImageListType>([]);
  const { data: reward } = useVirtualCurrency({ projectId, sku, validation: !!sku });

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onSubmit = handleSubmit((data) => {
    if (isEdit) {
      return dispatch(updateVirtualCurrency({ data: data.virtualCurrency, sku, projectId })).then(
        (res: any) => {
          if (res.payload) {
            return navigate(`/project/${projectId}/rewards`);
          }
        },
      );
    } else {
      return dispatch(createVirtualCurrency({ data: data.virtualCurrency, projectId })).then(
        (res: any) => {
          if (res.payload) {
            return navigate(`/project/${projectId}/rewards`);
          }
        },
      );
    }
  });

  useEffect(() => {
    if (!isEdit) {
      setValue(
        'virtualCurrency',
        {
          ...DefaultCurrencyState,
        },
        { shouldDirty: false },
      );
    } else if (isEdit && reward) {
      const form = convertData(reward);
      reset({ virtualCurrency: form });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, reward]);

  return (
    <PageLayout>
      <PageTitle title={isEdit ? 'Edit virtual currency' : 'Create virtual currency'} />

      <form onSubmit={onSubmit}>
        <div className={styles.section}>
          <Information
            logoImage={logoImage}
            setLogoImage={setLogoImage}
            spriteImage={spriteImage}
            setSpriteImage={setSpriteImage}
            control={control}
            watch={watch}
            setValue={setValue}
            isEdit={isEdit}
            errors={errors}
          />
        </div>

        <div
          className={classNames(styles.create_reward__buttons_container, {
            [styles['create_reward__buttons_container--absolute']]: true,
          })}
        >
          <Button onClick={() => navigate(-1)} variant='secondary'>
            Cancel
          </Button>

          <Button variant='primary' type='submit' className={styles.create_reward__submit_button}>
            Save item
          </Button>
        </div>
      </form>
    </PageLayout>
  );
};

export default CreateVirtualCurrency;
