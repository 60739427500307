import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { ImageListType } from 'react-images-uploading';
import { useParams, useNavigate } from 'react-router-dom';

import FooterButtons from 'components/common/FooterButtons';
import PageTitle from 'components/common/PageTitle';
import useCountries from 'hooks/useCountries';
import useQuest from 'hooks/useQuest';
import useTopics from 'hooks/useTopics';
import PageLayout from 'layout/PageLayout';
import { Fields } from 'types/Forms';

import useApi from '../../hooks/useApi';
import { ApiMethod, ApiUrlPatch, ApiUrlPost } from '../../types/Api';

import { Information } from './components/information';
import { Rewards } from './components/rewards';
import { Triggers } from './components/triggers';
import { Webhooks } from './components/webhooks';
import styles from './CreateQuest.module.scss';
import { DefaultQuestState } from './mock';
import { convertFormForReq, convertFormFromRes } from './utils';
import { createSchema } from './validation';

const CreateQuest: React.FC<{ isEdit?: boolean }> = ({ isEdit }) => {
  const {
    control,
    watch,
    setValue,
    reset,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<Fields>({
    resolver: yupResolver(createSchema()),
  });

  const quests = watch('quests');

  const navigate = useNavigate();
  const [logoImage, setLogoImage] = React.useState<ImageListType>([]);
  const [spriteImage, setSpriteImage] = React.useState<ImageListType>([]);
  const { regions } = useCountries();
  const { id: projectId = '', questId = '' } = useParams();
  const { data: quest } = useQuest({ projectId, questId, validation: !!questId });
  const { topics } = useTopics(projectId);
  const { sendRequest } = useApi({
    method: ApiMethod.post,
    url: ApiUrlPost.createQuest.replace(':id', projectId),
  });
  const { sendRequest: sendUpdateRequest } = useApi({
    method: ApiMethod.patch,
    url: ApiUrlPatch.editQuest.replace(':projectId', projectId).replace(':questId', questId),
  });
  const navigateToQuests = () => navigate(`/project/${projectId}/quests`);

  useEffect(() => {
    if (!isEdit) {
      reset({
        quests: {
          ...DefaultQuestState,
          topics: topics || [],
          availableRegions: regions || [],
        },
      });
    } else if (isEdit && quest) {
      const form = convertFormFromRes(quest, topics, regions);
      reset({
        quests: form,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regions?.length, topics?.length, isEdit, quest]);

  const onSubmit = handleSubmit(async () => {
    const state = getValues().quests;
    const form = convertFormForReq(state);
    if (isEdit) {
      await sendUpdateRequest(form).then((res: any) => {
        if (!res.errors) {
          navigateToQuests();
        }
      });
    } else {
      await sendRequest(form).then((res: any) => {
        if (!res.errors) {
          navigateToQuests();
        }
      });
    }
  });

  return (
    <PageLayout>
      <PageTitle
        title={isEdit ? 'Edit quest' : 'Create quest'}
        breadcrumbs='Quests'
        onBreadcrumbClick={navigateToQuests}
      />
      {!!quests && (
        <form onSubmit={onSubmit}>
          <div className={styles.section}>
            <Information
              logoImage={logoImage}
              setLogoImage={setLogoImage}
              spriteImage={spriteImage}
              setSpriteImage={setSpriteImage}
              control={control}
              watch={watch}
              setValue={setValue}
              reset={reset}
              projectId={projectId}
              errors={errors}
            />
          </div>
          <div className={styles.section}>
            <Triggers setValue={setValue} control={control} watch={watch} reset={reset} />
          </div>
          <div className={styles.section}>
            <Rewards
              reset={reset}
              control={control}
              watch={watch}
              errors={errors?.quests?.rewards_list}
            />
          </div>
          <div className={styles.section}>
            <Webhooks reset={reset} control={control} watch={watch} />
          </div>
          <FooterButtons
            submitTitle={isEdit ? 'Save changes' : 'Publicate quest'}
            rejectTitle={'Cancel'}
            onSubmit={onSubmit}
            onReject={navigateToQuests}
          />
        </form>
      )}
    </PageLayout>
  );
};

export default CreateQuest;
