import * as TooltipRadix from '@radix-ui/react-tooltip';
import React from 'react';

import styles from './Tooltip.module.scss';

interface TooltipProps {
  children?: React.ReactNode;
  content?: React.ReactNode;
}

const Tooltip: React.FC<TooltipProps> = ({ children, content }) => {
  return (
    <TooltipRadix.Root>
      <TooltipRadix.Trigger asChild>{children}</TooltipRadix.Trigger>
      <TooltipRadix.Portal>
        <TooltipRadix.Content className={styles.tooltip__content} sideOffset={5}>
          {content}
          <TooltipRadix.Arrow className={styles.tooltip__arrow} />
        </TooltipRadix.Content>
      </TooltipRadix.Portal>
    </TooltipRadix.Root>
  );
};

export default Tooltip;
