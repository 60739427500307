import classnames from 'classnames';
import isUndefined from 'lodash/isUndefined';
import React, { useState, useEffect } from 'react';
import { Control, UseFormSetValue, UseFormWatch } from 'react-hook-form';

import Label from 'components/common/Label';
import Input from 'components/common/NewInput';
import SingleSelect from 'components/common/SingleSelect';
import Switch from 'components/common/Switch';
import useTimezones from 'hooks/useTimezones';
import { Fields } from 'types/Forms';
import { PurchaseFrequencyType } from 'types/Reward';

import {
  PurchaseFrequencyTypeTypeMock,
  PurchaseWeeklyDayTypeMock,
  PurchaseMonthDayTypeMock,
  SelectedTimeTypeMock,
  DefaultTime,
  DefaultTimezone,
} from '../../mock';

import styles from './Limits.module.scss';

type LimitsProps = {
  control: Control<Fields>;
  watch: UseFormWatch<Fields>;
  setValue: UseFormSetValue<Fields>;
};

export const Limits: React.FC<LimitsProps> = ({ control, watch, setValue }) => {
  const virtualItem = watch('virtualItem');
  const limits = virtualItem.limits;

  const limitEnable = isUndefined(virtualItem.limits_enable)
    ? Boolean(limits.per_user || limits.recurrent_schedule)
    : virtualItem.limits_enable;

  const [intervalType, setIntervalType] = useState<string>(
    limits.recurrent_schedule?.per_user?.interval_type || PurchaseFrequencyType.none,
  );
  const [limitEnableState, setLimitEnableState] = useState<boolean>(
    isUndefined(virtualItem.limits_enable)
      ? Boolean(limits.per_user || limits.recurrent_schedule)
      : virtualItem.limits_enable,
  );

  const { data: timezones } = useTimezones();

  const isMonthlyPeriod = intervalType === PurchaseFrequencyType.monthly;
  const isWeeklyPeriod = intervalType === PurchaseFrequencyType.weekly;

  useEffect(() => {
    setLimitEnableState(limitEnable);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [limitEnable]);

  return (
    <div className={styles.limits}>
      <p className={styles.limits__title}>Limits for single user</p>
      <div className={styles.limits__content}>
        <div className={styles.wrapper}>
          <Switch
            label='Limit number of times one user can buy this item'
            name={'virtualItem.limits_enable'}
            control={control}
            value={limitEnableState}
            onChange={(value) => {
              setLimitEnableState(value);
              setValue('virtualItem.limits', { per_user: null, recurrent_schedule: null });
            }}
          />
        </div>
        {limitEnableState && (
          <div className={styles.limits__content}>
            <div>
              <Label label='Number of items can be bought' />
              <div
                className={classnames(
                  styles.limits__content__inputs,
                  styles['limits__content--col-2'],
                )}
              >
                <Input
                  placeholder='Number'
                  control={control}
                  type='number'
                  name={'virtualItem.limits.per_user'}
                />
                <SingleSelect
                  options={PurchaseFrequencyTypeTypeMock}
                  placeholder='Select attribute'
                  defaultValue={intervalType}
                  update={(value) => {
                    const perUser = {
                      day_of_month: null,
                      day_of_week: null,
                      interval_type: value,
                      time: DefaultTime,
                    };
                    setIntervalType(value);
                    setValue('virtualItem.limits.recurrent_schedule.per_user', perUser);
                  }}
                />
              </div>
            </div>
            {isMonthlyPeriod && (
              <div className={styles.wrapper}>
                <Label label='Every month on day' />
                <SingleSelect
                  update={(value) => {
                    const currentPerUser = virtualItem.limits.recurrent_schedule?.per_user;
                    const perUser = {
                      day_of_month: value,
                      day_of_week: null,
                      interval_type: currentPerUser?.interval_type,
                    };
                    setValue('virtualItem.limits.recurrent_schedule.per_user', perUser);
                  }}
                  options={PurchaseMonthDayTypeMock}
                  placeholder='Select day'
                  defaultValue={
                    virtualItem.limits.recurrent_schedule?.per_user?.day_of_month?.toString() || ''
                  }
                />
              </div>
            )}
            {isWeeklyPeriod && (
              <div className={styles.wrapper}>
                <Label label='Every week on' />
                <SingleSelect
                  update={(value) => {
                    setValue('virtualItem.limits.recurrent_schedule.per_user.day_of_week', value);
                  }}
                  options={PurchaseWeeklyDayTypeMock}
                  placeholder='Select week day'
                  defaultValue={
                    virtualItem.limits.recurrent_schedule?.per_user?.day_of_week?.toString() || ''
                  }
                />
              </div>
            )}
            {intervalType !== PurchaseFrequencyType.none && (
              <div>
                <Label
                  label={
                    isMonthlyPeriod
                      ? 'Every month at'
                      : isWeeklyPeriod
                      ? 'Every week at'
                      : 'Every day at'
                  }
                />
                <div
                  className={classnames(
                    styles.limits__content__inputs,
                    styles['limits__content--col-2'],
                  )}
                >
                  <SingleSelect
                    options={SelectedTimeTypeMock}
                    placeholder='Select attribute'
                    defaultValue={
                      virtualItem.limits.recurrent_schedule?.per_user?.time || DefaultTime
                    }
                    update={(value) => {
                      const perUser = {
                        ...virtualItem.limits.recurrent_schedule?.per_user,
                        time: value,
                      };
                      setValue('virtualItem.limits.recurrent_schedule.per_user', perUser);
                    }}
                  />
                  <SingleSelect
                    options={timezones || []}
                    placeholder='Select attribute'
                    defaultValue={virtualItem.timezone || DefaultTimezone}
                    update={(value) => {
                      setValue('virtualItem.timezone', value);
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
