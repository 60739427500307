import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import api from 'API/api';
import {
  AuthApiUrlGet,
  AuthApiUrlPost,
  IForgotPasswordRequestBody,
  IImportXsollProjectsRequestBody,
  ILoginRequestBody,
  IResetPasswordRequestBody,
  IUserProfile,
  IVerifyRequestBody,
  ISignUpRequestBody,
  IOwnerVerifyRequestBody,
} from 'types/Auth';
import { UserInviteFields } from 'types/Forms';

import { ApiMethod, ApiUrlPost, RequestStatus } from '../types/Api';

import type { AppState } from './store';

interface UserState {
  isAuthenticated: boolean;
  profile?: IUserProfile;
  profileStatus?: RequestStatus;
}

const initialState: UserState = {
  isAuthenticated: false,
  profileStatus: undefined,
  profile: undefined,
};

export const fetchProfile = createAsyncThunk('user/fetchProfile', async () => {
  const response = await api[ApiMethod.get](AuthApiUrlGet.profile);

  if ('data' in response && response.status === 200) {
    return response.data;
  } else {
    throw response?.data?.error;
  }
});

export const login = createAsyncThunk('user/login', async (data: ILoginRequestBody) => {
  const response = await api[ApiMethod.post](AuthApiUrlPost.login, data);

  if ('data' in response && response.status === 200) {
    return response.data;
  } else {
    throw response?.data?.error;
  }
});

export const verify = createAsyncThunk('auth/verify', async (data: IVerifyRequestBody) => {
  try {
    const response = await api[ApiMethod.post](AuthApiUrlPost.verify, data);

    if ('data' in response && response.status === 200) {
      return response.data;
    } else {
      throw response?.data?.error;
    }
  } catch (error: any) {
    return error;
  }
});

export const logout = createAsyncThunk('user/logout', async () => {
  try {
    const response = await api[ApiMethod.post](AuthApiUrlPost.logout, {});

    if ('data' in response && response.status === 200) {
      return 'success';
    } else {
      throw response?.data?.error;
    }
  } catch (error) {
    return;
  }
});

export const forgotPassword = createAsyncThunk(
  'user/forgotPassword',
  async (data: IForgotPasswordRequestBody) => {
    const response = await api[ApiMethod.post](AuthApiUrlPost.forgotPassword, data);

    if ('data' in response && response.status === 200) {
      return 'success';
    } else {
      throw response?.data?.error;
    }
  },
);

export const resetPassword = createAsyncThunk(
  'user/resetPassword',
  async (data: IResetPasswordRequestBody) => {
    const response = await api[ApiMethod.post](AuthApiUrlPost.resetPassword, data);

    if ('data' in response && response.status === 200) {
      return 'success';
    } else {
      throw response?.data?.error;
    }
  },
);

export const inviteUser = createAsyncThunk('user/inviteUser', async (data: UserInviteFields) => {
  try {
    const response = await api[ApiMethod.post](
      ApiUrlPost.inviteUser.replace(':projectId', data.projectId),
      data,
    );

    if ('data' in response && response.status === 200) {
      return response.data;
    } else {
      throw response?.data?.error;
    }
  } catch (error) {
    return error;
  }
});

export const signUpOwner = createAsyncThunk('auth/register', async (data: ISignUpRequestBody) => {
  const response = await api[ApiMethod.post](AuthApiUrlPost.signUp, data);

  if ('data' in response && response.status === 200) {
    return response.data;
  } else {
    throw response?.data?.error;
  }
});

export const ownerVerify = createAsyncThunk(
  'auth/verify/owner',
  async (data: IOwnerVerifyRequestBody) => {
    const response = await api[ApiMethod.post](AuthApiUrlPost.ownerVerify, data);

    if ('data' in response && response.status === 200) {
      return response.data;
    } else {
      throw response?.data?.error;
    }
  },
);

export const importXsollaProjects = createAsyncThunk(
  'user/importXsollaProjects',
  async (data: IImportXsollProjectsRequestBody) => {
    try {
      const response = await api[ApiMethod.post](ApiUrlPost.importXsollaProjects, data);

      if ('data' in response && response.status === 200) {
        return response.data;
      }
    } catch (error) {
      return error;
    }
  },
);

export const uploadImage = createAsyncThunk('user/uploadImage', async (data: FormData) => {
  try {
    const response = await api[ApiMethod.post](ApiUrlPost.uploadImage, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });

    if ('data' in response && response.status === 200) {
      return response.data;
    } else {
      throw response?.data?.error;
    }
  } catch (error: any) {
    return error;
  }
});

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setAuth: (state: UserState, action: PayloadAction<{ profile: IUserProfile }>) => {
      state.profileStatus = RequestStatus.success;
      state.profile = action.payload.profile;
      state.isAuthenticated = true;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(login.fulfilled, (state) => {
        state.profileStatus = RequestStatus.success;
      })
      .addCase(login.rejected, (state) => {
        state.profileStatus = RequestStatus.failure;
      })
      .addCase(logout.fulfilled, (state) => {
        state.isAuthenticated = false;
        Object.assign(state, initialState);
        state.profileStatus = RequestStatus.failure;
      })
      .addCase(fetchProfile.pending, (state) => {
        state.profileStatus = RequestStatus.pending;
      })
      .addCase(fetchProfile.fulfilled, (state, action) => {
        state.profileStatus = RequestStatus.success;
        state.profile = action.payload;

        if (action.payload?.verified) {
          state.isAuthenticated = true;
        }
      })
      .addCase(fetchProfile.rejected, (state) => {
        state.profileStatus = RequestStatus.failure;
        state.isAuthenticated = false;
      })
      .addCase(ownerVerify.fulfilled, (state) => {
        state.profileStatus = RequestStatus.success;
      });
  },
});

export const { setAuth } = userSlice.actions;

export const selectIsAuthenticated = (state: AppState) => state.user.isAuthenticated;
export const selectProfileStatus = (state: AppState) => state.user.profileStatus;
export const selectIsProfileLoading = (state: AppState) =>
  state.user.profileStatus === RequestStatus.pending;
export const selectIsProfileLoaded = (state: AppState) =>
  state.user.profileStatus === RequestStatus.success ||
  state.user.profileStatus === RequestStatus.failure;
export const selectProfile = (state: AppState) => state.user.profile;

export default userSlice.reducer;
