import { ExclamationTriangleIcon } from '@radix-ui/react-icons';
import classnames from 'classnames';
import React, { ChangeEvent, FC } from 'react';
import { useController, Control, FieldError } from 'react-hook-form';

import Label from 'components/common/Label';

import styles from './NewInput.module.scss';

type InputProps = {
  placeholder?: string;
  disabled?: boolean;
  readOnly?: boolean;
  autoFocus?: boolean;
  className?: string;
  control?: Control<any>;
  error?: FieldError;
  name: string;
  label?: string;
  required?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  titleClassName?: string;
  type?: string;
  optional?: string;
  min?: number;
  max?: number;
  step?: number;
  maxlength?: number;
};

const Input: FC<InputProps> = ({
  placeholder,
  disabled = false,
  readOnly = false,
  autoFocus = false,
  className,
  type = 'text',
  control,
  name,
  error: customError,
  label,
  required,
  onChange,
  optional,
  titleClassName,
  min = 1,
  max,
  step = 1,
  maxlength,
}) => {
  const {
    field,
    fieldState: { error: fieldError },
  } = useController<Record<string, string>>({
    control,
    name,
  });
  const error = customError || fieldError;

  return (
    <div className={styles.wrapper}>
      {!!label && <Label className={titleClassName} label={label} optional={optional} />}
      <div className={styles.wrapper__control}>
        <input
          className={classnames(styles.wrapper__input, className)}
          placeholder={placeholder}
          disabled={disabled}
          readOnly={readOnly}
          required={required}
          autoFocus={autoFocus}
          type={type}
          min={min}
          max={max}
          step={step}
          maxLength={maxlength}
          autoComplete='off'
          {...(!!error && { 'data-invalid': true })}
          {...field}
          onChange={(e) => {
            field.onChange(e.target.value);

            if (onChange) {
              onChange(e);
            }
          }}
        />
        {error ? <ExclamationTriangleIcon className={styles.wrapper__error_triangle} /> : null}
      </div>
      {error && <span className={styles.wrapper__error_label}>{error?.message}</span>}
    </div>
  );
};
export default Input;
