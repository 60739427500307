import classnames from 'classnames';
import React from 'react';

import styles from './Hint.module.scss';

type HintProps = {
  children: string;
  className?: string;
};

const Hint: React.FC<HintProps> = ({ children, className }) => {
  return <p className={classnames(styles.hint, className)}>{children}</p>;
};

export default Hint;
