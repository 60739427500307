import { PlusIcon, TrashIcon } from '@radix-ui/react-icons';
import classNames from 'classnames';
import React, { useState } from 'react';
import { Control, Controller, useFieldArray, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import Button from 'components/common/Button';
import ConfirmDialog from 'components/common/ConfirmDialog';
import Hint from 'components/common/Hint';
import ImageUploader from 'components/common/ImageUploader';
import Label from 'components/common/Label';
import Input from 'components/common/NewInput';
import SingleSelect from 'components/common/SingleSelect';
import TextArea from 'components/common/TextArea';
import LoadingBox from 'components/LoadingBox';
import { useAppDispatch, useAppSelector } from 'hooks/storeHooks';
import { USER_ROLES_OPTIONS } from 'pages/users/constants';
import { deleteProject, selectIsProjectLoading } from 'store/project';
import { UserRole } from 'types';
import { Fields } from 'types/Forms';

import styles from './Information.module.scss';

type InformationProps = {
  control: Control<Fields>;
  watch: UseFormWatch<Fields>;
  setValue: UseFormSetValue<Fields>;
  isValid: boolean;
  isAbsoluteFooter?: boolean;
  isEdit?: boolean;
  projectId?: string;
};

export const Information: React.FC<InformationProps> = ({
  control,
  watch,
  isValid,
  isAbsoluteFooter,
  setValue,
  isEdit,
  projectId = '',
}) => {
  const [removePopupOpen, setRemovePopupOpen] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isLoadingProject = useAppSelector<boolean>(selectIsProjectLoading);
  const formKey = isEdit ? 'projectUpdate' : 'project';

  watch('project');
  watch('projectUpdate');

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'project.users',
  });

  const handleAddUser = () => {
    append({ email: '', role: UserRole.MODERATOR });
  };

  const handleDeleteUser = (index: number) => {
    if (fields.length === 1) return;
    return remove(index);
  };

  const removeProject = () => {
    return dispatch(deleteProject(projectId)).then((res: any) => {
      if (!res.error) {
        return navigate('/projects');
      }

      setRemovePopupOpen(false);
    });
  };

  return (
    <div>
      <div className={styles.info}>
        <ConfirmDialog
          onClose={() => setRemovePopupOpen(false)}
          open={removePopupOpen}
          title='Remove project'
          primaryButton='Remove'
          onPrimaryButtonClick={removeProject}
          secondaryButton='Cancel'
          loading={isLoadingProject}
          description='By removing this project you will loose quests and data related to it'
        ></ConfirmDialog>
        {isLoadingProject ? <LoadingBox /> : null}
        <p className={styles.info__title}>{isEdit ? 'General settings' : 'General info'}</p>
        <div className={styles.info__content}>
          <div className={styles.info__content__image_uploader}>
            <div>
              <Label label='Thumbnail (optional)' />
              <ImageUploader
                name={`${formKey}.cover`}
                title='Upload image'
                setValue={setValue}
                watch={watch}
                acceptType={['png', 'jpg', 'jpeg', 'webp']}
                maxFileSize={4e6}
              />
              <Hint>Use .png, .jpg and .webp file / 4MB max size</Hint>
            </div>
          </div>

          <Input
            label='Project name'
            name={`${formKey}.title`}
            control={control}
            placeholder='Enter project name'
          />
          <TextArea
            label='Project description'
            name={`${formKey}.content`}
            control={control}
            rows={8}
            placeholder='Write a full description of the project'
          />

          {!isEdit ? (
            <Input
              label='Xsolla project ID'
              name='project.xsollaProjectId'
              control={control}
              placeholder='Enter Xsolla project ID from Xsolla Publisher Account'
            />
          ) : null}

          {!isEdit ? (
            <>
              <p className={styles.info__users_label}>Invite user</p>
              {!isEdit
                ? fields.map((field: any, index: number) => (
                    <div className={styles.info__inputs} key={index + field.email + field.role}>
                      <div className={styles.info__inputs__label_container}>
                        <div className={styles.info__inputs__label}>USER {index + 1}</div>
                        {fields.length > 1 && (
                          <TrashIcon
                            className={styles.info__inputs__trash_icon}
                            onClick={() => handleDeleteUser(index)}
                          />
                        )}
                      </div>

                      <fieldset>
                        <Controller
                          render={({ field: { value, onChange } }) => (
                            <SingleSelect
                              defaultValue={value}
                              placeholder='Select an option'
                              options={USER_ROLES_OPTIONS}
                              update={(data) => onChange(data)}
                            />
                          )}
                          name={`project.users.${index}.role`}
                          control={control}
                        />
                      </fieldset>

                      <Input
                        type='email'
                        className={styles.info__inputs__email_input}
                        name={`project.users[${index}].email`}
                        control={control}
                        placeholder='username@domain.com'
                      />
                    </div>
                  ))
                : null}
              <span className={styles.info__content__add_button} onClick={handleAddUser}>
                <PlusIcon className={styles.info__content__add_icon} />
                <div className={styles.info__content__add_text}>Add user</div>
              </span>
            </>
          ) : null}
        </div>
      </div>
      <div
        className={classNames(styles.info__buttons_container, {
          [styles['info__buttons_container--absolute']]: isAbsoluteFooter,
        })}
      >
        {isEdit ? (
          <div className={styles.info__buttons_container__left}>
            <Button
              onClick={() => setRemovePopupOpen(true)}
              leftIcon={<TrashIcon />}
              variant='transparent_delete'
            >
              Remove project
            </Button>
          </div>
        ) : null}
        <Button onClick={() => navigate(-1)} variant='secondary'>
          Cancel
        </Button>

        <Button
          disabled={!isValid || isLoadingProject}
          variant='primary'
          type='submit'
          className={styles.info__submit_button}
        >
          Save project
        </Button>
      </div>
    </div>
  );
};
