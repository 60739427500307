import * as yup from 'yup';

export const createSchema = () => {
  return yup.object().shape({
    quests: yup.object().shape({
      name: yup.string().required('Name is required'),
      shortDescription: yup.string().required('Short description is required'),
      description: yup.string().required('Description is required'),
      endDate: yup
        .date()
        .nullable()
        .test('date-range', 'End date must be greater than start date', function (value) {
          const start = new Date(this.resolve(yup.ref('startDate'))).getTime();
          const end = value?.getTime();
          const duration = this.resolve(yup.ref('duration')) === 'closing_date';
          if ((duration && end && start >= end) || (duration && !end)) {
            this.createError({ path: 'endDate' });
          } else {
            return true;
          }
        }),
      startDate: yup.date().required('Start date is required'),
      triggers_list: yup.array().of(
        yup.object().shape({
          name: yup.string().required('Event name is required'),
          least: yup.number().typeError('Min 1').min(1, 'Min 1'),
          period_number: yup.number().when('times', ([times], schema) => {
            return times !== 'ever'
              ? schema.typeError('Min 1').min(1, 'Min 1')
              : schema.transform((_, val) => (val === Number(val) ? val : null)).nullable();
          }),
        }),
      ),
      metadata_list: yup.array().of(
        yup.object().shape({
          key: yup.string().required('Key is required'),
          value: yup.string().required('Value is required'),
        }),
      ),
      webhook_list: yup.array().of(
        yup.object().shape({
          value: yup.string().required('Url is required'),
        }),
      ),
      rewards_list: yup.array().of(
        yup.object().shape({
          reward_type: yup.string().required('Value is required'),
        }),
      ),
    }),
  });
};
