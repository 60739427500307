import React from 'react';
import { UseFormSetValue } from 'react-hook-form';

import Label from 'components/common/Label';
import SingleSelect from 'components/common/SingleSelect';
import { Fields } from 'types/Forms';

import { ItemPropertyTypeMock } from '../../mock';

import styles from './ItemProperties.module.scss';

type ItemAttributesProps = {
  setValue: UseFormSetValue<Fields>;
};

export const ItemAttributes: React.FC<ItemAttributesProps> = ({ setValue }) => {
  return (
    <div className={styles.info}>
      <p className={styles.info__title}>Item attributes</p>
      <div className={styles.info__content}>
        <div className={styles.wrapper}>
          <Label label='Item attribute (optional)' />
          <SingleSelect
            disabled={true}
            update={(value) => setValue('virtualCurrency.itemAttribute', value)}
            options={ItemPropertyTypeMock}
            placeholder='Select attribute'
            defaultValue='value'
            inputClassName={styles.info__content__input}
          />
        </div>
      </div>
    </div>
  );
};
