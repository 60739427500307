import { PlusIcon, TrashIcon } from '@radix-ui/react-icons';
import React, { useMemo } from 'react';
import { Control, useFieldArray, UseFormReset, UseFormWatch } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import Radio from 'components/common/Radio';
import SingleSelect, { SingleSelectEnum } from 'components/common/SingleSelect';
import useVirtualCurrencies from 'hooks/useVirtualCurrencies';
import useVirtualItems from 'hooks/useVirtualItems';
import { Fields, QuestFieldsName, QuestReward } from 'types/Forms';

import { RewardsMock, ClaimTypeMock } from '../../mock';
import { getRewards } from '../../utils';

import styles from './Rewards.module.scss';

type RewardsProps = {
  control: Control<any>;
  watch: UseFormWatch<Fields>;
  reset: UseFormReset<any>;
  name?: any;
  errors?: any;
};

export const Rewards: React.FC<RewardsProps> = ({
  control,
  name = QuestFieldsName.rewardsList,
  watch,
  errors,
}) => {
  const { id: projectId = '' } = useParams();
  const { data: virtualCurrencies } = useVirtualCurrencies({
    projectId,
    params: { limit: 999, offset: 0 },
    validation: true,
  });
  const { data: virtualItems } = useVirtualItems({
    projectId,
    params: { limit: 999, offset: 0 },
    validation: true,
  });
  const options = useMemo(
    () => getRewards(virtualCurrencies?.items || [], virtualItems?.items || []),
    [virtualItems, virtualCurrencies],
  );

  const { update, fields, insert, remove } = useFieldArray({
    control,
    name,
  });

  const claims = watch(name);
  const rewards = fields as QuestReward[];
  const onAddReward = () => {
    insert(fields.length, RewardsMock[0]);
  };

  const onRemove = (i: number) => {
    remove(i);
  };

  const onUpdate = (id: number, i: QuestReward, key: string, value: string, custom?: string) => {
    update(id, {
      ...i,
      [key]: value,
      claim_type: claims[id]?.claim_type,
      custom,
    });
  };

  return (
    <div className={styles.rewards}>
      <p className={styles.rewards__title}>Rewards</p>
      <div className={styles.rewards__wrapper}>
        {rewards.map((item, index) => (
          <div className={styles.rewards_content} key={`${item.id}_${item.reward_type}_${index}`}>
            <div className={styles.rewards__content__header}>
              <p>REWARD {index + 1}</p>
              <TrashIcon color='#0073F7' width={18} height={18} onClick={() => onRemove(index)} />
            </div>
            <SingleSelect
              update={(value, custom) => onUpdate(index, item, 'reward_type', value, custom)}
              options={options}
              defaultValue={item.reward_type}
              type={SingleSelectEnum.search}
              error={errors?.[index]?.reward_type?.message}
              placeholder='Choose reward'
            />
            <div className={styles.rewards__content__radios}>
              {ClaimTypeMock.map((radio, i) => (
                <Radio
                  control={control}
                  key={`${radio.title}_${i}`}
                  value={radio.value}
                  name={`${QuestFieldsName.rewardsList}.${index}.claim_type`}
                  checked={radio.value === claims[index]?.claim_type}
                  label={radio.title}
                />
              ))}
            </div>
          </div>
        ))}
        <div className={styles.rewards__add_more} onClick={onAddReward}>
          <PlusIcon color='#0073F7' />
          <p>Add another reward</p>
        </div>
      </div>
    </div>
  );
};
