import { PlusIcon } from '@radix-ui/react-icons';
import React from 'react';
import {
  Control,
  useFieldArray,
  UseFormReset,
  UseFormWatch,
  UseFormSetValue,
} from 'react-hook-form';

import Label from 'components/common/Label';
import SingleSelect from 'components/common/SingleSelect';
import { Fields, QuestFieldsName, QuestTrigger } from 'types/Forms';

import { TriggersMock, TriggersUsersState } from '../../mock';

import { TriggerItem } from './triggerItem';
import styles from './Triggers.module.scss';

type TriggersProps = {
  control: Control<any>;
  watch: UseFormWatch<Fields>;
  reset: UseFormReset<any>;
  name?: any;
  setValue: UseFormSetValue<Fields>;
};

export const Triggers: React.FC<TriggersProps> = ({
  control,
  name = QuestFieldsName.triggersList,
  watch,
  setValue,
}) => {
  const { update, fields, insert, remove } = useFieldArray({
    control,
    name,
  });

  const operation = watch(QuestFieldsName.logicalOperator);
  const data = fields as QuestTrigger[];

  const onAddTrigger = () => {
    insert(data.length, TriggersMock[0]);
  };

  return (
    <div className={styles.triggers}>
      <p className={styles.triggers__title}>Triggers</p>
      <div>
        <div className={styles.triggers__users}>
          <Label label='Trigger for users when' />
          <div className={styles.triggers__users__select}>
            <SingleSelect
              disabled={fields.length <= 1}
              update={(value) => setValue(QuestFieldsName.logicalOperator, value)}
              options={TriggersUsersState}
              defaultValue={operation}
            />
            <p className={styles.triggers__users__label}>of the following conditions match</p>
          </div>
        </div>
        {data.map((item, index: number) => (
          <TriggerItem
            control={control}
            watch={watch}
            index={index}
            update={update}
            remove={remove}
            canRemove={data?.length > 1}
            item={item}
            key={`${item.id}_${index}`}
          />
        ))}
        <div className={styles.triggers__add_more} onClick={onAddTrigger}>
          <PlusIcon color='#0073F7' />
          <p>Add another trigger</p>
        </div>
      </div>
    </div>
  );
};
