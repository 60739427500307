import { UserRole } from 'types';

import { ApiMethod, ApiUrlGet } from '../types/Api';

import useApi from './useApi';

export interface IProject {
  id: string;
  createdAt: string | null;
  updatedAt: string | null;
  deletedAt: string | null;
  xsollaProjectId: string;
  title: string;
  cover: string;
  companyId: string;
  ownerId: string;
  content: string;
  numberQuests: number;
  numberUsers: number;
  role: UserRole;
  currentUser: {
    userId: string;
    role: UserRole;
    projectId: string;
  };
}

export interface IProjectsResponse {
  data: IProject[];
  page: number;
  perPage: number;
  total: number;
  pageCount: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
}

export default function useProjects(params: any, validation?: boolean) {
  const { data, sendRequest, error, isLoading } = useApi<IProjectsResponse>({
    method: ApiMethod.get,
    url: ApiUrlGet.projects,
    options: { params, validation },
  });

  return { data, sendRequest, error, isLoading };
}
