import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { IProject } from 'hooks/useProjects';
import { UserRole } from 'types';
import { IDeleteUserFromProjectRequestBody } from 'types/User';

import api from '../API/api';
import {
  ApiMethod,
  ApiUrlDelete,
  ApiUrlGet,
  ApiUrlPost,
  ApiUrlPut,
  RequestStatus,
} from '../types/Api';
import { ProjectFields, ProjectUpdateFields } from '../types/Forms';

import { AppState } from './store';

interface ProjectState {
  projectStatus?: RequestStatus;
  projects?: IProject[];
  projectsStatus?: RequestStatus;
}

const initialState: ProjectState = {
  projectStatus: undefined,
  projects: [],
  projectsStatus: undefined,
};

export const fetchProjects = createAsyncThunk('user/fetchProjects', async () => {
  const response = await api[ApiMethod.get](ApiUrlGet.projects);

  if ('data' in response && response.status === 200) {
    return response.data;
  } else {
    throw response?.data?.error;
  }
});

export const createProject = createAsyncThunk('project/create', async (data: ProjectFields) => {
  try {
    const response = await api[ApiMethod.post](ApiUrlPost.createProject, data);

    if ('data' in response && response.status === 200) {
      return response.data;
    } else {
      throw response?.data?.error;
    }
  } catch (error: any) {
    return error;
  }
});

export const updateProject = createAsyncThunk(
  'project/update',
  async (data: { projectId: string; data: ProjectUpdateFields }) => {
    try {
      const response = await api[ApiMethod.patch](
        ApiUrlPut.updateProject.replace(':id', data.projectId),
        data.data,
      );

      if ('data' in response && response.status === 200) {
        return 'success';
      } else {
        throw response?.data?.error;
      }
    } catch (error: any) {
      return error;
    }
  },
);

export const deleteProject = createAsyncThunk('project/delete', async (projectId: string) => {
  try {
    const response = await api[ApiMethod.delete](
      ApiUrlDelete.deleteProject.replace(':id', projectId),
    );

    if ('data' in response && response.status === 200) {
      return 'success';
    } else {
      throw response?.data?.error;
    }
  } catch (error: any) {
    return error;
  }
});

export const updateUserRoleOnProject = createAsyncThunk(
  'project/updateUserRoleOnProject',
  async (data: { projectId: string; userId: string; role: UserRole }) => {
    try {
      const response = await api[ApiMethod.patch](
        ApiUrlPut.updateUserRoleOnProject
          .replace(':projectId', data.projectId)
          .replace(':userId', data.userId),
        { role: data.role },
      );

      if ('data' in response && response.status === 200) {
        return response.data;
      } else {
        throw response?.data?.error;
      }
    } catch (error: any) {
      return error;
    }
  },
);

export const deleteUserFromProject = createAsyncThunk(
  'project/deleteUserFromProject',
  async (data: IDeleteUserFromProjectRequestBody) => {
    try {
      const response = await api[ApiMethod.delete](
        ApiUrlDelete.deleteUserFromProject
          .replace(':projectId', data.projectId)
          .replace(':userId', data.userId),
      );

      if ('data' in response && response.status === 200) {
        return response.data;
      } else {
        throw response?.data?.error;
      }
    } catch (error: any) {
      return error;
    }
  },
);

export const deleteUserFromAllProjects = createAsyncThunk(
  'project/deleteUserFromAllProjects',
  async (data: { userId: string }) => {
    try {
      const response = await api[ApiMethod.delete](
        ApiUrlDelete.deleteUserFromAllProjects.replace(':userId', data.userId),
      );

      if ('data' in response && response.status === 200) {
        return response.data;
      } else {
        throw response?.data?.error;
      }
    } catch (error: any) {
      return error;
    }
  },
);

export const refreshApiKey = createAsyncThunk(
  'project/refreshApiKey',
  async (data: { projectId: string }) => {
    try {
      const response = await api[ApiMethod.patch](
        ApiUrlPut.updateProjectApiKey.replace(':id', data.projectId),
        {},
      );

      if ('data' in response && response.status === 200) {
        return response.data;
      } else {
        throw response?.data?.error;
      }
    } catch (error: any) {
      return error;
    }
  },
);

export const deleteQuest = createAsyncThunk(
  'project/deleteQuest',
  async (data: { projectId: string; questId: string }) => {
    try {
      const response = await api[ApiMethod.delete](
        ApiUrlDelete.deleteQuest
          .replace(':projectId', data.projectId)
          .replace(':questId', data.questId),
      );

      if ('data' in response && response.status === 200) {
        return response.data;
      } else {
        throw response?.data?.error;
      }
    } catch (error: any) {
      return error;
    }
  },
);

export const deleteVirtualItem = createAsyncThunk(
  'project/deleteVirtualItem',
  async (data: { projectId: string; rewardId: string }) => {
    try {
      const response = await api[ApiMethod.delete](
        ApiUrlDelete.deleteVirtualItem
          .replace(':projectId', data.projectId)
          .replace(':rewardId', data.rewardId),
      );

      if ('data' in response && response.status === 200) {
        return response.data;
      } else {
        throw response?.data?.error;
      }
    } catch (error: any) {
      return error;
    }
  },
);

export const deleteVirtualCurrency = createAsyncThunk(
  'project/deleteVirtualCurrency',
  async (data: { projectId: string; rewardId: string }) => {
    try {
      const response = await api[ApiMethod.delete](
        ApiUrlDelete.deleteVirtualCurrency
          .replace(':projectId', data.projectId)
          .replace(':rewardId', data.rewardId),
      );

      if ('data' in response && response.status === 200) {
        return response.data;
      } else {
        throw response?.data?.error;
      }
    } catch (error: any) {
      return error;
    }
  },
);

export const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchProjects.pending, (state) => {
        state.projectStatus = RequestStatus.success;
      })
      .addCase(fetchProjects.fulfilled, (state, action) => {
        if (action.payload) {
          state.projects = action.payload.data;
        }

        state.projectStatus = RequestStatus.failure;
      })
      .addCase(fetchProjects.rejected, (state) => {
        state.projectStatus = RequestStatus.failure;
      })
      .addCase(createProject.pending, (state) => {
        state.projectStatus = RequestStatus.success;
      })
      .addCase(createProject.fulfilled, (state) => {
        state.projectStatus = RequestStatus.failure;
      })
      .addCase(createProject.rejected, (state) => {
        state.projectStatus = RequestStatus.failure;
      })
      .addCase(updateProject.pending, (state) => {
        state.projectStatus = RequestStatus.success;
      })
      .addCase(updateProject.fulfilled, (state) => {
        state.projectStatus = RequestStatus.failure;
      })
      .addCase(updateProject.rejected, (state) => {
        state.projectStatus = RequestStatus.failure;
      })
      .addCase(deleteProject.pending, (state) => {
        state.projectStatus = RequestStatus.success;
      })
      .addCase(deleteProject.fulfilled, (state) => {
        state.projectStatus = RequestStatus.failure;
      })
      .addCase(deleteProject.rejected, (state) => {
        state.projectStatus = RequestStatus.failure;
      });
  },
});

export const selectIsProjectLoading = (state: AppState) =>
  state.project.projectStatus === RequestStatus.pending;

export const selectProjects = (state: AppState) => state.project.projects;

export default projectSlice.reducer;
