import React from 'react';

export enum IIconTypes {
  success = 'success',
  error = 'error',
  warning = 'warning',
}

export enum IIconColorTypes {
  green = 'green',
  orange = 'orange',
  red = 'red',
}

export interface ConfirmDialogInt {
  onClose?: () => void;
  open?: boolean;
  title: string;
  primaryButton: string;
  onPrimaryButtonClick?: () => void;
  secondaryButton?: string;
  onSecondaryButtonClick?: () => void;
  loading?: boolean;
  description?: string;
  icon?: IIconTypes;
  iconColor?: IIconColorTypes;
  children?: React.ReactNode;
  onSubmit?: () => void;
  isValid?: boolean;
  withoutIcon?: boolean;
}
