import * as DialogRadix from '@radix-ui/react-dialog';
import { ExclamationTriangleIcon, CheckIcon, Cross1Icon } from '@radix-ui/react-icons';
import classNames from 'classnames';
import React from 'react';

import Button from 'components/common/Button';
import {
  IIconTypes,
  ConfirmDialogInt,
  IIconColorTypes,
} from 'components/common/ConfirmDialog/interface';
import LoadingBox from 'components/LoadingBox';

import styles from './ConfirmDialog.module.scss';

const ConfirmDialog = ({
  onClose,
  open,
  title,
  primaryButton,
  onPrimaryButtonClick,
  secondaryButton,
  onSecondaryButtonClick,
  loading,
  description,
  icon = IIconTypes.warning,
  iconColor = IIconColorTypes.red,
  withoutIcon = false,
  children,
  onSubmit,
  isValid = true,
}: ConfirmDialogInt) => {
  const disabled = !isValid;

  return loading ? (
    <LoadingBox />
  ) : (
    <DialogRadix.Root open={open}>
      <DialogRadix.Portal>
        <DialogRadix.Overlay className={styles.dialog__overlay} onPointerDown={onClose} />
        <DialogRadix.Content className={styles.dialog__content}>
          <form className={styles.confirm_dialog} onSubmit={onSubmit}>
            {icon && !withoutIcon && (
              <div className={styles.confirm_dialog__icon_container}>
                <div
                  className={classNames(
                    styles.confirm_dialog__warning_icon,
                    styles[`dialog__bg_${iconColor}`],
                  )}
                >
                  {icon === IIconTypes.success ? (
                    <CheckIcon
                      className={classNames(
                        styles.confirm_dialog__icon,
                        styles[`dialog__${iconColor}`],
                      )}
                    />
                  ) : icon === IIconTypes.warning ? (
                    <ExclamationTriangleIcon
                      className={classNames(
                        styles.confirm_dialog__icon,
                        styles[`dialog__${iconColor}`],
                      )}
                    />
                  ) : null}
                </div>
                <DialogRadix.Close
                  className={styles.confirm_dialog__icon_container__close}
                  onClick={onClose}
                >
                  <Cross1Icon className={styles.confirm_dialog__icon_container__close__icon} />
                </DialogRadix.Close>
              </div>
            )}

            <h1 className={styles.confirm_dialog__title}>{title}</h1>
            <div className={styles.confirm_dialog__description}>{description}</div>
            {children}

            <div className={styles.confirm_dialog__buttons_container}>
              {secondaryButton && (
                <DialogRadix.Close
                  className={styles.confirm_dialog__close_button}
                  onClick={onClose}
                >
                  <Button variant='secondary' onClick={onSecondaryButtonClick}>
                    {secondaryButton || 'Cancel'}
                  </Button>
                </DialogRadix.Close>
              )}
              <DialogRadix.Close className={styles.confirm_dialog__close_button}>
                <Button
                  disabled={disabled}
                  variant={primaryButton === 'Remove' ? 'delete' : 'primary'}
                  type={onPrimaryButtonClick ? 'button' : 'submit'}
                  className={styles.confirm_dialog__submit_button}
                  onClick={onPrimaryButtonClick}
                >
                  {primaryButton}
                </Button>
              </DialogRadix.Close>
            </div>
          </form>
        </DialogRadix.Content>
      </DialogRadix.Portal>
    </DialogRadix.Root>
  );
};

export default ConfirmDialog;
