import { TrashIcon } from '@radix-ui/react-icons';
import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import compact from 'lodash/compact';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ConfirmDialog from 'components/common/ConfirmDialog';
import DropdownButton from 'components/common/DropdownButton';
import Status, { TableStatus } from 'components/common/Status';
import Table from 'components/common/Table';
import { useAppDispatch, useAppSelector } from 'hooks/storeHooks';
import useVirtualCurrencies from 'hooks/useVirtualCurrencies';
import { deleteVirtualCurrency, selectProjects } from 'store/project';
import { UserRole } from 'types';

import styles from './Rewards.module.scss';

const TableVirtualCurrencies = ({
  projectId,
  setCount,
}: {
  projectId: string;
  setCount: Dispatch<SetStateAction<number>>;
}) => {
  const dispatch = useAppDispatch();
  const [removePopupOpen, setRemovePopupOpen] = useState(false);
  const [rewardIdToRemove, setRewardIdToRemove] = useState<string | undefined>('');
  const projects = useAppSelector(selectProjects) || [];
  const currentProject = projects.find((p) => p.id === projectId);
  const currentUserRole = currentProject?.currentUser?.role;
  const { items, isLoading, handleScrollLoad, reload } = useVirtualCurrencies({
    projectId,
    validation: true,
  });
  const navigate = useNavigate();

  const [sorting, setSorting] = useState<SortingState>([]);

  useEffect(() => {
    if (items) {
      setCount(items.length);
    }
  }, [items, setCount]);

  const columnHelper = createColumnHelper<any>();

  const columns = compact([
    columnHelper.accessor((row) => row.name.en, {
      id: 'name',
      cell: (info) => <b>{info.getValue()}</b>,
      header: () => <span>Reward name</span>,
      enableColumnFilter: false,
    }),
    columnHelper.accessor('sku', {
      cell: (info) => <b>{info.getValue()}</b>,
      header: () => <span>SKU</span>,
      enableColumnFilter: false,
    }),
    columnHelper.accessor((row) => row.prices, {
      id: 'prices',
      cell: (info) => (
        <span>
          {info.getValue().length > 0
            ? info
                .getValue()
                .map((price: any) => `${price.amount} ${price.currency}`)
                .join(', ')
            : info.row.original.is_free
            ? 'Free item'
            : '–'}
        </span>
      ),
      header: () => <span>Price</span>,
      enableColumnFilter: false,
    }),
    columnHelper.accessor('is_enabled', {
      cell: (info) => (
        <Status value={info.getValue() === true ? TableStatus.active : TableStatus.disabled} />
      ),
      header: () => <span>Status</span>,
      enableColumnFilter: false,
    }),
    currentUserRole !== UserRole.MODERATOR
      ? columnHelper.display({
          id: 'edit',
          cell: (info) => (
            <DropdownButton
              items={[
                {
                  title: 'Edit virtual currency',
                  action: () =>
                    navigate(`/project/${projectId}/virtual-currencies/${info.row.original.sku}`),
                },
                undefined,
                {
                  title: 'Remove reward',
                  right: <TrashIcon />,
                  action: () => {
                    setRemovePopupOpen(true);
                    setRewardIdToRemove(info.row.original.sku);
                  },
                  styles: { color: '#E03131' },
                },
              ]}
              title='Manage'
              editable={true}
            />
          ),
          enableColumnFilter: false,
        })
      : null,
  ]);

  const table = useReactTable({
    data: items || [],
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const handleDeleteReward = () => {
    if (!rewardIdToRemove) return;

    return dispatch(deleteVirtualCurrency({ projectId, rewardId: rewardIdToRemove })).then(() => {
      setRemovePopupOpen(false);
      reload();
    });
  };

  return (
    <div className={styles.content} onScroll={(e) => handleScrollLoad(e.target as HTMLDivElement)}>
      <ConfirmDialog
        onClose={() => {
          setRemovePopupOpen(false);
          setRewardIdToRemove(undefined);
        }}
        open={removePopupOpen}
        title='Remove virtual currency'
        primaryButton='Remove'
        onPrimaryButtonClick={handleDeleteReward}
        secondaryButton='Cancel'
        description='By removing this virtual currency from the project you will loose all access to this reward'
      />
      <Table
        emptyText='There are no virtual currencies to display'
        isLoading={isLoading}
        table={table}
      />
    </div>
  );
};

export default TableVirtualCurrencies;
