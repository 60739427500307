import { IApiMethods } from '../types/Api';

import axiosSetup from './axios';

const api: IApiMethods = {
  get: axiosSetup.get,
  post: axiosSetup.post,
  patch: axiosSetup.patch,
  put: axiosSetup.put,
  delete: axiosSetup.delete,
};

export default api;
