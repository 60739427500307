import classnames from 'classnames';
import React, { useState, useEffect } from 'react';
import {
  Control,
  UseFormReset,
  UseFormSetValue,
  UseFormWatch,
  useFieldArray,
} from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';

import Label from 'components/common/Label';
import Radio from 'components/common/Radio';
import SingleSelect from 'components/common/SingleSelect';
import Switch from 'components/common/Switch';
import useCurrencies from 'hooks/useCurrencies';
import { Fields } from 'types/Forms';
import { CostType, PriceType } from 'types/Reward';

import {
  RadioPriceTypeMock,
  getMockCurrency,
  CountryBasedCurrenciesMock,
  DefaultCurrency,
} from '../../mock';

import { CountryCurrencyItem } from './countryCurrencies/index';
import { CurrencyItem } from './currencies/index';
import styles from './PriceSettings.module.scss';
import { VirtualCurrencyItem } from './virtualCurrencies/index';

type PriceSettingsProps = {
  control: Control<Fields>;
  watch: UseFormWatch<Fields>;
  setValue: UseFormSetValue<Fields>;
  reset: UseFormReset<any>;
  isEdit?: boolean;
  errors?: any;
};

export const PriceSettings: React.FC<PriceSettingsProps> = ({
  control,
  watch,
  setValue,
  errors,
  reset,
}) => {
  const virtualItem = watch('virtualItem');

  const prices = virtualItem.prices;
  const vcPrices = virtualItem.vc_prices || [];
  const currencyList = prices.filter((price) => price.country_iso === undefined);
  const countryBasedCurrencyList = prices.filter((price) => price.country_iso !== undefined);

  const { data: currencies } = useCurrencies();
  const selectedCostType = virtualItem.is_free ? CostType.free : CostType.paid;

  const [costType, setСostType] = useState<string>(selectedCostType);

  const [realCurrency, setRealCurrency] = useState<boolean>(Boolean(virtualItem.prices.length));
  const [virtualCurrency, setVirtualCurrency] = useState<boolean>(
    Boolean(virtualItem.vc_prices.length),
  );

  const { update, fields, insert, remove } = useFieldArray({
    control,
    name: 'virtualItem.prices',
  });

  const pricesData = fields as PriceType[];

  const onAddTrigger = (country: boolean, currency?: string) => {
    const price = country ? CountryBasedCurrenciesMock[0] : getMockCurrency(currency);
    insert(pricesData.length, { ...price, id: uuidv4() });
  };

  const onUpdate = (id: string, i: PriceType, key: string, value: string) => {
    const index = prices.findIndex((item) => item.id === id);

    update(index, {
      ...i,
      [key]: value,
      amount: prices[index]?.amount,
    });
  };

  const onRemove = (id: string) => {
    const index = prices.findIndex((item) => item.id === id);
    remove(index);
  };

  useEffect(() => {
    setСostType(selectedCostType);
  }, [selectedCostType]);

  return (
    <div className={styles.info}>
      <p className={styles.info__title}>Price settings</p>
      <div className={styles.info__content}>
        <div className={styles.info__content__radio}>
          <Label label='Paid or free' />
          <div
            className={classnames(
              styles.info__content__radio__radios,
              styles['info__content__radio__radios--col-3'],
            )}
          >
            {RadioPriceTypeMock.map((i) => (
              <Radio
                value={i.value}
                key={i.id}
                label={i.title}
                name='is_free'
                control={control}
                checked={costType === i.value}
                onChange={(e) => {
                  const value = e.target.value;

                  let isFree;
                  if (value === CostType.paid) {
                    isFree = false;
                  } else {
                    isFree = true;
                  }
                  setValue('virtualItem', {
                    ...virtualItem,
                    is_free: isFree,
                    prices: [],
                  });
                  setСostType(value);
                }}
              />
            ))}
          </div>
        </div>
        {!virtualItem.is_free && (
          <div className={styles.info__content}>
            <div className={styles.info__content__radio}>
              <Label label='Specify the price in at least one currency for this virtual item' />
              <Switch
                label='Pricing in real currency'
                name={'realCurrency'}
                control={control}
                value={realCurrency}
                onChange={(value) => {
                  reset({
                    virtualItem: {
                      ...virtualItem,
                      prices: [],
                    },
                  });
                  setRealCurrency(value);
                }}
                error={errors.virtualItem?.prices}
              />
            </div>
            {realCurrency && (
              <div className={styles.info__content}>
                <div className={styles.wrapper}>
                  <Label label='Default currency' />
                  <SingleSelect
                    disabled={true}
                    update={(value) => setValue('virtualItem.default_currency', value)}
                    options={currencies || []}
                    placeholder='Select currency'
                    defaultValue={virtualItem.default_currency || DefaultCurrency}
                  />
                </div>

                <div className={styles.wrapper}>
                  <CurrencyItem
                    control={control}
                    watch={watch}
                    update={onUpdate}
                    remove={onRemove}
                    prices={prices}
                    currencyList={currencyList}
                    addPrice={onAddTrigger}
                    currencies={currencies || []}
                    formKey='virtualItem'
                    label='Price in real currency'
                    errors={errors.virtualItem?.prices}
                  />
                </div>

                <div className={styles.wrapper}>
                  <CountryCurrencyItem
                    control={control}
                    watch={watch}
                    update={onUpdate}
                    remove={onRemove}
                    prices={prices}
                    currencyList={countryBasedCurrencyList}
                    addPrice={onAddTrigger}
                    currencies={currencies || []}
                    formKey='virtualItem'
                    errors={errors.virtualItem?.prices}
                  />
                </div>
              </div>
            )}

            <div>
              <Switch
                label='Pricing in virtual currency'
                name={'virtualItem.vc_pricing'}
                control={control}
                value={virtualCurrency}
                onChange={(value) => {
                  reset({
                    virtualItem: {
                      ...virtualItem,
                      vc_prices: [],
                    },
                  });
                  setVirtualCurrency(value);
                }}
              />
            </div>

            {virtualCurrency && (
              <div className={styles.info__content}>
                <div className={styles.wrapper}>
                  <Label label='Default currency' />
                  <SingleSelect
                    disabled={true}
                    update={(value) => setValue('virtualItem.default_currency', value)}
                    options={currencies || []}
                    placeholder='Select currency'
                    defaultValue={virtualItem.default_currency || DefaultCurrency}
                  />
                </div>

                <div className={styles.wrapper}>
                  <VirtualCurrencyItem
                    control={control}
                    watch={watch}
                    prices={vcPrices}
                    formKey='virtualItem'
                    label='Price in virtual currency'
                    isEdit
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
