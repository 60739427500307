import * as yup from 'yup';

const schema = yup.object().shape({
  virtualCurrency: yup.object().shape({
    sku: yup.string().required('Sku is required'),
    groups: yup.array().min(0).of(yup.string()),
    name: yup.object().shape({
      en: yup.string().required('Name is required'),
    }),
    prices: yup.array().of(
      yup.object().shape({
        amount: yup.number().typeError('Min 1').min(1, 'Min 1').required('Amount is required'),
        currency: yup.string().required('Currency is required'),
        country_iso: yup.string().test('country_iso', 'Country iso is required', function (value) {
          const parent = this.parent;

          if (parent && parent.country_iso !== undefined) {
            return !!value;
          }
          return true;
        }),
        is_enabled: yup.boolean().optional(),
        is_default: yup.boolean().optional(),
      }),
    ),
  }),
});

export default schema;
