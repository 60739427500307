import { PlusIcon, TrashIcon } from '@radix-ui/react-icons';
import React from 'react';
import { Controller, useFieldArray } from 'react-hook-form';

import Input from 'components/common/NewInput';
import SingleSelect from 'components/common/SingleSelect';
import { UserRole } from 'types';

import { USER_ROLES_OPTIONS } from './constants';
import { IUserInviteForm } from './interface';
import styles from './User.module.scss';

const UsersInviteForm: React.FC<IUserInviteForm> = ({ control, isSubmitted }) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'userInvite.users',
  });

  const handleAddUser = () => {
    append({ email: '', role: UserRole.MODERATOR });
  };

  const handleDeleteUser = (index: number) => {
    if (fields.length === 1) return;
    return remove(index);
  };

  const renderInputs = () => {
    return fields.map((field: any, index: number) => (
      <div className={styles.dialog_inputs} key={index}>
        <div className={styles.dialog_inputs__label_container}>
          <div className={styles.dialog_inputs__label}>USER {index + 1}</div>
          {fields.length !== 1 && (
            <TrashIcon
              className={styles.dialog_inputs__trash_icon}
              onClick={() => handleDeleteUser(index)}
            />
          )}
        </div>

        <fieldset className={styles.dialog_inputs__select}>
          <Controller
            render={({ field: { value, onChange } }) => (
              <SingleSelect
                defaultValue={value}
                placeholder='Select role'
                options={USER_ROLES_OPTIONS}
                update={(data) => onChange(data)}
              />
            )}
            name={`userInvite.users.[${index}].role`}
            control={control}
          />
        </fieldset>

        <Input
          type='email'
          label='Email'
          name={`userInvite.users.[${index}].email`}
          control={control}
          placeholder='Enter your email'
        />
      </div>
    ));
  };

  return (
    <div>
      <div>
        {isSubmitted ? null : (
          <div>
            {renderInputs()}
            <span className={styles.dialog__add_button} onClick={handleAddUser}>
              <PlusIcon className={styles.dialog__add_icon} />
              <div className={styles.dialog__add_text}>Add another user</div>
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default UsersInviteForm;
