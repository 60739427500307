import classnames from 'classnames';
import uniq from 'lodash/uniq';
import React, { useState } from 'react';
import { Control, UseFormReset, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { ImageListType } from 'react-images-uploading';

import DatePicker from 'components/common/DatePicker';
import Hint from 'components/common/Hint';
import ImageUploader from 'components/common/ImageUploader';
import Label from 'components/common/Label';
import MultiselectField from 'components/common/Multiselect';
import Input from 'components/common/NewInput';
import Radio from 'components/common/Radio';
import TextArea from 'components/common/TextArea';
import useApi from 'hooks/useApi';
import { ApiMethod, ApiUrlGet } from 'types/Api';
import { Fields, QuestFieldsName } from 'types/Forms';

import { RadioDiffMock, RadioDurationMock, RadioVisibilityMock } from '../../mock';
import { Operation, updateCheckedStatus } from '../../utils';

import styles from './Information.module.scss';

type InformationProps = {
  control: Control<Fields>;
  watch: UseFormWatch<Fields>;
  setValue: UseFormSetValue<Fields>;
  logoImage: ImageListType | [];
  setLogoImage: (i: ImageListType) => void;
  spriteImage: ImageListType | [];
  setSpriteImage: (i: ImageListType) => void;
  reset: UseFormReset<any>;
  projectId: string;
  errors?: any;
};

export const Information: React.FC<InformationProps> = ({
  projectId,
  control,
  watch,
  setValue,
  reset,
  errors,
}) => {
  const quests = watch('quests');
  const [regionsFilter, setRegionsFilter] = useState<string>('All');
  const { sendRequest } = useApi<any>({
    method: ApiMethod.post,
    url: ApiUrlGet.topics.replace(':id', projectId),
  });
  const onDiffReset = () => {
    setValue(QuestFieldsName.difficulty, '');
  };

  const onTopicsSubmit = async (title: string) => {
    try {
      if (title) {
        const duplicate = quests.topics.find((i) => i.label.toLowerCase() === title.toLowerCase());
        if (!duplicate) {
          reset({
            quests: {
              ...quests,
              topics: [
                ...quests.topics,
                {
                  checked: true,
                  label: title,
                  id: quests.topics.length,
                },
              ],
            },
          });
        }
        await sendRequest({
          title: title,
        });
      }
    } catch (e) {
      console.log(e, 'error');
    }
  };

  const onMultiselectReset = (operation: Operation, isCountry?: boolean, parent?: string) => {
    if (isCountry && quests?.availableRegions && operation) {
      const result = updateCheckedStatus(
        quests.availableRegions,
        operation,
        parent ? parent : regionsFilter,
      );
      setValue(QuestFieldsName.availableRegions, result);
    } else {
      reset({
        quests: {
          ...quests,
          topics: quests.topics.map((i) => ({
            ...i,
            checked: Operation.select === operation,
          })),
        },
      });
    }
  };

  return (
    <div className={styles.info}>
      <p className={styles.info__title}>Information</p>
      <div className={styles.info__content}>
        <div>
          <div className={styles.info__content__image_uploader}>
            <div>
              <Label label='Logo' />
              <ImageUploader
                name={QuestFieldsName.logo}
                title='Upload image'
                setValue={setValue}
                watch={watch}
                acceptType={['png', 'jpg', 'jpeg', 'webp']}
                maxFileSize={4e6}
              />
            </div>
            <div>
              <Label label='Sprite' />
              <ImageUploader
                name={QuestFieldsName.sprite}
                title='Upload image'
                setValue={setValue}
                watch={watch}
                acceptType={['png', 'jpg', 'jpeg', 'webp']}
                maxFileSize={4e6}
              />
            </div>
          </div>
          <Hint>Use .png, .jpg or .webp file / 4MB max size</Hint>
        </div>

        <Input label='Name' name='quests.name' control={control} placeholder='Enter name quest' />
        <TextArea
          label='Short description'
          name={QuestFieldsName.shortDescription}
          control={control}
          placeholder='Write a short description of the quest'
        />
        <TextArea
          label='Full description'
          name={QuestFieldsName.description}
          control={control}
          rows={8}
          placeholder='Write a full description of the quest'
        />
        <div className={styles.info__content__radio}>
          <Label label='Difficulty level (optional)' />
          <div
            className={classnames(
              styles.info__content__radio__radios,
              styles['info__content__radio__radios--col-3'],
            )}
          >
            {RadioDiffMock.map((i) => (
              <Radio
                value={i.value}
                key={i.id}
                name={QuestFieldsName.difficulty}
                control={control}
                label={i.title}
                checked={quests?.difficulty === i.value}
              />
            ))}
          </div>
          <p
            onClick={onDiffReset}
            className={classnames(
              styles.info__content__radio__title,
              styles.info__content__radio__title__clickable,
            )}
          >
            Clear selection
          </p>
        </div>
        <div className={styles.info__content__radio}>
          <Label label='Quest visibility' />
          <div
            className={classnames(
              styles.info__content__radio__radios,
              styles['info__content__radio__radios--col-3'],
            )}
          >
            {RadioVisibilityMock.map((i) => (
              <Radio
                value={i.value}
                key={i.id}
                label={i.title}
                name={QuestFieldsName.visibility}
                control={control}
                checked={quests?.visibility === i.value}
              />
            ))}
          </div>
        </div>
        <div className={styles.info__content__multiselect}>
          <Label label='Quest topics' />
          <MultiselectField
            placeholder='Search quest tags'
            name={QuestFieldsName.topics}
            data={quests?.topics || []}
            control={control}
            onReset={onMultiselectReset}
            onSubmit={onTopicsSubmit}
          />
        </div>
        <Input
          label='Completion limit (optional)'
          name={QuestFieldsName.completionsLimit}
          control={control}
          placeholder='Limit for completions'
          type='number'
        />
        <div className={styles.info__content__radio}>
          <Label label='Quest duration' />
          <div
            className={classnames(
              styles.info__content__radio__radios,
              styles['info__content__radio__radios--col-2'],
            )}
          >
            <DatePicker
              control={control}
              selected={quests.startDate}
              name={QuestFieldsName.startDate}
              placeholder='Starting Date'
              error={errors?.quests?.startDate?.message}
            />
            {quests.duration === 'closing_date' ? (
              <DatePicker
                control={control}
                selected={quests.endDate}
                name={QuestFieldsName.endDate}
                placeholder='Closing Date'
                minDate={
                  quests?.startDate && new Date(quests.startDate.getTime() + 24 * 60 * 60 * 1000)
                }
                error={errors?.quests?.endDate?.message}
              />
            ) : (
              <div></div>
            )}
            {RadioDurationMock.map((i) => (
              <Radio
                key={i.id}
                value={i.value}
                label={i.title}
                name={QuestFieldsName.duration}
                control={control}
                checked={quests.duration === i.value}
                onChange={() => setValue(QuestFieldsName.endDate, null)}
              />
            ))}
          </div>
        </div>

        {!!quests?.availableRegions?.length && (
          <div className={styles.info__content__multiselect}>
            <Label label='Regions' />
            <MultiselectField
              leftColumnTitle='Regions'
              rightColumnTitle='Countries'
              placeholder='Search countries'
              name={QuestFieldsName.availableRegions}
              data={quests.availableRegions}
              control={control}
              onReset={onMultiselectReset}
              selectedFilter={regionsFilter}
              setSelectedFilter={setRegionsFilter}
              regions={[
                'All',
                ...uniq(quests.availableRegions.map((i) => i.continent || 'Unsupported continent')),
              ]}
            />
          </div>
        )}
      </div>
    </div>
  );
};
