import { ApiMethod, ApiUrlGet } from '../types/Api';

import useApi from './useApi';
import { IQuest } from './useQuests';

export default function useQuest({
  projectId,
  questId,
  params = {},
  validation = {},
}: {
  projectId: string;
  questId: string;
  params?: any;
  validation?: any;
}) {
  const { data, sendRequest, error, isLoading } = useApi<IQuest>({
    method: ApiMethod.get,
    url: ApiUrlGet.quest.replace(':id', projectId).replace(':questId', questId),
    options: { params, validation },
  });

  return { data, sendRequest, error, isLoading };
}
