import { TrashIcon, PlusIcon } from '@radix-ui/react-icons';
import classnames from 'classnames';
import React, { useEffect } from 'react';
import { Control, UseFormWatch } from 'react-hook-form';

import Label from 'components/common/Label';
import Input from 'components/common/NewInput';
import SingleSelect from 'components/common/SingleSelect';
import { Fields } from 'types/Forms';
import { SelectOption } from 'types/index';
import { PriceType } from 'types/Reward';

import styles from './Currencies.module.scss';

type CurrenciesProps = {
  control: Control<any>;
  watch: UseFormWatch<Fields>;
  update: (id: string, i: PriceType, key: string, value: string) => void;
  remove: (index: string) => void;
  addPrice: (country: boolean, currency: string) => void;
  prices: PriceType[];
  currencyList: PriceType[];
  currencies: SelectOption[];
  formKey: 'virtualItem' | 'virtualCurrency';
  label: string;
  errors?: any;
};

export const CurrencyItem: React.FC<CurrenciesProps> = ({
  watch,
  update,
  remove,
  control,
  prices,
  currencyList,
  addPrice,
  currencies,
  formKey,
  label,
  errors,
}) => {
  const form = watch(formKey);
  const [selectedCurrencies, setSelectedCurrencies] = React.useState<Set<string>>(new Set());

  const handleRemove = (id: string) => {
    const removedCurrency = currencyList.find((item) => item.id === id);

    setSelectedCurrencies((prevSelectedCurrencies) => {
      const updatedSelectedCurrencies = new Set(prevSelectedCurrencies);

      if (removedCurrency) updatedSelectedCurrencies.delete(removedCurrency?.currency);
      return updatedSelectedCurrencies;
    });

    remove(id);
  };

  useEffect(() => {
    if (form.prices?.length && selectedCurrencies.size !== form.prices.length) {
      setSelectedCurrencies(new Set(currencyList.map((price) => price.currency)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.prices.length, currencies.length, selectedCurrencies.size]);

  const currencyFirstOption = currencies.filter((currency) => {
    return !selectedCurrencies.has(currency.value);
  })[0]?.value;

  const renderCurrencies = () => {
    return currencyList.map((item, i: number) => {
      const index = prices.findIndex((price) => price.id === item.id);
      const currencyOptions = currencies.filter((currency) => {
        return !selectedCurrencies.has(currency.value) || item.currency === currency.value;
      });

      return (
        <div className={styles.currency} key={`${item.currency}_${index}`}>
          <div className={styles.currency__header}>
            <p>UNIT {i + 1}</p>
            {currencyList?.length > 1 && (
              <TrashIcon
                className={styles.currency__trash_icon}
                width={18}
                height={18}
                onClick={() => handleRemove(item.id)}
              />
            )}
          </div>
          <div className={classnames(styles.currency__content, styles['currency__content--col-2'])}>
            <SingleSelect
              update={(value) => {
                update(item.id, item, 'currency', value);
                setSelectedCurrencies(new Set(currencyList.map((price) => price.currency)));
              }}
              options={currencyOptions}
              defaultValue={item.currency}
              error={errors && errors[index]?.currency?.message}
            />
            <Input
              placeholder='Price'
              control={control}
              type='number'
              name={`${formKey}.prices.${index}.amount`}
            />
          </div>
        </div>
      );
    });
  };

  return (
    <div>
      <Label label={label} />
      {renderCurrencies()}

      <div
        className={styles.currency__add_more}
        onClick={() => addPrice(false, currencyFirstOption)}
      >
        <PlusIcon className={styles.currency__add_icon} />
        <p>Add pricing</p>
      </div>
    </div>
  );
};
