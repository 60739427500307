import { UserRole } from 'types';

export const FIELDS = Object.freeze({
  name: 'name',
  subgenres: 'subgenres',
  bookCount: 'bookCount',
  status: 'status',
});

export const DEFAULT_FORM_STATE = {
  name: '',
  subgenres: [],
};

export const USER_ROLES_OPTIONS = [
  { value: UserRole.MODERATOR, label: 'Moderator' },
  { value: UserRole.ADMIN, label: 'Admin' },
  // { value: UserRole.OWNER, label: 'Owner' },
];
