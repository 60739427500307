import classnames from 'classnames';
import React, { useState } from 'react';
import { Control, UseFormWatch, UseFormSetValue } from 'react-hook-form';

import DatePicker from 'components/common/DatePicker';
import Label from 'components/common/Label';
import Radio from 'components/common/Radio';
import SingleSelect from 'components/common/SingleSelect';
import Switch from 'components/common/Switch';
import useTimezones from 'hooks/useTimezones';
import { Fields } from 'types/Forms';
import { PeriodType, DurationType } from 'types/Reward';

import { RadioPeriodTypeMock, RadioDurationTypeMock, DefaultTimezone } from '../../mock';

import styles from './Store.module.scss';

type StoreProps = {
  control: Control<Fields>;
  watch: UseFormWatch<Fields>;
  setValue: UseFormSetValue<Fields>;
};

export const Store: React.FC<StoreProps> = ({ control, watch, setValue }) => {
  const virtualItem = watch('virtualItem');
  const { data: timezones } = useTimezones();
  const period = virtualItem.periods[0];

  const selectedPeriodType = virtualItem.periods.length
    ? PeriodType.time_period
    : PeriodType.always;

  const selectedDurationType =
    selectedPeriodType === PeriodType.time_period && period.date_until
      ? DurationType.closing_date
      : DurationType.perpetual;

  const [duration, setDuration] = useState<string>(selectedDurationType);
  const [periodType, setPeriodType] = useState<string>(selectedPeriodType);

  return (
    <div className={styles.store}>
      <p className={styles.store__title}>Store display</p>
      <div className={styles.store__content}>
        <div className={styles.wrapper}>
          <Switch
            label='Show in store'
            name={'virtualItem.is_show_in_store'}
            control={control}
            value={virtualItem.is_show_in_store}
            onChange={(value) => {
              setValue('virtualItem', {
                ...virtualItem,
                is_show_in_store: value,
                periods: [],
              });

              return value;
            }}
          />
        </div>
        {virtualItem.is_show_in_store && (
          <div className={styles.store__content}>
            <div className={styles.store__content__radio}>
              <Label label='Period' />
              <div
                className={classnames(
                  styles.store__content__radio__radios,
                  styles['store__content__radio__radios--col-3'],
                )}
              >
                {RadioPeriodTypeMock.map((i) => (
                  <Radio
                    value={i.value}
                    key={i.id}
                    label={i.title}
                    name='periodType'
                    control={control}
                    checked={periodType === i.value}
                    onChange={(e) => {
                      const value = e.target.value;

                      if (value === PeriodType.always) {
                        virtualItem.periods = [];
                      } else {
                        virtualItem.periods = [{ date_from: null, date_until: null }];
                      }
                      setPeriodType(value);
                    }}
                  />
                ))}
              </div>
            </div>

            {periodType !== PeriodType.always && (
              <div className={styles.store__content}>
                <div className={styles.wrapper}>
                  <Label label='Time zone' />
                  <SingleSelect
                    options={timezones || []}
                    placeholder='Select attribute'
                    defaultValue={virtualItem.storeTimezone || DefaultTimezone}
                    update={(value) => {
                      setValue('virtualItem.storeTimezone', value);
                    }}
                  />
                </div>

                <div className={styles.store__content__radio}>
                  <Label label='Duration' />
                  <div
                    className={classnames(
                      styles.store__content__radio__radios,
                      styles['store__content__radio__radios--col-3'],
                    )}
                  >
                    {RadioDurationTypeMock.map((i) => (
                      <Radio
                        value={i.value}
                        key={i.id}
                        label={i.title}
                        name={'duration'}
                        control={control}
                        checked={duration === i.value}
                        onChange={(e) => {
                          const value = e.target.value;
                          if (value === DurationType.perpetual) {
                            period.date_until = null;
                          }
                          setDuration(value);
                        }}
                      />
                    ))}
                  </div>
                </div>

                <div className={styles.store__content__date_inputs}>
                  <DatePicker
                    control={control}
                    selected={period?.date_from ? new Date(period.date_from) : null}
                    name='virtualItem.periods[0].date_from'
                    placeholder='Starting Date'
                    className={styles.store__content__date_inputs__date_wrapper}
                  />
                  {duration === DurationType.closing_date && (
                    <DatePicker
                      control={control}
                      selected={period?.date_until ? new Date(period.date_until) : null}
                      name='virtualItem.periods[0].date_until'
                      placeholder='Closing Date'
                      minDate={period?.date_until ? new Date(period.date_until) : null}
                      className={styles.store__content__date_inputs__date_wrapper}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
