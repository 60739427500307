import { ChatBubbleIcon, QuestionMarkCircledIcon } from '@radix-ui/react-icons';
import React, { useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link, useNavigate } from 'react-router-dom';

import Button from 'components/common/Button';
import PageTitle from 'components/common/PageTitle';
import Tabs from 'components/common/Tabs';
import LoadingBox from 'components/LoadingBox';
import ProjectCard from 'components/ProjectCard';
import { useAppSelector } from 'hooks/storeHooks';
import useProjects from 'hooks/useProjects';
import PageLayout from 'layout/PageLayout';
import { selectProfile } from 'store/user';
import { UserRole } from 'types';

import styles from './Projects.module.scss';

const getHasQuestParam = (tab: string) => {
  switch (tab) {
    case '0':
      return undefined;
    case '1':
      return 'true';
    case '2':
      return 'false';
    default:
      return '0';
  }
};

const Projects = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('0');
  const hasQuest = getHasQuestParam(activeTab);
  const profile = useAppSelector(selectProfile);
  const [perPage, setPerPage] = useState(20);
  const { data, isLoading } = useProjects({ page: 1, perPage, hasQuest }, true);
  const isProjectsEmpty = !isLoading && !hasQuest && data?.data?.length === 0;

  const content = useMemo(
    () => (
      <div className={styles.content}>
        <div className={styles.card}>
          <InfiniteScroll
            className={styles.list}
            dataLength={data?.data.length || 0}
            hasMore={data?.hasNextPage || false}
            next={() => setPerPage(perPage + 10)}
            loader={
              isLoading ? (
                <div className={styles.list__loader}>
                  <LoadingBox />
                </div>
              ) : null
            }
            scrollableTarget='scrollableDiv'
          >
            {data?.data.map((project, index) => (
              <ProjectCard key={project.id + index} data={project} />
            ))}
          </InfiniteScroll>
        </div>
      </div>
    ),
    [data, perPage, isLoading],
  );

  const list = useMemo(
    () => [
      {
        title: 'All projects',
        content: content,
      },
      {
        title: 'With quests',
        content: content,
      },
      {
        title: 'Without quests',
        content: content,
      },
    ],
    [content],
  );

  return (
    <PageLayout withSidebar={false}>
      {isProjectsEmpty ? (
        <div className={styles.inner}>
          <div className={styles.card}>
            <div className={styles.empty__inner}>
              <h1 className={styles.empty__title}>Welcome to Xsolla Quest System</h1>
              <p className={styles.empty__desc}>
                The whole range of tools necessary is available here. To start the integration
                process please create your first project.
              </p>
              <div className={styles.empty__buttons}>
                <Button onClick={() => navigate('/projects-import')} variant='secondary'>
                  Import project
                </Button>
                <Button onClick={() => navigate('/projects/create')} variant='primary'>
                  Create project
                </Button>
              </div>

              <div className={styles.empty__footer}>
                <Button leftIcon={<QuestionMarkCircledIcon />} variant='transparent'>
                  Help
                </Button>
                <Button leftIcon={<ChatBubbleIcon />} variant='transparent'>
                  Chat with us
                </Button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <PageTitle
            title='Projects'
            count={data?.total}
            action={
              profile?.role === UserRole.OWNER ? (
                <Link to='/projects/create'>
                  <Button variant='primary'>Create new project</Button>
                </Link>
              ) : undefined
            }
          />
          <Tabs
            defaultValue={activeTab}
            onTabChange={(tab) => {
              setActiveTab(tab);
              setPerPage(10);
            }}
            list={list}
          />
        </>
      )}
    </PageLayout>
  );
};

export default Projects;
