import { v4 as uuidv4 } from 'uuid';

import { getUTCOffset, getTime, getUTCOffsetForDate } from 'utils/utcOffset';

import { DefaultTimezone } from '../pages/createVirtualItem/mock';
import { ApiMethod, ApiUrlGet } from '../types/Api';
import { VirtualItemFields } from '../types/Forms';

import useApi from './useApi';
import { IVirtualItem } from './useVirtualItems';

export const convertData = (obj: IVirtualItem): VirtualItemFields => {
  const timeValue = obj.limits.recurrent_schedule?.per_user?.time;
  const timezone = timeValue ? getUTCOffset(timeValue) : DefaultTimezone;

  const time = timeValue && getTime(timeValue);
  const recurrentSchedule = obj.limits.recurrent_schedule;
  const limits = {
    per_user: obj.limits.per_user?.total || null,
    recurrent_schedule: recurrentSchedule
      ? {
          per_user: {
            ...obj.limits.recurrent_schedule?.per_user,
            time: time,
          },
        }
      : null,
  };

  const storeDate = obj.periods.length && obj.periods[0].date_from;
  const storeTimezone = storeDate ? getUTCOffsetForDate(storeDate) : undefined;
  const expirationPeriod = obj.inventory_options?.expiration_period;

  return {
    image_url: obj.image_url,
    sku: obj.sku,
    name: obj.name,
    groups: obj.groups,
    description: obj.description,
    inventory_options: {
      consumable: obj.inventory_options.consumable,
      expiration_period: expirationPeriod
        ? {
            type: obj.inventory_options?.expiration_period?.type,
            value: Number(obj.inventory_options?.expiration_period?.value),
          }
        : null,
    },
    is_free: obj.is_free,
    vc_pricing: obj.vc_pricing,
    limits_enable: obj.limits_enable,
    is_show_in_store: obj.is_show_in_store,
    periods: obj.periods,
    default_currency: obj.default_currency,
    limits: limits,
    prices: obj.prices.map((price) => {
      return {
        ...price,
        id: uuidv4(),
      };
    }),
    vc_prices: obj.vc_prices.map((price) => {
      return {
        ...price,
        id: uuidv4(),
        is_enabled: true,
      };
    }),
    timezone,
    storeTimezone,
  };
};

export default function useVirtualItem({
  projectId,
  sku,
  params = {},
  validation = {},
}: {
  projectId: string;
  sku: string;
  params?: any;
  validation?: any;
}) {
  const { data, sendRequest, error, isLoading } = useApi<IVirtualItem>({
    method: ApiMethod.get,
    url: ApiUrlGet.virtualItem.replace(':id', projectId).replace(':sku', sku),
    options: { params, validation },
  });
  const res = data;

  return { data: res, sendRequest, error, isLoading };
}
