import { yupResolver } from '@hookform/resolvers/yup';
import * as Form from '@radix-ui/react-form';
import React from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';

import Button from 'components/common/Button';
import Input from 'components/common/NewInput';
import PasswordInput from 'components/common/PasswordInput';
import { useAppDispatch } from 'hooks/storeHooks';
import AuthLayout from 'layout/authLayout/index';
import { signUpOwner } from 'store/user';
import { ISignUpRequestBody } from 'types/Auth';
import { passwordValidation } from 'utils/validations';

import styles from './SignUp.module.scss';

const SignUp: React.FC = () => {
  const dispatch = useAppDispatch();

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<ISignUpRequestBody>({
    resolver: yupResolver(passwordValidation),
  });

  const navigate = useNavigate();

  const onSubmit = handleSubmit((data) => {
    return dispatch(signUpOwner(data)).then((res: any) => {
      if (res?.payload) {
        return navigate('/signUp/success');
      }
    });
  });

  const liItems = [<li key='1'>Password must contain at least 6 characters</li>];

  return (
    <AuthLayout loading={isSubmitting} className={styles.sign_up__layout}>
      <h1 className={styles.sign_up__title}>Sign up as owner</h1>
      <Form.Root onSubmit={onSubmit} className={styles.sign_up__form}>
        <Input
          label='Email address'
          name='email'
          control={control}
          placeholder='Enter your email'
          error={errors.email}
        />

        <PasswordInput
          labelName='Create password'
          name='password'
          control={control}
          placeholder='......'
        ></PasswordInput>

        <ul className={styles.sign_up__helper}>{liItems} </ul>

        <PasswordInput
          labelName='Confirm password'
          name='confirmedPassword'
          control={control}
          placeholder='......'
        ></PasswordInput>

        <Form.Submit asChild>
          <Button
            disabled={!!Object.keys(errors).length}
            variant='primary'
            className={styles.sign_up__submit_margin}
          >
            Sign up
          </Button>
        </Form.Submit>

        <div className={styles.sign_up__sign_in}>
          Have an account? <Link to='/login'>Sign in</Link>
        </div>
      </Form.Root>
    </AuthLayout>
  );
};

export default SignUp;
