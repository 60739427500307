import { ApiMethod, ApiUrlGet } from '../types/Api';

import useApi from './useApi';

const convertTimezons = (currencies: string[]): any[] => {
  return currencies.map((currency: string) => ({
    value: currency,
    label: currency,
  }));
};

export default function useCurrencies() {
  const {
    data: obj,
    error,
    isLoading,
  } = useApi<string[]>({
    method: ApiMethod.get,
    url: ApiUrlGet.currencies,
  });
  const convertedData = obj && convertTimezons(obj);

  return { data: convertedData, error, isLoading };
}
