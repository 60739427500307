import { ApiMethod, ApiUrlGet } from '../types/Api';

import useApi from './useApi';

export default function useProjectApiKey(projectId: string) {
  const { data, error, isLoading, sendRequest } = useApi<{
    apiKey: string;
    xsollaProjectId: string;
  }>({
    method: ApiMethod.get,
    url: ApiUrlGet.projectApiKey.replace(':id', projectId),
  });

  return { data, error, isLoading, sendRequest };
}
