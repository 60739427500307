import classnames from 'classnames';
import React, { ChangeEvent, FC } from 'react';
import { useController, Control } from 'react-hook-form';

import styles from './Radio.module.scss';

type IRadioProps = {
  value: string;
  disabled?: boolean;
  readOnly?: boolean;
  className?: string;
  control?: Control<any>;
  name: string;
  label?: string;
  required?: boolean;
  labelClassName?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
};

const Radio: FC<IRadioProps> = ({
  disabled = false,
  readOnly = false,
  className,
  control,
  name,
  label,
  onChange,
  labelClassName,
  value,
  checked,
}) => {
  const { field } = useController<Record<string, string>>({
    control,
    name,
  });

  return (
    <div className={classnames(styles.wrapper)}>
      <input
        className={classnames(styles.wrapper__radio)}
        disabled={disabled}
        id={`${name}_${value}`}
        readOnly={readOnly}
        type='radio'
        {...field}
        value={value}
        onChange={(e) => {
          field.onChange(e);

          if (onChange) {
            onChange(e);
          }
        }}
      />
      {Boolean(label) && (
        <label
          htmlFor={`${name}_${value}`}
          className={classnames(styles.wrapper__label, labelClassName)}
        >
          <div
            className={classnames(
              styles.wrapper__label__dot,
              checked && styles.wrapper__label__dot__selected,
              className,
            )}
          />
          {label}
        </label>
      )}
    </div>
  );
};

export default Radio;
