import { ChevronRightIcon } from '@radix-ui/react-icons';
import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import dayjs from 'dayjs';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from 'components/common/Button';
import PageTitle from 'components/common/PageTitle';
import Table from 'components/common/Table';
import Tabs from 'components/common/Tabs';
import useUsers, { IUserWithProjectsCount } from 'hooks/useUsers';
import PageLayout from 'layout/PageLayout';

import styles from './ManageCompany.module.scss';

const ManageCompany = () => {
  const navigate = useNavigate();
  const { items, isLoading, handleScrollLoad } = useUsers({ validation: true });
  const [sorting, setSorting] = useState<SortingState>([]);

  const columnHelper = createColumnHelper<IUserWithProjectsCount>();

  const columns = [
    columnHelper.accessor('email', {
      cell: (info) => <b>{info.getValue()}</b>,
      header: () => <span>User email</span>,
      enableColumnFilter: false,
    }),
    columnHelper.accessor('projectsCount', {
      id: 'projectsCount',
      cell: (info) => (
        <span>
          {info.getValue() || 0} {info.getValue() === 1 ? 'project' : 'projects'}
        </span>
      ),
      header: () => <span>Projects associated</span>,
      enableColumnFilter: false,
    }),
    columnHelper.accessor((row) => row.createdAt, {
      id: 'jointOn',
      cell: (info) => <span>{dayjs(info.getValue()).format('MM / DD / YYYY')}</span>,
      header: () => <span>Joined on</span>,
      enableColumnFilter: false,
    }),
    columnHelper.accessor('id', {
      header: () => <span></span>,
      cell: (info) => (
        <Button
          onClick={() => navigate(`/manage-user/${info.getValue()}`)}
          variant='secondary'
          rightIcon={<ChevronRightIcon />}
        >
          Manage
        </Button>
      ),
      enableColumnFilter: false,
      enableSorting: false,
    }),
  ];

  const table = useReactTable({
    data: items || [],
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const list = useMemo(
    () => [
      {
        title: 'Users',
        content: (
          <div
            className={styles.content}
            onScroll={(e) => handleScrollLoad(e.target as HTMLDivElement)}
          >
            <Table
              emptyText='There are no users to display'
              isLoading={isLoading}
              table={table}
            ></Table>
          </div>
        ),
      },
      {
        title: 'Settings',
        content: (
          <div className={styles.content}>
            <div className={styles.card}>
              <p>Settings</p>
            </div>
          </div>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading, items, table, sorting],
  );

  return (
    <PageLayout loading={isLoading} withSidebar={false}>
      <PageTitle title='Manage company' />
      <Tabs list={list} />
    </PageLayout>
  );
};

export default ManageCompany;
