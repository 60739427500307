import classNames from 'classnames';
import React from 'react';

import styles from './Button.module.scss';
export interface IButtonProps {
  variant: string | undefined;
  disabled?: boolean;
  fullWidth?: boolean;
  className?: string;
  children?: string;
  onClick?: () => void;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  type?: 'button' | 'submit' | 'reset' | undefined;
}

const Button = ({
  disabled,
  variant,
  className,
  children,
  fullWidth,
  leftIcon,
  rightIcon,
  onClick,
  type = 'button',
}: IButtonProps) => {
  return (
    <button
      type={type}
      onClick={onClick}
      className={classNames(
        styles.button,
        styles[`button--${variant}`],
        { [styles['button--full-width']]: fullWidth },
        className,
      )}
      disabled={disabled}
    >
      {leftIcon ? <div className={styles.button__left_icon}>{leftIcon}</div> : null}
      {children}
      {rightIcon ? <div className={styles.button__right_icon}>{rightIcon}</div> : null}
    </button>
  );
};

export default Button;
