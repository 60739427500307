import * as yup from 'yup';

import { emailValidation } from 'utils/validations';

const schema = yup.object().shape({
  email: emailValidation,
  password: yup
    .string()
    .min(6, 'Password must be at least 6 characters')
    .required('Password is required'),
});

export default schema;
