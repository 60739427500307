import { CalendarIcon } from '@radix-ui/react-icons';
import classnames from 'classnames';
import React, { FC, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useController, Control } from 'react-hook-form';

import 'react-datepicker/dist/react-datepicker.css';

import { useOnClickOutside } from 'hooks/useOnClickOutside';

import styles from './DatePicker.module.scss';

type DatePickerProps = {
  className?: string;
  placeholder: string;
  control: Control<any>;
  name: string;
  selected: Date | null;
  disabled?: boolean;
  minDate?: Date | null;
  onChange?: (d?: Date) => void;
  error?: string | null;
};

const DatePickerField: FC<DatePickerProps> = ({
  placeholder,
  control,
  name,
  selected,
  disabled,
  className,
  minDate,
  onChange,
  error,
}) => {
  const { field } = useController<Record<string, string>>({
    control,
    name,
  });

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const divRef = useRef<HTMLDivElement>(null);

  const onChangeDate = (date: Date) => {
    field.onChange(date);

    if (onChange) {
      return onChange(date);
    }
  };

  useOnClickOutside(divRef, () => setIsOpen(false));

  return (
    <div
      className={classnames(styles.wrapper, className)}
      ref={divRef}
      onClick={() => !disabled && setIsOpen(true)}
    >
      <CalendarIcon className={styles.custom_datepicker_icon} />

      <DatePicker
        showIcon
        disabled={disabled}
        selected={selected}
        minDate={minDate}
        onChange={(date: Date) => onChangeDate(date)}
        disabledKeyboardNavigation
        placeholderText={placeholder}
        className={classnames({ [styles['wrapper--checked']]: isOpen })}
      />
      {!!error && <span className={styles.wrapper__error}>{error}</span>}
    </div>
  );
};

export default DatePickerField;
