import React from 'react';

import Tooltip from '../Tooltip';

import styles from './Tag.module.scss';

interface TagProps {
  value?: string;
  values?: string[];
  isShort?: boolean;
}

const Tag: React.FC<TagProps> = ({ value, values = [], isShort }) => {
  const text = isShort
    ? values.length > 1
      ? `${values[0]} + ${values.length - 1}`
      : values[0]
    : value;

  const tag = (
    <div className={styles.tag}>
      <span className={styles.tag__text}>{text}</span>
    </div>
  );

  if (isShort && values.length > 0) {
    return (
      <Tooltip
        content={
          <div className={styles['tag-list']}>
            {values.map((val) => (
              <div key={val} className={styles.tag}>
                <span className={styles.tag__text}>{val}</span>
              </div>
            ))}
          </div>
        }
      >
        {tag}
      </Tooltip>
    );
  }

  return value ? tag : null;
};

export default Tag;
