import * as RadixTabs from '@radix-ui/react-tabs';
import React, { useState } from 'react';

import styles from './Tabs.module.scss';

interface TabsProps {
  defaultValue?: string;
  list: { title: string; content: React.ReactNode }[];
  onTabChange?: (value: string) => void;
}

const Tabs: React.FC<TabsProps> = ({ list, defaultValue, onTabChange }) => {
  const [activeTab, setActiveTab] = useState(defaultValue || '0');

  return (
    // <div className={styles.header_container}>
    <RadixTabs.Root
      value={activeTab}
      onValueChange={(val) => {
        setActiveTab(val);

        if (onTabChange) {
          onTabChange(val);
        }
      }}
      className={styles.tabs}
      defaultValue={defaultValue}
    >
      <RadixTabs.List className={styles.tabs__list}>
        {list.map((item, index) => (
          <RadixTabs.Trigger className={styles.tabs__trigger} key={index} value={`${index}`}>
            {item.title}
          </RadixTabs.Trigger>
        ))}
      </RadixTabs.List>
      {list.map((item, index) => (
        <RadixTabs.Content className={styles.tabs__content} key={index} value={`${index}`}>
          {item.content}
        </RadixTabs.Content>
      ))}
    </RadixTabs.Root>
    // </div>
  );
};

export default Tabs;
