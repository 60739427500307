import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { ImageListType } from 'react-images-uploading';
import { useNavigate, useParams } from 'react-router-dom';

import Button from 'components/common/Button';
import PageTitle from 'components/common/PageTitle';
import { useAppDispatch } from 'hooks/storeHooks';
import useVirtualItem, { convertData } from 'hooks/useVirtualItem';
import PageLayout from 'layout/PageLayout';
import { createVirtualItem, updateVirtualItem } from 'store/reward';
import { Fields } from 'types/Forms';

import { Information } from './components/information';
import { ItemAttributes } from './components/itemAttributes';
import { ItemProperties } from './components/itemProperties';
import { Limits } from './components/limits';
import { PriceSettings } from './components/priceSettings';
import { Store } from './components/store';
import styles from './CreateVirtualItem.module.scss';
import { DefaultRewardState } from './mock';
import { convertFormForReq } from './utils';
import Schema from './validation';

const CreateVirtualItem: React.FC<{ isEdit?: boolean }> = ({ isEdit }) => {
  const { id: projectId = '', sku = '' } = useParams();

  const {
    control,
    watch,
    setValue,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<Fields>({
    resolver: yupResolver(Schema),
  });
  const virtualItem = watch('virtualItem');

  const [logoImage, setLogoImage] = React.useState<ImageListType>([]);
  const [spriteImage, setSpriteImage] = React.useState<ImageListType>([]);
  const { data: reward } = useVirtualItem({ projectId, sku, validation: !!sku });

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onSubmit = handleSubmit((data) => {
    const form = convertFormForReq(data.virtualItem);

    if (isEdit) {
      return dispatch(updateVirtualItem({ data: form, sku, projectId })).then((res: any) => {
        if (res.payload) {
          return navigate(`/project/${projectId}/rewards`);
        }
      });
    } else {
      return dispatch(createVirtualItem({ data: form, projectId })).then((res: any) => {
        if (res.payload) {
          return navigate(`/project/${projectId}/rewards`);
        }
      });
    }
  });

  useEffect(() => {
    if (!isEdit) {
      setValue(
        'virtualItem',
        {
          ...DefaultRewardState,
        },
        { shouldDirty: false },
      );
    } else if (isEdit && reward) {
      const form = convertData(reward);
      setValue('virtualItem', form, { shouldDirty: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, reward]);

  return (
    <PageLayout>
      <PageTitle title={isEdit ? 'Edit virtual item' : 'Create virtual item'} />

      {!!virtualItem && (
        <form onSubmit={onSubmit}>
          <div className={styles.section}>
            <Information
              logoImage={logoImage}
              setLogoImage={setLogoImage}
              spriteImage={spriteImage}
              setSpriteImage={setSpriteImage}
              control={control}
              watch={watch}
              setValue={setValue}
              isEdit={isEdit}
            />
          </div>

          <div className={styles.section}>
            <ItemProperties control={control} watch={watch} setValue={setValue} reset={reset} />
          </div>
          <div className={styles.section}>
            <PriceSettings
              control={control}
              watch={watch}
              setValue={setValue}
              isEdit={isEdit}
              reset={reset}
              errors={errors}
            />
          </div>

          <div className={classNames(styles.section, styles.last_section)}>
            <Limits control={control} watch={watch} setValue={setValue} />
          </div>

          <div className={classNames(styles.section, styles.last_section)}>
            <Store control={control} watch={watch} setValue={setValue} />
          </div>

          <div className={classNames(styles.section, styles.last_section)}>
            <ItemAttributes setValue={setValue} />
          </div>

          <div
            className={classNames(styles.create_reward__buttons_container, {
              [styles['create_reward__buttons_container--absolute']]: true,
            })}
          >
            <Button onClick={() => navigate(-1)} variant='secondary'>
              Cancel
            </Button>

            <Button variant='primary' type='submit' className={styles.create_reward__submit_button}>
              Save item
            </Button>
          </div>
        </form>
      )}
    </PageLayout>
  );
};

export default CreateVirtualItem;
