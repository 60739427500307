// eslint-disable-next-line import/no-extraneous-dependencies
import { format } from 'date-fns';
import filter from 'lodash/filter';
import map from 'lodash/map';
import reduce from 'lodash/reduce';

import { IQuest } from 'hooks/useQuests';
import { IVirtualItem } from 'hooks/useVirtualItems';
import { RewardType } from 'types';
import { CheckboxGroupItem, QuestFields } from 'types/Forms';

export enum Operation {
  select = 'select',
  unselect = 'unselect',
}

export function updateCheckedStatus(arr: CheckboxGroupItem[], operation: Operation, label: string) {
  return arr.map((item) => {
    if (item.continent === label || label === 'All') {
      return {
        ...item,
        checked: operation !== Operation.unselect,
      };
    } else {
      return item;
    }
  });
}

export const rewardsConverter = (data: IVirtualItem[]) => {
  return data.map((i) => ({
    value: i?.sku,
    label: i?.sku,
    type: i?.type,
  }));
};
export const getRewards = (virtualCurrencies: IVirtualItem[], virtualItems: IVirtualItem[]) => {
  const firstArr = rewardsConverter(virtualItems);
  const secondArr = rewardsConverter(virtualCurrencies);
  return [
    {
      title: true,
      label: `Virtual Items (${firstArr?.length})`,
      value: 'Virtual Items',
    },
    ...firstArr.map((i) => ({ ...i, type: RewardType.VIRTUAL_ITEM })),
    {
      title: true,
      label: `Virtual Currencies (${secondArr?.length})`,
      value: 'Virtual Currencies',
    },
    ...secondArr.map((i) => ({ ...i, type: RewardType.VIRTUAL_CURRENCY })),
  ];
};

export const convertFormForReq = (state: QuestFields) => {
  const topics = map(filter(state.topics, { checked: true }), 'label');
  const countries = map(filter(state.availableRegions, { checked: true }), 'countryId');
  const metadata = reduce<{ key: string; value: string }, Record<string, string>>(
    state.metadata_list,
    (acc, { key, value }) => {
      acc[key] = value;
      return acc;
    },
    {},
  );
  const webhooks = map(state.webhook_list, (i) => ({ url: i.value }));
  const conditions = map(state.triggers_list, (i) => ({
    eventName: i.name,
    hasBeenPerformed: JSON.parse(i.perform),
    amount: Number(i.least),
    within: Number(i.period_number),
    range: i.period,
    rangeType: i.times,
  }));
  const rewards = map(state.rewards_list, (i) => ({
    rewardId: i.reward_type,
    quantity: 1,
    claimType: i.claim_type,
    rewardType: i.custom,
  }));

  return {
    name: state.name,
    shortDescription: state.shortDescription,
    description: state.description,
    logo: state.logo,
    sprite: state.sprite,
    difficulty: state.difficulty,
    visibility: state.visibility,
    topics,
    completionsLimit: state.completionsLimit,
    startDate: state.startDate && format(state.startDate, 'yyyy-MM-dd'),
    endDate:
      state.endDate && state.duration === 'closing_date'
        ? format(state.endDate, 'yyyy-MM-dd')
        : null,
    availableRegions: countries,
    isDraft: false,
    trigger: {
      logicalOperator: state.logicalOperator,
      conditions,
    },
    rewards,
    webhooks,
    metadata,
  };
};

export const convertFormFromRes = (
  quest: IQuest,
  topicsState?: CheckboxGroupItem[],
  regionsState?: CheckboxGroupItem[],
) => {
  const topics =
    topicsState?.map((i) => {
      const topic = quest.topics?.find((value) => value?.title === i?.label);
      return {
        ...i,
        checked: !!topic,
      };
    }) || [];
  const availableRegions =
    regionsState?.map((i) => ({
      ...i,
      checked: quest.availableRegions.includes(Number(i?.countryId)),
    })) || [];
  const triggersList =
    quest.trigger?.conditions?.map((i) => ({
      name: i.eventName,
      perform: i.hasBeenPerformed.toString(),
      least: i.amount.toString(),
      times: i.rangeType,
      period_number: i.within.toString(),
      period: i.range,
    })) || [];
  const rewardsList =
    quest.rewards?.map((i) => ({
      reward_type: i.rewardId,
      claim_type: i.claimType,
      custom: i.rewardType,
    })) || [];
  const metadataList = Object.entries(quest.metadata || {}).map(([key, value]) => {
    return { key, value };
  });
  const webhookList = quest.webhooks?.map((i) => ({ value: i.url })) || [];
  return {
    logo: quest.logo,
    sprite: quest.sprite,
    name: quest.name,
    shortDescription: quest.shortDescription,
    description: quest.description,
    difficulty: quest.difficulty,
    topics,
    visibility: quest.visibility,
    completionsLimit: String(quest.completionsLimit),
    duration: quest.endDate ? 'closing_date' : 'perpetual',
    startDate: new Date(quest.startDate),
    endDate: new Date(quest.endDate),
    availableRegions,
    logicalOperator: quest.trigger.logicalOperator,
    triggers_list: triggersList,
    rewards_list: rewardsList,
    metadata_list: metadataList,
    webhook_list: webhookList,
  };
};
