import * as Tooltip from '@radix-ui/react-tooltip';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import Header from 'components/Header';
import LoadingBox from 'components/LoadingBox';
import Nav from 'components/Nav';
import { useAppDispatch, useAppSelector } from 'hooks/storeHooks';
import { fetchProjects } from 'store/project';
import {
  fetchProfile,
  selectIsAuthenticated,
  selectIsProfileLoaded,
  selectIsProfileLoading,
} from 'store/user';

import './styles.css';

interface PageLayoutProps {
  loading?: boolean;
  children?: React.ReactNode;
  withSidebar?: boolean;
}

const PageLayout: React.FC<PageLayoutProps> = ({
  loading = false,
  children,
  withSidebar = true,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const isProfileLoading = useAppSelector(selectIsProfileLoading);
  const isProfileLoaded = useAppSelector(selectIsProfileLoaded);

  useEffect(() => {
    if (isProfileLoaded) {
      if (!isAuthenticated) {
        navigate('/login');
      }
    } else {
      dispatch(fetchProfile());
      dispatch(fetchProjects());
    }
  }, [isProfileLoaded, isAuthenticated, dispatch, navigate]);

  return (
    <Tooltip.Provider delayDuration={300}>
      <div className='page-layout-container'>
        <Header />
        <div className='header-content-container'>
          {withSidebar ? <Nav /> : null}
          <main className='content-container'>
            {loading || isProfileLoading ? <LoadingBox /> : children}
          </main>
        </div>
        <ToastContainer />
      </div>
    </Tooltip.Provider>
  );
};

export default PageLayout;
