import { ClipboardCopyIcon, UpdateIcon } from '@radix-ui/react-icons';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import Button from 'components/common/Button';
import ConfirmDialog from 'components/common/ConfirmDialog';
import { IIconColorTypes, IIconTypes } from 'components/common/ConfirmDialog/interface';
import Input from 'components/common/NewInput';
import showToast from 'components/Toast';
import { ToastTypes } from 'components/Toast/toast.enum';
import { useAppDispatch } from 'hooks/storeHooks';
import useProjectApiKey from 'hooks/useProjectApiKey';
import { refreshApiKey } from 'store/project';

import styles from './Settings.module.scss';

const ApiKey = () => {
  const params = useParams();
  const projectId = params.id || '';
  const { data, sendRequest } = useProjectApiKey(projectId);
  const dispatch = useAppDispatch();
  const [refreshPopupOpen, setRefreshPopupOpen] = useState(false);

  const { control, setValue } = useForm<{ apiKey: string }>();

  useEffect(() => {
    if (data?.apiKey) {
      setValue('apiKey', data?.apiKey);
    }
  }, [data, setValue]);

  const refreshToken = () => {
    dispatch(refreshApiKey({ projectId })).then((res) => {
      if (res.payload) {
        sendRequest({});
      }
    });
    setRefreshPopupOpen(false);
  };

  const copyAPIKey = () => {
    navigator.clipboard.writeText(data?.apiKey || '');

    showToast({
      title: 'API key',
      description: 'Successfully copied to clipboard',
      type: ToastTypes.success,
    });
  };

  return (
    <div className={styles.card}>
      <ConfirmDialog
        onClose={() => setRefreshPopupOpen(false)}
        open={refreshPopupOpen}
        title='Refresh API key'
        primaryButton='Refresh'
        onPrimaryButtonClick={refreshToken}
        secondaryButton='Cancel'
        description='Are you sure you want to refresh this API key?'
        icon={IIconTypes.warning}
        iconColor={IIconColorTypes.orange}
      ></ConfirmDialog>
      <div className={styles.info}>
        <p className={styles.info__title}>API Key</p>
        <div className={styles.info__content}>
          <div>
            <Input
              label='Quest system API key'
              name={'apiKey'}
              control={control}
              placeholder='API key'
              readOnly
              disabled
            />
          </div>
          <div className={styles.info__content__buttons}>
            <Button
              onClick={() => setRefreshPopupOpen(true)}
              leftIcon={<UpdateIcon />}
              variant='transparent'
            >
              Refresh
            </Button>
            <Button onClick={copyAPIKey} leftIcon={<ClipboardCopyIcon />} variant='transparent'>
              Copy API Key
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApiKey;
