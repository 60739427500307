import React from 'react';
import { Control, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { ImageListType } from 'react-images-uploading';

import Hint from 'components/common/Hint';
import ImageUploader from 'components/common/ImageUploader';
import Label from 'components/common/Label';
import Input from 'components/common/NewInput';
import SingleSelect from 'components/common/SingleSelect';
import TextArea from 'components/common/TextArea';
import { Fields } from 'types/Forms';

import styles from './Information.module.scss';

type InformationProps = {
  control: Control<Fields>;
  watch: UseFormWatch<Fields>;
  setValue: UseFormSetValue<Fields>;
  logoImage: ImageListType | [];
  setLogoImage: (i: ImageListType) => void;
  spriteImage: ImageListType | [];
  setSpriteImage: (i: ImageListType) => void;
  isEdit?: boolean;
};

export const Information: React.FC<InformationProps> = ({ control, watch, setValue, isEdit }) => {
  const liItems = [<li key='1'>Use English</li>];
  const virtualItem = watch('virtualItem');

  return (
    <div className={styles.info}>
      <p className={styles.info__title}>Basic settings</p>
      <div className={styles.info__content}>
        <div className={styles.info__content__image_uploader}>
          <div>
            <Label label='Image' />
            <ImageUploader
              name='virtualItem.image_url'
              title='Upload image'
              setValue={setValue}
              watch={watch}
              acceptType={['png', 'jpg', 'jpeg', 'webp']}
              maxFileSize={4e6}
            />
            <Hint>Use .png, .jpg or .webp file / 4MB max size</Hint>
          </div>
        </div>
        <Input
          label='SKU'
          name='virtualItem.sku'
          control={control}
          placeholder='Enter reward SKU'
          disabled={isEdit}
        />

        <div className={styles.wrapper}>
          <Label label='Groups' />
          <SingleSelect
            update={(value) => {
              setValue('virtualItem.groups', [value]);
            }}
            options={[
              { value: 'Group 1', label: 'Group 1' },
              { value: 'Group 2', label: 'Group 2' },
            ]}
            placeholder='Select group'
            defaultValue={virtualItem.groups?.length ? virtualItem.groups[0] : ''}
            disabled={true}
          />
        </div>

        <div>
          <Input
            label='Item name'
            name='virtualItem.name.en'
            control={control}
            placeholder='Enter reward name'
          />
          <ul className={styles.info__content__helper}>{liItems} </ul>
        </div>

        <div>
          <TextArea
            label='Description'
            name='virtualItem.description.en'
            control={control}
            rows={8}
            placeholder='Write a full description of the reward'
          />
          <ul className={styles.info__content__helper}>{liItems} </ul>
        </div>
      </div>
    </div>
  );
};
