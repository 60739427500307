import { AxiosError, AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import capitalize from 'lodash/capitalize';

import Toast from 'components/Toast';

const onRequest = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  if (config.headers) {
    config.headers['X-Language'] = 'EN';
  }

  return config;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  // console.error(`[request error] [${JSON.stringify(error)}]`);
  return Promise.reject(error);
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  // console.info(`[response] [${JSON.stringify(response)}]`);
  return response;
};

const onResponseError = (error: AxiosError): Promise<AxiosError> => {
  // console.error(`[response error] [${JSON.stringify(error)}]`);
  // const message = error.message || 'An error occurred. Please try again.';

  if (error?.response?.status === 422) {
    const data = error?.response?.data as {
      errors: [{ field: string; errors: string[] }];
      message: string;
    };

    Toast({
      title: data?.message || '',
      description: data.errors
        ?.map((errorText) => `${capitalize(errorText.field)}: ${errorText.errors?.join(', ')}`)
        .join('; '),
    });
  }

  if (
    error?.response?.status === 400 ||
    error?.response?.status === 403 ||
    error?.response?.status === 404 ||
    error?.response?.status === 409 ||
    error?.response?.status === 500
  ) {
    const BLACKLIST_URLS = ['/auth/user/me'];
    if (BLACKLIST_URLS.some((url) => error?.request?.responseURL.includes(url))) {
      return Promise.reject(error);
    }

    const data = error?.response?.data as { error: string; message: string };

    Toast({ title: data.error || 'Error', description: data.message || data.toString() });
  }

  if (error?.response?.status === 401) {
    const data = error?.response?.data as { message: string };

    Toast({ title: 'Error', description: data?.message });
  }

  return Promise.reject(error);
};

export function setupInterceptorsTo(axiosInstance: AxiosInstance): AxiosInstance {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
}
