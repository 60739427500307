import { Cross2Icon, MagnifyingGlassIcon } from '@radix-ui/react-icons';
import classnames from 'classnames';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Control, useFieldArray } from 'react-hook-form';

import CheckboxGroup from 'components/common/CheckboxGroup';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import { Operation } from 'pages/createQuest/utils';
import { CheckboxGroupItem, Fields } from 'types/Forms';

import styles from './Multiselect.module.scss';

type StatisticsItem = {
  full: number;
  checked: number;
};

type Statistics = StatisticsItem & {
  [x: string]: StatisticsItem;
};

type MultiselectFieldProps = {
  control: Control<Fields>;
  data: CheckboxGroupItem[] | [];
  onReset: (operation: Operation, isCountry?: boolean, parent?: string) => void;
  //TODO: QuestFieldsName.topic. If Reusable a lot of names
  name: any;
  //TODO: Again. If Reusable a lot of states
  placeholder: string;
  checkboxClassName?: string;
  chipsClassName?: string;
  chipsWrapperClassName?: string;
  inputClassName?: string;
  checkboxWrapperClassName?: string;
  leftColumnTitle?: string;
  rightColumnTitle?: string;
  selectedFilter?: string;
  setSelectedFilter?: (i: string) => void;
  regions?: string[];
  onSubmit?: (item: string) => Promise<void>;
};

const MultiselectField: React.FC<MultiselectFieldProps> = ({
  control,
  data,
  name,
  placeholder,
  checkboxClassName,
  chipsClassName,
  chipsWrapperClassName,
  inputClassName,
  checkboxWrapperClassName,
  leftColumnTitle,
  rightColumnTitle,
  selectedFilter,
  setSelectedFilter,
  regions,
  onReset,
  onSubmit,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const divRef = useRef<HTMLDivElement>(null);
  const [chips, setChips] = useState<CheckboxGroupItem[]>([]);
  const [search, setSearch] = useState<string>('');
  const [statistics, setStatistics] = useState<Statistics>();
  const isRegionsChecked = selectedFilter && statistics?.[selectedFilter]?.checked;
  const isCheckedItem = statistics?.checked;
  const searchResult = useMemo(() => {
    return Boolean(data?.some((item) => item.label.toLowerCase().includes(search.toLowerCase())));
  }, [data, search]);

  useOnClickOutside(divRef, () => setIsOpen(false));

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && onSubmit) {
      event.preventDefault();
      setSearch('');
      onSubmit(search);
    }
  };

  useEffect(() => {
    const statisticsState = {} as Statistics;
    if (regions && selectedFilter) {
      regions.forEach((key: string) => {
        const item = key !== 'All' ? data?.filter((i) => i.continent === key) : data;
        return (statisticsState[key] = {
          full: item?.length,
          checked: item?.filter((i) => i.checked)?.length,
        });
      });
    } else {
      statisticsState.checked = data?.filter((i) => i.checked)?.length;
      statisticsState.full = data?.length;
    }
    setStatistics(statisticsState);
  }, [data, regions, selectedFilter]);

  const { update } = useFieldArray({
    control,
    name,
  });

  useEffect(() => {
    let checkboxes = [] as CheckboxGroupItem[];
    if (regions && statistics) {
      Object.keys(statistics).forEach((i, index) => {
        if (i !== 'All' && statistics[i].checked) {
          return (checkboxes = [
            ...checkboxes,
            {
              checked: statistics[i].checked,
              label: `${i} (${statistics[i].checked})`,
              id: index,
              parent: i,
            },
          ]);
        }
      });
    }
    setChips(regions ? checkboxes : data);
  }, [data, regions, statistics]);

  const onChipsUpdate = (item: CheckboxGroupItem, remove?: boolean, countryId?: string) => {
    if (regions && selectedFilter && statistics) {
      if (remove) {
        onReset(Operation.unselect, true, item.parent);
      } else {
        update(item.id, { ...item, checked: item.checked, countryId });
      }
    } else {
      update(item.id, { ...item, checked: remove ? false : item.checked });
      setChips(
        remove || !item.checked ? chips?.filter((value) => value.id !== item.id) : [...chips, item],
      );
    }
  };

  const onUnselectAll = () => {
    if (regions) {
      onReset(isRegionsChecked ? Operation.unselect : Operation.select, true);
    } else {
      onReset(isCheckedItem ? Operation.unselect : Operation.select);
    }
  };

  return (
    <div ref={divRef} className={styles.wrapper}>
      <div
        className={classnames(
          styles.wrapper__chips,
          !chips?.length && styles.invisible,
          chipsWrapperClassName,
        )}
      >
        {chips?.map((item, index: number) => {
          return (
            <div
              className={classnames(
                styles.wrapper__chips__item,
                !item.checked && styles.invisible,
                chipsClassName,
              )}
              key={index}
            >
              {item.label}
              <Cross2Icon
                onClick={() => onChipsUpdate(item, true)}
                className={styles.wrapper__chips__item__icon}
                width={18}
              />
            </div>
          );
        })}
      </div>
      <div className={styles.wrapper__search}>
        <MagnifyingGlassIcon width={17} height={17} className={styles.wrapper__search__left_icon} />
        <input
          onClick={() => setIsOpen(true)}
          className={classnames(
            styles.wrapper__search__input,
            isOpen && styles.wrapper__search__select,
            inputClassName,
          )}
          onKeyDown={handleKeyDown}
          placeholder={placeholder}
          value={search}
          onChange={(event) => setSearch(event.target.value)}
        />
        {search && (
          <Cross2Icon
            width={15}
            height={15}
            onClick={() => setSearch('')}
            className={styles.wrapper__search__right_icon}
          />
        )}
      </div>

      {isOpen && (
        <div
          className={classnames(styles.wrapper__content, {
            [styles['wrapper__content--col-2']]: regions,
          })}
        >
          {regions && (
            <div className={styles.wrapper__content__filters}>
              {leftColumnTitle && (
                <p className={styles.wrapper__content__filters__title}>{leftColumnTitle}</p>
              )}
              {regions?.map((value: string, index: number) => (
                <div
                  onClick={() => setSelectedFilter && setSelectedFilter(value)}
                  className={classnames(styles.wrapper__content__filters__item, {
                    [styles['wrapper__content__filters__item--checked']]: selectedFilter === value,
                  })}
                  key={`${value}_${index}`}
                >
                  <span>{value}</span>
                  <span>
                    {statistics?.[value].checked}/{statistics?.[value].full}
                  </span>
                </div>
              ))}
            </div>
          )}

          <div className={styles.wrapper__content__selects}>
            {
              <div
                className={classnames(
                  styles.wrapper__content__selects__menu,
                  checkboxWrapperClassName,
                )}
              >
                {rightColumnTitle && (
                  <p className={styles.wrapper__content__selects__menu__title}>
                    {selectedFilter && statistics?.[selectedFilter]?.full} {rightColumnTitle}
                  </p>
                )}
                {searchResult ? (
                  data.map((option, index: number) => (
                    <CheckboxGroup
                      key={`${option.label}_${option.id}_${index}`}
                      id={index}
                      label={option.label}
                      checked={Boolean(option.checked)}
                      continent={option.continent}
                      onChange={(i) => onChipsUpdate(i, false, option.countryId)}
                      code={option.code}
                      labelClassName={classnames(
                        styles.wrapper__content__selects__menu__label,
                        {
                          [styles.invisible]:
                            (selectedFilter !== 'All' && option.continent !== selectedFilter) ||
                            (search && !option.label.toLowerCase().includes(search.toLowerCase())),
                        },
                        checkboxClassName,
                      )}
                    />
                  ))
                ) : (
                  <p className={styles.wrapper__content__selects__menu__not_found}>Not found</p>
                )}
              </div>
            }
            <div
              className={classnames(styles.wrapper__content__selects__menu__statistic, {
                [styles['wrapper__content__selects__menu__statistic--roundness-border']]: !regions,
              })}
            >
              <span onClick={onUnselectAll}>
                {isRegionsChecked || statistics?.checked ? 'Unselect All' : 'Select All'}
              </span>

              <p>
                {regions
                  ? `${isRegionsChecked} countries selected`
                  : `${statistics?.checked} of ${statistics?.full} items selected`}
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MultiselectField;
