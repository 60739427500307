import { CaretRightIcon } from '@radix-ui/react-icons';
import React from 'react';

import { IPageTitleProps } from './interface';
import styles from './PageTitle.module.scss';

const PageTitle = ({
  title,
  breadcrumbs,
  count,
  action,
  onBreadcrumbClick = () => {},
}: IPageTitleProps) => {
  return (
    <div className={styles.page_title}>
      <div className={styles.page_title__text}>
        {breadcrumbs ? <b onClick={onBreadcrumbClick}>{breadcrumbs}</b> : null}
        {breadcrumbs ? <CaretRightIcon width={30} height={30} /> : null}
        {title} <span>{count}</span>
      </div>
      <div className={styles.page_title__action}>{action}</div>
    </div>
  );
};

export default PageTitle;
