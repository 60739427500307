import { Column, Table } from '@tanstack/react-table';
import React from 'react';
import { Control, useForm } from 'react-hook-form';

import Input from 'components/common/NewInput';
import { Fields } from 'types/Forms';

interface FilterProps {
  column: Column<object, unknown>;
  table: Table<object>;
  control?: Control<Fields>;
}

export const Filter = ({ column }: FilterProps) => {
  // const firstValue = table.getPreFilteredRowModel().flatRows[0]?.getValue(column.id);
  // const columnFilterValue = column.getFilterValue();
  // const facetedUniqueValues = column.getFacetedUniqueValues();

  const { control } = useForm<object>({});

  return (
    <Input
      type='text'
      name={column.id}
      control={control}
      // value={(columnFilterValue ?? '') as string}
      onChange={(e) => column.setFilterValue(e.target.value)}
      placeholder='Search'
    />
  );
};
