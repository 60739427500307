import { CaretDownIcon, CaretSortIcon, CaretUpIcon } from '@radix-ui/react-icons';
import { Table, flexRender } from '@tanstack/react-table';
import React from 'react';

import LoadingBox from 'components/LoadingBox';

import { Filter } from './Filter';
import styles from './Table.module.scss';

interface TableProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  table: Table<any>;
  isLoading?: boolean;
  emptyText?: string;
  footer?: React.ReactNode;
}

const CustomTable: React.FC<TableProps> = ({ table, isLoading, emptyText, footer }) => {
  return (
    <div>
      <div className={styles.table_wrapper}>
        <table className={styles.table}>
          <thead className={styles.table__thead}>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id}>
                    {header.isPlaceholder ? (
                      <span />
                    ) : (
                      <>
                        <div
                          onClick={header.column.getToggleSortingHandler()}
                          className={styles.table__thead__sort}
                        >
                          <span>
                            {flexRender(header.column.columnDef.header, header.getContext())}
                          </span>{' '}
                          {header.column.getCanSort() ? (
                            <>
                              {header.column.getIsSorted() === 'asc' && (
                                <CaretUpIcon color='#0073F7' />
                              )}
                              {header.column.getIsSorted() === 'desc' && (
                                <CaretDownIcon color='#0073F7' />
                              )}
                              {!header.column.getIsSorted() && <CaretSortIcon />}
                            </>
                          ) : null}
                        </div>
                        <div className={styles.table__thead__filter}>
                          {header.column.getCanFilter() ? (
                            <div>
                              <Filter column={header.column} table={table} />
                            </div>
                          ) : null}
                        </div>
                      </>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className={styles.table__tbody}>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                ))}
              </tr>
            ))}
          </tbody>
          <tfoot>
            {table.getFooterGroups().map((footerGroup) => (
              <tr key={footerGroup.id}>
                {footerGroup.headers.map((header) => (
                  <th key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(header.column.columnDef.footer, header.getContext())}
                  </th>
                ))}
              </tr>
            ))}
          </tfoot>
        </table>
        {isLoading ? (
          <div className={styles.table__loader}>
            <LoadingBox variant='small' />
          </div>
        ) : null}
        {!isLoading && table.getRowModel().rows.length === 0 && emptyText ? (
          <p className={styles.table__empty}>{emptyText}</p>
        ) : null}
        {!isLoading && table.getRowModel().rows.length > 0 && footer ? (
          <div className={styles.table__footer}>{footer}</div>
        ) : null}
      </div>
    </div>
  );
};
export default CustomTable;
