import { BarChartIcon, GearIcon, MixIcon, PersonIcon, RocketIcon } from '@radix-ui/react-icons';
import compact from 'lodash/compact';
import React from 'react';
import { NavLink, useParams } from 'react-router-dom';

import { useAppSelector } from 'hooks/storeHooks';
import { selectProjects } from 'store/project';
// eslint-disable-next-line import/order
import { selectProfile } from 'store/user';
import { UserRole } from 'types';

import styles from './Nav.module.scss';

const Nav = () => {
  const profile = useAppSelector(selectProfile);
  const params = useParams();
  const projects = useAppSelector(selectProjects) || [];
  const currentProject = projects.find((p) => p.id === params.id);
  const currentUserRole = currentProject?.currentUser?.role;

  const menu = compact([
    { title: 'Quests', icon: <MixIcon />, href: `/project/${params.id}/quests` },
    { title: 'Statistics', icon: <BarChartIcon />, href: `/project/${params.id}/statistics` },
    { title: 'Rewards', icon: <RocketIcon />, href: `/project/${params.id}/rewards` },
    (currentUserRole === UserRole.ADMIN || profile?.role === UserRole.OWNER) && {
      title: 'Users',
      icon: <PersonIcon />,
      href: `/project/${params.id}/users`,
    },
    (currentUserRole === UserRole.ADMIN || profile?.role === UserRole.OWNER) && {
      title: 'Settings',
      icon: <GearIcon />,
      href: `/project/${params.id}/settings`,
    },
  ]);
  const handleNavLinks = (item, key) => {
    return (
      <li key={key}>
        <NavLink active='true' activeclassname={styles.active} to={item.href} key={key}>
          <div className={styles.nav_item}>
            {item.icon}
            {item.title}
          </div>
        </NavLink>
      </li>
    );
  };

  return (
    <div className={styles.navigation}>
      <ul>
        {menu.map((item, key) => {
          return handleNavLinks(item, key);
        })}
      </ul>
      <p className={styles.logo}>Xsolla</p>
    </div>
  );
};
export default Nav;
