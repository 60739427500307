import { MagnifyingGlassIcon, CaretDownIcon, CaretUpIcon } from '@radix-ui/react-icons';
import classnames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

import { useOnClickOutside } from 'hooks/useOnClickOutside';

import styles from './SingleSelect.module.scss';

export enum SingleSelectEnum {
  search = 'search',
  select = 'select',
}

type SingleSelectProps = {
  inputClassName?: string;
  placeholder?: string;
  type?: SingleSelectEnum;
  defaultValue: string;
  update: (value: string, custom?: any) => void;
  options: { value: string; label: string; type?: string; title?: boolean }[];
  disabled?: boolean;
  error?: string;
};

const SingleSelect: React.FC<SingleSelectProps> = ({
  inputClassName,
  placeholder,
  type = SingleSelectEnum.select,
  defaultValue,
  update,
  options,
  disabled,
  error,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const divRef = useRef<HTMLDivElement>(null);
  const isSearchable = type === SingleSelectEnum.search;
  const label = options.find((i) => i.value === defaultValue)?.label;

  useOnClickOutside(divRef, () => {
    setIsOpen(false);
    if (isSearchable) {
      setSearch(defaultValue);
    }
  });

  useEffect(() => {
    if (isSearchable) {
      setSearch(defaultValue);
    }
  }, [defaultValue, isSearchable, type]);

  const onSearchClick = () => {
    setIsOpen(!isOpen);
  };

  const onInputClick = (value: string, listType?: string) => {
    setIsOpen(false);
    update(value, listType);
    if (isSearchable) {
      setSearch('');
    }
  };

  return (
    <div ref={divRef} className={styles.wrapper}>
      <div className={styles.wrapper__search}>
        {isSearchable && (
          <MagnifyingGlassIcon
            width={17}
            height={17}
            className={styles.wrapper__search__left_icon}
          />
        )}
        <input
          disabled={disabled}
          onClick={onSearchClick}
          className={classnames(
            styles.wrapper__search__input,
            { [styles['wrapper__search__input--selected']]: isOpen },
            { [styles['wrapper__search__input--with_search']]: !isSearchable },
            { [styles['wrapper__search__input--error']]: error && !defaultValue },
            inputClassName,
          )}
          placeholder={placeholder}
          value={isSearchable ? search : label}
          readOnly={!isSearchable}
          onChange={(event) => setSearch(event.target.value)}
        />
        {isOpen ? (
          <CaretUpIcon width={20} height={20} className={styles.wrapper__search__right_icon} />
        ) : (
          <CaretDownIcon width={20} height={20} className={styles.wrapper__search__right_icon} />
        )}
      </div>
      {isOpen && (
        <div className={classnames(styles.wrapper__content)}>
          {options.map((item, index) => (
            <div
              onClick={() => !item?.title && onInputClick(item.value, item?.type)}
              className={classnames(
                styles.wrapper__content__item,
                {
                  [styles['wrapper__content__item--selected']]: defaultValue === item.value,
                },
                {
                  [styles['wrapper__content__item--titling']]: item?.title,
                },
                {
                  [styles['wrapper__content__item--invisible']]:
                    isSearchable &&
                    !item.value.toLowerCase().includes(search.toLowerCase()) &&
                    search !== defaultValue,
                },
              )}
              key={`${item.value}_${item.label}_${index}`}
            >
              {item.label}
            </div>
          ))}
        </div>
      )}
      {error && !defaultValue && <span className={styles.wrapper__error}>{error}</span>}
    </div>
  );
};

export default SingleSelect;
