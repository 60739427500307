import { VirtualCurrencyFields } from 'types/Forms';

export const DefaultCurrencyState = {
  image_url: '',
  sku: '',
  name: {
    en: '',
  },
  is_hard: false,
  prices: [],
  periods: [],
} as VirtualCurrencyFields;
