import React from 'react';
import { toast } from 'react-toastify';

import { ToastTypes } from './toast.enum';
import { ToastComponentInt, ShowToastInt } from './toast.interface';
import styles from './Toast.module.scss';

const Toast = ({ message, descriptionMessage, type }: ToastComponentInt) => {
  const className = `${styles.toast__title} ${
    type === ToastTypes.error ? styles.toast__error : styles.toast__success
  }`;

  return (
    <div>
      <div className={className}>{message}</div>
      <div className={styles.toast__description}>{descriptionMessage}</div>
    </div>
  );
};

function showToast({ title, type = ToastTypes.error, description }: ShowToastInt) {
  toast.error(<Toast message={title} descriptionMessage={description} type={type} />, {
    closeButton: false,
    icon: false,
    hideProgressBar: true,
    autoClose: 5000,
    position: 'bottom-right',
  });
}

export default showToast;
