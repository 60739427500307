import * as yup from 'yup';

const emailValidation = yup
  .string()
  .matches(
    // eslint-disable-next-line no-useless-escape
    /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/,
    { message: 'Please enter correct email' },
  )
  .required('Email is required');

const passwordValidation = yup.object().shape({
  password: yup
    .string()
    .min(6, 'Password must be at least 6 characters')
    .required('Password is required'),
  confirmedPassword: yup
    .string()
    .min(6, 'Password must be at least 6 characters')
    .required('Password is required')
    .oneOf([yup.ref('password')], 'Passwords must match'),
});

export { emailValidation, passwordValidation };
