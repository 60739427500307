import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import PageTitle from 'components/common/PageTitle';
import { useAppDispatch } from 'hooks/storeHooks';
import PageLayout from 'layout/PageLayout';
import { createProject } from 'store/project';
import { Fields } from 'types/Forms';

import { Information } from './components/information';
import styles from './CreateProject.module.scss';
import { createSchema } from './validation';

const CreateProject = () => {
  const dispatch = useAppDispatch();
  const {
    control,
    watch,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<Fields>({
    resolver: yupResolver(createSchema),
    defaultValues: {
      project: {
        cover: '',
        title: '',
        content: '',
        xsollaProjectId: '',
        users: [],
      },
    },
  });

  const navigate = useNavigate();

  const onSubmit = handleSubmit((data) => {
    return dispatch(createProject(data.project)).then((res: any) => {
      if (res.payload) {
        return navigate('/projects');
      }
    });
  });

  return (
    <PageLayout loading={isSubmitting} withSidebar={false}>
      <PageTitle
        onBreadcrumbClick={() => navigate('/projects')}
        breadcrumbs='Projects'
        title='Create new project'
      />
      <form className={styles.project} onSubmit={onSubmit}>
        <Information control={control} watch={watch} setValue={setValue} isValid={true} />
      </form>
    </PageLayout>
  );
};

export default CreateProject;
