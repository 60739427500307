import throttle from 'lodash/throttle';
import uniqBy from 'lodash/uniqBy';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { TableStatus } from 'components/common/Status';
import { Difficulty, IQuestReward, Visibility } from 'types';

import { ApiMethod, ApiUrlGet } from '../types/Api';

import useApi from './useApi';

export interface IQuest {
  id: string;
  name: string;
  shortDescription: string;
  description: string;
  logo: string;
  sprite: string;
  difficulty: Difficulty;
  visibility: Visibility;
  topics: {
    id: string;
    title: string;
    projectId: string;
  }[];
  completionsLimit: number;
  startDate: string;
  endDate: string;
  availableRegions: number[];
  isDraft: boolean;
  status: TableStatus;
  trigger: {
    logicalOperator: string; // @TODO: update to enum AND / OR and etc..
    conditions: {
      rangeType: string;
      eventName: string;
      hasBeenPerformed: boolean;
      amount: number;
      within: number;
      range: string; // @TODO: update to enum months/days and etc..
    }[];
  };
  rewards: IQuestReward[];
  webhooks: {
    url: string;
  }[];
  metadata: {
    custom_key: string;
  };
}

export interface IQuestsResponse {
  data: IQuest[];
  limit: number;
  offset: number;
  total: number;
}

interface IQuestsHook {
  projectId: string;
  params?: object;
  validation?: boolean;
}

export default function useQuests({ projectId, params = {}, validation }: IQuestsHook) {
  const [limit] = useState(20);
  const [offset, setOffset] = useState(0);
  const [items, setItems] = useState<IQuest[]>([]);
  const [hasMore, setHasMore] = useState(false);
  const { data, error, isLoading, sendRequest, resetData } = useApi<IQuestsResponse>({
    method: ApiMethod.get,
    url: ApiUrlGet.quests.replace(':id', projectId),
    options: { params: { limit, offset, ...params }, validation },
  });

  useEffect(() => {
    if (data?.data && data?.data?.length > 0) {
      setItems(offset === 0 ? data.data : uniqBy([...items, ...data.data], 'id'));

      setHasMore(data.data.length === limit);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, limit]);

  const handleScroll = useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        if (scrollHeight - scrollTop - clientHeight < 100 && !isLoading) {
          if (hasMore) {
            setOffset(offset + limit);
          }
        }
      }
    },
    [isLoading, hasMore, offset, limit],
  );

  const throttledHandleScroll = useMemo(() => throttle(handleScroll, 200), [handleScroll]);

  const reload = () => {
    setItems([]);
    resetData();
    setOffset(0);
    setHasMore(true);
    sendRequest({});
  };

  return {
    data,
    items,
    error,
    isLoading,
    sendRequest,
    handleScrollLoad: throttledHandleScroll,
    reload,
  };
}
