import { CheckIcon } from '@radix-ui/react-icons';
import React from 'react';
import { Link } from 'react-router-dom';

import AuthLayout from 'layout/authLayout/index';

import styles from './ForgotPassword.module.scss';

const ForgotPasswordSuccess: React.FC = () => {
  // process.env.SUPPORT_EMAIL is undefined
  return (
    <AuthLayout>
      <div className={styles.success__icon}>
        <CheckIcon className={styles.success__check_icon} />
      </div>
      <h1 className={styles.forgot_password__title}>Message sent</h1>
      <div className={styles.success__description}>
        Please check the email that you entered previously to reset your password
      </div>

      <div className={styles.success__questions}>
        Any questions?
        <Link to='mailto:example@example.com' className={styles.success__email}>
          support@quests.com
        </Link>
      </div>
    </AuthLayout>
  );
};

export default ForgotPasswordSuccess;
