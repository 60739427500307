import { AxiosError, AxiosResponse } from 'axios';
import isEmpty from 'lodash/isEmpty';
import { useCallback, useEffect, useMemo, useState } from 'react';
import useSWR from 'swr';

import api from '../API/api';
import { IErrorResponse, IOptions, IOptionsMutate } from '../types';
import { ApiMethod, ApiUrl, AxiosMethodsParams, IAxiosMethodsParamsWithBody } from '../types/Api';
import queryParamsString from '../utils/queryParams';

type IAPIProps = AxiosMethodsParams & {
  method: ApiMethod;
  options?: IOptions;
};

export default function useApi<T>({ method, url, options = { params: {} } }: IAPIProps) {
  const [data, setData] = useState<T | undefined>(undefined);
  const [error, setError] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const urlWithParams = useMemo(() => {
    if (!url) return null;
    const fetchUrl = url as string;
    const { params, dynamicP } = options;

    if (params && !isEmpty(params)) {
      if (dynamicP) return `${fetchUrl}/${params[Object.keys(params)[0]]}`;
      else {
        const qs = queryParamsString(params);

        return `${fetchUrl}${qs && '?'}${qs}`;
      }
    }
    return fetchUrl;
  }, [options, url]);

  const getUrl = useMemo(() => {
    if (urlWithParams && method === ApiMethod.get) {
      if ('validation' in options) return options.validation ? urlWithParams : null;
      return urlWithParams;
    }
    return null;
  }, [method, options, urlWithParams]);

  const { data: swrData, error: swrError, isValidating } = useSWR(getUrl);
  const mutateData = useCallback(
    async (
      mutateUrl: AxiosMethodsParams['url'],
      body: IAxiosMethodsParamsWithBody['body'],
      optionsWithParams: IOptionsMutate,
    ): Promise<T> => {
      let dataToReturn: unknown | T;
      try {
        setIsLoading(true);

        if (method === ApiMethod.delete) {
          await api[method](mutateUrl, optionsWithParams);
        } else {
          await api[method](mutateUrl, body, optionsWithParams)
            .then((res) => {
              if ('code' in res && 'message' in res) {
                const resError = res as IErrorResponse;
                setError(resError);
              } else {
                const { status, data: resData } = res as AxiosResponse<T>;
                if (parseInt(status.toString()[0]) < 4) {
                  setError(null);
                  setData(resData || ('success' as any));
                }
                dataToReturn = resData;
              }
            })
            .catch((err: AxiosError) => {
              setError(err);
            });
        }
      } catch (err) {
        setError(err);
      } finally {
        setIsLoading(false);
      }
      return dataToReturn as T;
    },
    [method],
  );

  const sendRequest = useCallback(
    (body: IAxiosMethodsParamsWithBody['body'], optionsParams?: IOptionsMutate): Promise<T> =>
      mutateData(urlWithParams as ApiUrl, body, optionsParams || { params: {} }),
    [mutateData, urlWithParams],
  );

  const resetData = useCallback(() => setData(undefined), []);
  const resetError = useCallback(() => setError(undefined), []);

  useEffect(() => {
    if (method === ApiMethod.get) {
      setData(swrData?.data);
      setError(swrError);
      setIsLoading(isValidating);
    }
  }, [isValidating, setData, setError, setIsLoading, method, swrData, swrError]);

  return {
    data,
    error,
    isLoading,
    sendRequest,
    resetData,
    resetError,
  };
}
