import React, { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import DropdownButton from 'components/common/DropdownButton';
import PageTitle from 'components/common/PageTitle';
import Tabs from 'components/common/Tabs';
import PageLayout from 'layout/PageLayout';

import TableVirtualCurrencies from './TableVirtualCurrencies';
import TableVirtualItems from './TableVirtualItems';

const Rewards = () => {
  const params = useParams();
  const projectId = params.id || '';
  const [count, setCount] = useState(0);
  const navigate = useNavigate();

  const list = useMemo(
    () =>
      projectId
        ? [
            {
              title: 'Virtual items',
              content: <TableVirtualItems projectId={projectId} setCount={setCount} />,
            },
            {
              title: 'Virtual currencies',
              content: <TableVirtualCurrencies projectId={projectId} setCount={setCount} />,
            },
          ]
        : [],
    [projectId],
  );

  return (
    <PageLayout>
      <PageTitle
        title='Rewards'
        count={count}
        action={
          <DropdownButton
            items={[
              {
                title: 'Create item',
                action: () => navigate(`/project/${projectId}/virtual-items/create`),
              },
              {
                title: 'Create currency',
                action: () => navigate(`/project/${projectId}/virtual-currencies/create`),
              },
            ]}
            title='Create a reward'
            editable
          />
        }
      />
      <Tabs list={list} />
    </PageLayout>
  );
};

export default Rewards;
