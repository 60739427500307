import React from 'react';
import { Control, Controller } from 'react-hook-form';

import Label from 'components/common/Label';
import SingleSelect from 'components/common/SingleSelect';
import { useAppSelector } from 'hooks/storeHooks';
import { IProject } from 'hooks/useProjects';
import { USER_ROLES_OPTIONS } from 'pages/users/constants';
import { selectProjects } from 'store/project';
import { Fields } from 'types/Forms';

import styles from './ManageUser.module.scss';

const AddProjectForm: React.FC<{
  control: Control<Fields>;
  userProjects: IProject[];
}> = ({ control, userProjects }) => {
  const projects =
    useAppSelector(selectProjects)
      ?.filter((project) => !userProjects.map((p) => p.id).includes(project.id))
      ?.map((project) => ({ value: project.id, label: project.title })) || [];

  return (
    <div className={styles.form}>
      {projects.length ? (
        <>
          <div className={styles.form__row}>
            <Label label='Project' />
            <Controller
              render={({ field: { value, onChange } }) => (
                <SingleSelect
                  defaultValue={value}
                  placeholder='Select project'
                  options={projects}
                  update={(data) => onChange(data)}
                />
              )}
              name='addProjectToUser.projectId'
              control={control}
            />
          </div>
          <div className={styles.form__row}>
            <Label label='Role' />
            <Controller
              render={({ field: { value, onChange } }) => (
                <SingleSelect
                  defaultValue={value}
                  placeholder='Select role'
                  options={USER_ROLES_OPTIONS}
                  update={(data) => onChange(data)}
                />
              )}
              name='addProjectToUser.role'
              control={control}
            />
          </div>
        </>
      ) : (
        <div className={styles.form__empty}>There are no projects to add</div>
      )}
    </div>
  );
};

export default AddProjectForm;
