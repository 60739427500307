import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import PageTitle from 'components/common/PageTitle';
import Tabs from 'components/common/Tabs';
import { useAppDispatch } from 'hooks/storeHooks';
import useProject from 'hooks/useProject';
import PageLayout from 'layout/PageLayout';
import { Information } from 'pages/createProject/components/information';
import { updateSchema } from 'pages/createProject/validation';
import { updateProject } from 'store/project';
import { Fields } from 'types/Forms';

import ApiKey from './ApiKey';
import styles from './Settings.module.scss';

const Settings = () => {
  const params = useParams();
  const projectId = params.id || '';
  const { data } = useProject(projectId);
  const dispatch = useAppDispatch();

  const {
    control,
    watch,
    setValue,
    handleSubmit,
    trigger,
    formState: { isValid },
  } = useForm<Fields>({
    resolver: yupResolver(updateSchema),
    defaultValues: {
      projectUpdate: {
        cover: '',
        title: 'Project title',
        content: 'Project description',
      },
    },
  });

  useEffect(() => {
    if (data) {
      setValue('projectUpdate', {
        cover: data?.cover,
        title: data?.title,
        content: data?.content,
      });
    }
  }, [setValue, data, trigger]);

  const navigate = useNavigate();

  const onSubmit = handleSubmit((form) => {
    return dispatch(updateProject({ projectId, data: form.projectUpdate })).then((res: any) => {
      if (!res.error) {
        return navigate(`/project/${projectId}/quests`);
      }
    });
  });

  const settings = useMemo(
    () => (
      <div className={styles.content}>
        <div className={styles.card}>
          <form className={styles.project} onSubmit={onSubmit}>
            <Information
              setValue={setValue}
              control={control}
              watch={watch}
              isValid={isValid}
              isEdit={true}
              isAbsoluteFooter={true}
              projectId={projectId}
            />
          </form>
        </div>
      </div>
    ),
    [control, isValid, onSubmit, setValue, watch, projectId],
  );

  const list = useMemo(
    () => [
      {
        title: 'General settings',
        content: settings,
      },
      {
        title: 'API Key',
        content: (
          <div className={styles.content}>
            <ApiKey />
          </div>
        ),
      },
    ],
    [settings],
  );

  return (
    <PageLayout>
      <PageTitle title='Project settings' />
      <Tabs list={list} />
    </PageLayout>
  );
};

export default Settings;
