import { v4 as uuidv4 } from 'uuid';

import { ApiMethod, ApiUrlGet } from '../types/Api';
import { VirtualCurrencyFields } from '../types/Forms';

import useApi from './useApi';

export const convertData = (obj: any): VirtualCurrencyFields => {
  return {
    image_url: obj.image_url || '',
    sku: obj.sku,
    name: obj.name,
    is_hard: obj.is_hard,
    prices: obj.prices.map((price: any) => {
      return {
        id: uuidv4(),
        amount: price.amount,
        country_iso: price.country_iso,
        currency: price.currency,
        is_default: price.is_default,
        is_enabled: price.is_enabled,
      };
    }),
  };
};

export default function useVirtualCurrency({
  projectId,
  sku,
  params = {},
  validation = {},
}: {
  projectId: string;
  sku: string;
  params?: any;
  validation?: any;
}) {
  const { data, sendRequest, error, isLoading } = useApi<VirtualCurrencyFields>({
    method: ApiMethod.get,
    url: ApiUrlGet.virtualCurrency.replace(':id', projectId).replace(':sku', sku),
    options: { params, validation },
  });

  return { data, sendRequest, error, isLoading };
}
