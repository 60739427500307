import { IUser } from '../types';
import { ApiMethod, ApiUrlGet } from '../types/Api';

import useApi from './useApi';

export default function useUser(userId: string) {
  const { data, error, isLoading, sendRequest } = useApi<IUser>({
    method: ApiMethod.get,
    url: ApiUrlGet.user.replace(':id', userId),
  });

  return { data, error, isLoading, sendRequest };
}
