import { yupResolver } from '@hookform/resolvers/yup';
import { TrashIcon } from '@radix-ui/react-icons';
import {
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import Button from 'components/common/Button';
import ConfirmDialog from 'components/common/ConfirmDialog';
import DropdownButton from 'components/common/DropdownButton';
import PageTitle from 'components/common/PageTitle';
import Table from 'components/common/Table';
import { useAppDispatch } from 'hooks/storeHooks';
import { IProject } from 'hooks/useProjects';
import useUser from 'hooks/useUser';
import useUserProjects from 'hooks/useUserProjects';
import PageLayout from 'layout/PageLayout';
import { getUserRoleName } from 'pages/users/Users';
import {
  deleteUserFromAllProjects,
  deleteUserFromProject,
  updateUserRoleOnProject,
} from 'store/project';
import { inviteUser } from 'store/user';
import { UserRole } from 'types';
import { Fields } from 'types/Forms';

import AddProjectForm from './AddProjectForm';
import styles from './ManageUser.module.scss';
import schema from './validation';

const ManageUser = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id = '' } = useParams();
  const { data: user, isLoading: isUserLoading } = useUser(id);
  const { data, isLoading, reload, handleScrollLoad } = useUserProjects({
    userId: id,
    validation: true,
  });
  const [sorting, setSorting] = useState<SortingState>([]);
  const [isDeleteUserPopupOpen, setDeleteUserPopupOpen] = useState(false);
  const [isDeleteProjectsPopupOpen, setDeleteProjectsPopupOpen] = useState(false);
  const [isAddProjectPopupOpen, setAddProjectPopupOpen] = useState(false);
  const [dataForDeletion, setDataForDeletion] = useState<
    { projectId: string; userId: string } | undefined
  >();

  const {
    control,
    reset,
    handleSubmit,
    formState: { isValid },
  } = useForm<Fields>({
    resolver: yupResolver(schema),
    defaultValues: {
      addProjectToUser: {},
    },
  });

  const columnHelper = createColumnHelper<IProject>();

  const columns = [
    columnHelper.accessor('title', {
      cell: (info) => <b>{info.getValue()}</b>,
      header: () => <span>Project</span>,
      enableColumnFilter: false,
    }),
    columnHelper.accessor('role', {
      header: () => <span></span>,
      cell: (info) => (
        <DropdownButton
          items={[
            {
              title: `Set as ${
                info.renderValue() !== UserRole.MODERATOR ? 'Moderator' : 'Administrator'
              }`,
              action: () => {
                dispatch(
                  updateUserRoleOnProject({
                    projectId: info.row.original.id,
                    userId: user?.id || '',
                    role:
                      info.renderValue() === UserRole.MODERATOR
                        ? UserRole.ADMIN
                        : UserRole.MODERATOR,
                  }),
                ).then(() => {
                  reload();
                });
              },
            },
            undefined,
            {
              title: 'Remove from project',
              right: <TrashIcon />,
              styles: { color: '#E03131' },
              action: () => {
                setDeleteUserPopupOpen(true);
                setDataForDeletion({ projectId: info.row.original.id, userId: user?.id || '' });
              },
            },
          ]}
          title={getUserRoleName(info.renderValue())}
          editable={info.getValue() !== UserRole.OWNER}
        />
      ),
      enableColumnFilter: false,
      enableSorting: false,
    }),
  ];

  const table = useReactTable({
    data: data?.data || [],
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const handleDeleteUserFromProject = () => {
    if (!dataForDeletion) return;

    return dispatch(deleteUserFromProject(dataForDeletion)).then(() => {
      setDeleteUserPopupOpen(false);
      setDataForDeletion(undefined);
      reload();
    });
  };

  const handleDeleteFromAllProjects = () => {
    return dispatch(deleteUserFromAllProjects({ userId: id })).then(() => {
      setDeleteProjectsPopupOpen(false);
      reload();
    });
  };

  const handleAddProjectSubmit = handleSubmit((formData) => {
    const { projectId, role } = formData.addProjectToUser;
    return dispatch(
      inviteUser({
        projectId,
        users: [{ email: user?.email || '', role }],
      }),
    )
      .then((res: any) => {
        if (!res.error) {
          reload();

          reset({});

          setAddProjectPopupOpen(false);
        }
      })
      .catch(() => {});
  });

  return (
    <PageLayout withSidebar={false} loading={isUserLoading || isLoading}>
      <PageTitle
        onBreadcrumbClick={() => navigate(-1)}
        breadcrumbs='Manage company'
        title={user?.email || ''}
        count={data?.data?.length}
        action={
          <Button onClick={() => setAddProjectPopupOpen(true)} variant='primary'>
            Add project
          </Button>
        }
      />
      <ConfirmDialog
        withoutIcon
        onClose={() => {
          setAddProjectPopupOpen(false);
        }}
        open={isAddProjectPopupOpen}
        title='Add Project'
        description='Select project and user’s role'
        loading={false}
        primaryButton='Add project'
        secondaryButton='Cancel'
        onSubmit={handleAddProjectSubmit}
        isValid={isValid}
      >
        <AddProjectForm userProjects={data?.data || []} control={control} />
      </ConfirmDialog>
      <ConfirmDialog
        onClose={() => {
          setDeleteUserPopupOpen(false);
          setDataForDeletion(undefined);
        }}
        open={isDeleteUserPopupOpen}
        title='Remove user'
        primaryButton='Remove'
        onPrimaryButtonClick={handleDeleteUserFromProject}
        secondaryButton='Cancel'
        description='By removing this user from the project they will loose all access to this project'
      />
      <ConfirmDialog
        onClose={() => {
          setDeleteProjectsPopupOpen(false);
        }}
        open={isDeleteProjectsPopupOpen}
        title='Remove user from all projects'
        primaryButton='Remove'
        onPrimaryButtonClick={handleDeleteFromAllProjects}
        secondaryButton='Cancel'
        description='By removing this user from all projects they will loose all access to them'
      />
      <div
        className={styles.content}
        onScroll={(e) => handleScrollLoad(e.target as HTMLDivElement)}
      >
        <Table
          emptyText='There are no projects to display'
          isLoading={isLoading}
          table={table}
          footer={
            user?.role !== UserRole.OWNER ? (
              <Button
                onClick={() => setDeleteProjectsPopupOpen(true)}
                leftIcon={<TrashIcon />}
                variant='transparent_delete'
              >
                Remove from all projects
              </Button>
            ) : null
          }
        />
      </div>
    </PageLayout>
  );
};

export default ManageUser;
