import { TrashIcon, PlusIcon } from '@radix-ui/react-icons';
import classnames from 'classnames';
import React, { useEffect, useMemo } from 'react';
import { Control, UseFormWatch, useFieldArray } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import Label from 'components/common/Label';
import Input from 'components/common/NewInput';
import SingleSelect from 'components/common/SingleSelect';
import useVirtualCurrencies from 'hooks/useVirtualCurrencies';
import { rewardsConverter } from 'pages/createQuest/utils';
import { getMockVirtualCurrency } from 'pages/createVirtualItem/mock';
import { Fields } from 'types/Forms';
import { VCPriceType } from 'types/Reward';

import styles from './Currencies.module.scss';

type CurrenciesProps = {
  control: Control<any>;
  watch: UseFormWatch<Fields>;
  prices: VCPriceType[];
  formKey: 'virtualItem' | 'virtualCurrency';
  label: string;
  isEdit?: boolean;
};

export const VirtualCurrencyItem: React.FC<CurrenciesProps> = ({
  watch,
  control,
  prices,
  formKey = 'virtualItem',
  label,
}) => {
  const form = watch(formKey);
  const { id: projectId = '' } = useParams();

  const { data: virtualCurrenciesData } = useVirtualCurrencies({
    projectId,
    params: { page: 1, perPage: 999 },
    validation: true,
  });

  const options = useMemo(
    () => rewardsConverter(virtualCurrenciesData?.items || []),
    [virtualCurrenciesData],
  );
  const [selectedCurrencies, setSelectedCurrencies] = React.useState<Set<string>>(new Set());

  const { update, fields, insert, remove } = useFieldArray({
    control,
    name: 'virtualItem.vc_prices',
  });
  const pricesData = fields as VCPriceType[];

  const onAddTrigger = (currency?: string) => {
    const price = getMockVirtualCurrency(currency);
    insert(pricesData.length, { ...price, id: uuidv4() });
  };

  const onUpdateVirtualCurrency = (id: string, i: VCPriceType, key: string, value: string) => {
    const index = prices.findIndex((item) => item.id === id);

    update(index, {
      ...i,
      [key]: value,
      amount: prices[index]?.amount,
    });
  };

  const onRemove = (id: string) => {
    const index = prices.findIndex((item) => item.id === id);
    const removedCurrency = prices[index];

    setSelectedCurrencies((prevSelectedCurrencies) => {
      const updatedSelectedCurrencies = new Set(prevSelectedCurrencies);

      if (removedCurrency) updatedSelectedCurrencies.delete(removedCurrency?.sku);
      return updatedSelectedCurrencies;
    });
    remove(index);
  };

  useEffect(() => {
    if (form.vc_prices?.length) {
      setSelectedCurrencies(new Set(form.vc_prices.map((price) => price.sku)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.vc_prices?.length, options.length, selectedCurrencies.size]);

  const currencyFirstOption = options.filter((option) => {
    return !selectedCurrencies.has(option.value);
  })[0]?.value;

  const renderCurrencies = () => {
    return prices.map((item, i: number) => {
      const index = prices.findIndex((price) => price.id === item.id);
      const currencyOptions = options.filter((currency) => {
        return !selectedCurrencies.has(currency.value) || item.sku === currency.value;
      });

      return (
        <div className={styles.currency} key={`${item.sku}_${index}`}>
          <div className={styles.currency__header}>
            <p>UNIT {i + 1}</p>
            {prices?.length > 1 && (
              <TrashIcon
                className={styles.currency__trash_icon}
                width={18}
                height={18}
                onClick={() => onRemove(item.id)}
              />
            )}
          </div>
          <div className={classnames(styles.currency__content, styles['currency__content--col-2'])}>
            <SingleSelect
              update={(value) => {
                onUpdateVirtualCurrency(item.id, item, 'sku', value);
                setSelectedCurrencies(new Set(form.vc_prices?.map((price) => price.sku)));
              }}
              options={currencyOptions}
              defaultValue={item.sku}
            />
            <Input
              placeholder='Price'
              control={control}
              type='number'
              name={`${formKey}.vc_prices.${index}.amount`}
            />
          </div>
        </div>
      );
    });
  };

  return (
    <div>
      <Label label={label} />
      {renderCurrencies()}

      <div className={styles.currency__add_more} onClick={() => onAddTrigger(currencyFirstOption)}>
        <PlusIcon className={styles.currency__add_icon} />
        <p>Add pricing</p>
      </div>
    </div>
  );
};
