import classnames from 'classnames';
import React, { ChangeEvent, FC } from 'react';
import { useController, Control } from 'react-hook-form';

import Label from 'components/common/Label';

import styles from './TextArea.module.scss';

type ITextAreaProps = {
  onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  rows?: number;
  cols?: number;
  disabled?: boolean;
  readOnly?: boolean;
  className?: string;
  titleClassName?: string;
  control?: Control<any>;
  name: string;
  required?: boolean;
  label?: string;
  optional?: string;
};

const TextArea: FC<ITextAreaProps> = ({
  onChange,
  placeholder,
  rows = 4,
  cols = 30,
  disabled = false,
  readOnly = false,
  className,
  control,
  name,
  label,
  optional,
}) => {
  const {
    field,
    fieldState: { error },
  } = useController<Record<string, string>>({
    control,
    name,
  });

  return (
    <div className={styles.wrapper}>
      {!!label && <Label label={label} optional={optional} />}
      <textarea
        className={classnames(styles.wrapper__textarea, className)}
        placeholder={placeholder}
        rows={rows}
        cols={cols}
        disabled={disabled}
        readOnly={readOnly}
        {...(!!error && { 'data-invalid': true })}
        {...field}
        onChange={(e) => {
          field.onChange(e);

          if (onChange) {
            onChange(e);
          }
        }}
      />
      {error && <span className={styles.wrapper__error_label}>{error?.message}</span>}
    </div>
  );
};

export default TextArea;
