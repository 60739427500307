export type Modify<T, R> = Omit<T, keyof R> & R;

export interface IErrorResponse {
  [data: string]: any;
  code: number;
  message: string;
}

export type IOptions = {
  params: Record<string, any>;
  validation?: boolean;
  dynamicP?: boolean;
};

export type IOptionsMutate = {
  params?:
    | {
        [key in string]: boolean;
      }
    | object;
  headers?: {
    [key in string]: string | boolean;
  };
};

export enum UserRole {
  OWNER = 'OWNER',
  MODERATOR = 'MODERATOR',
  ADMIN = 'ADMIN',
}

export interface IUser {
  id: string;
  email: string;
  companyId: string;
  password: string;
  role: UserRole;
  verified: boolean;
  projectsCount: number;
  inviteToken: string | null;
  resetToken: string | null;
  createdAt: string | null;
  updatedAt: string | null;
  deletedAt: string | null;
}

export enum ClaimsEnum {
  'auto' = 'AUTO',
  'manual' = 'MANUAL',
}

export enum Difficulty {
  easy = 'easy',
  medium = 'medium',
  hard = 'hard',
}

export enum PeriodsEnum {
  days = 'days',
  weeks = 'weeks',
  months = 'months',
}

export enum Visibility {
  public = 'public',
  hidden = 'hidden',
}

export enum TriggersOperation {
  or = 'OR',
  and = 'AND',
}

export enum RewardType {
  VIRTUAL_CURRENCY = 'VIRTUAL_CURRENCY',
  VIRTUAL_ITEM = 'VIRTUAL_ITEM',
}

export const RewardTypeText = {
  [RewardType.VIRTUAL_CURRENCY]: 'Virtual currency',
  [RewardType.VIRTUAL_ITEM]: 'Virtual item',
};

export interface IQuestReward {
  rewardId: string;
  amount: number;
  claimType: string;
  rewardType: RewardType;
}

export type SelectOption = {
  value: string;
  label: string;
};
