import './styles.css';
import { ChevronDownIcon, ChevronUpIcon } from '@radix-ui/react-icons';
import React, { useState } from 'react';

import Button from '../Button';
import DropdownMenu from '../DropdownMenu';

interface DropdownButtonProps {
  title: string;
  editable?: boolean;
  items: (
    | {
        title: string;
        link?: string;
        action?: () => void;
        styles?: object;
        label?: string;
        right?: React.ReactNode | string;
      }
    | undefined
  )[];
}

const DropdownButton: React.FC<DropdownButtonProps> = ({
  items = [],
  title = '',
  editable = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const button = (
    <Button
      rightIcon={editable ? isOpen ? <ChevronUpIcon /> : <ChevronDownIcon /> : null}
      disabled={!editable}
      variant={isOpen ? 'dropdown-active' : 'dropdown'}
      fullWidth
    >
      {title}
    </Button>
  );

  if (editable) {
    return (
      <DropdownMenu onOpenChange={setIsOpen} items={items}>
        <div className='dropdown-button'>{button}</div>
      </DropdownMenu>
    );
  }

  return button;
};
export default DropdownButton;
