import { PlusIcon, TrashIcon } from '@radix-ui/react-icons';
import React from 'react';
import { Control, useFieldArray, UseFormReset, UseFormWatch } from 'react-hook-form';

import Label from 'components/common/Label';
import Input from 'components/common/NewInput';
import { QuestMetadata, QuestWebhook, Fields, QuestFieldsName } from 'types/Forms';

import { WebhookMock, MetadataMock } from '../../mock';

import styles from './Webhooks.module.scss';

type WebhooksProps = {
  control: Control<any>;
  watch: UseFormWatch<Fields>;
  reset: UseFormReset<any>;
  name?: any;
};

export const Webhooks: React.FC<WebhooksProps> = ({ control }) => {
  const webhooksForm = useFieldArray({
    control,
    name: QuestFieldsName.webHookList,
  });

  const metadataForm = useFieldArray({
    control,
    name: QuestFieldsName.metadataList,
  });

  const webhooks = webhooksForm.fields as QuestWebhook[];
  const metadata = metadataForm.fields as QuestMetadata[];

  const onAdd = (isWebhook?: boolean) => {
    if (isWebhook) {
      webhooksForm.insert(webhooksForm.fields.length, WebhookMock[0]);
    } else {
      metadataForm.insert(metadataForm.fields.length, MetadataMock[0]);
    }
  };

  const onRemove = (i: number, isWebhook?: boolean) => {
    if (isWebhook) {
      webhooksForm.remove(i);
    } else {
      metadataForm.remove(i);
    }
  };

  return (
    <div className={styles.webhooks}>
      <p className={styles.webhooks__title}>Integration</p>
      <div>
        <div className={styles.webhooks__wrapper}>
          <Label label='Metadata (optional)' />
          {metadata?.map((item, index) => (
            <div className={styles.webhooks_content} key={`${item.id}_${item.key}_${index}`}>
              <div className={styles.webhooks__content__header}>
                <p>ITEM {index + 1}</p>
                <TrashIcon color='#0073F7' width={18} height={18} onClick={() => onRemove(index)} />
              </div>
              <div className={styles['webhooks__content--col-2']}>
                <Input
                  placeholder='Metadata key'
                  control={control}
                  name={`${QuestFieldsName.metadataList}.${index}.key`}
                />
                <Input
                  placeholder='Metadata value'
                  control={control}
                  name={`${QuestFieldsName.metadataList}.${index}.value`}
                />
              </div>
            </div>
          ))}
          <div className={styles.webhooks__add_more} onClick={() => onAdd()}>
            <PlusIcon color='#0073F7' />
            <p>Add item</p>
          </div>
        </div>
        <div className={styles.webhooks__wrapper}>
          <Label label='Webhooks (optional)' />
          {webhooks?.map((item, index) => (
            <div className={styles.webhooks_content} key={`${item.id}_${item.value}_${index}`}>
              <div className={styles.webhooks__content__header}>
                <p>WEBHOOK {index + 1}</p>
                <TrashIcon
                  color='#0073F7'
                  width={18}
                  height={18}
                  onClick={() => onRemove(index, true)}
                />
              </div>
              <Input
                placeholder='Webhook URL'
                control={control}
                name={`${QuestFieldsName.webHookList}.${index}.value`}
              />
            </div>
          ))}
          <div className={styles.webhooks__add_more} onClick={() => onAdd(true)}>
            <PlusIcon color='#0073F7' />
            <p>Add Webhook</p>
          </div>
        </div>
      </div>
    </div>
  );
};
