import * as yup from 'yup';

import { emailValidation } from 'utils/validations';

export const createSchema = yup.object().shape({
  project: yup.object().shape({
    cover: yup.string(),
    title: yup.string().required('Project name is required'),
    content: yup.string().required('Description is required'),
    xsollaProjectId: yup.string().required('Xsolla project ID is required'),
    users: yup.array().of(
      yup.object().shape({
        email: emailValidation,
        role: yup.string().required('Role is required'),
      }),
    ),
  }),
});

export const updateSchema = yup.object().shape({
  projectUpdate: yup.object().shape({
    cover: yup.string().nullable(),
    title: yup.string().required('Project name is required'),
    content: yup.string().required('Description is required'),
  }),
});
