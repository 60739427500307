import * as yup from 'yup';

const schema = yup.object().shape({
  addProjectToUser: yup.object().shape({
    projectId: yup.string().required('Project is required'),
    role: yup.string().required('Role is required'),
  }),
});

export default schema;
