import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import Button from 'components/common/Button';
import Checkbox from 'components/common/NewCheckbox';
import PageTitle from 'components/common/PageTitle';
import LoadingBox from 'components/LoadingBox';
import { useAppDispatch } from 'hooks/storeHooks';
import useXsollaProjects from 'hooks/useXsollaProjects';
import PageLayout from 'layout/PageLayout';
import { importXsollaProjects } from 'store/user';

import styles from './ProjectsImport.module.scss';

const selectedProjectIds = (form: object) =>
  Object.entries(form).reduce((acc: number[], [id, val]) => {
    if (val) {
      acc.push(Number(id));
    }

    return acc;
  }, []);

const ProjectsImport = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [select, setSelect] = useState(false);
  const { data, isLoading } = useXsollaProjects();
  const { control, watch, setValue, handleSubmit } = useForm<any>();
  const selectedXsollaProjectIds = selectedProjectIds(watch());

  const updateAll = (val: boolean) => {
    if (data?.length) {
      data.forEach((project) => {
        setValue(project.id.toString(), val);
        setSelect(val);
      });
    }
  };

  const onSubmit = handleSubmit((body) => {
    if (data?.length) {
      const xsollaProjectIds = selectedProjectIds(body);

      return dispatch(importXsollaProjects({ xsollaProjectIds })).then((res) => {
        if (res.payload) {
          navigate('/projects');
        }
      });
    }
  });

  return (
    <PageLayout withSidebar={false}>
      <PageTitle title='Import projects' count={data?.length} />
      <div className={styles.inner}>
        <div className={styles.card}>
          <div className={styles.card__header}>
            <p>Project name</p>
          </div>
          {isLoading ? (
            <div className={styles.projects}>
              <LoadingBox />
            </div>
          ) : (
            <div className={styles.projects}>
              {data?.map((project) => (
                <div
                  onClick={(event) => {
                    event.preventDefault();

                    setValue(project.id.toString(), !watch(project.id.toString()));
                  }}
                  key={project.id}
                  className={styles.project}
                >
                  <p>{project.name}</p>
                  <div className={styles.project__checkbox}>
                    <Checkbox name={project.id.toString()} control={control} />
                  </div>
                </div>
              ))}
              {!isLoading && data && data?.length === 0 ? (
                <div className={styles.projects__empty}>There are no projects to import</div>
              ) : null}
            </div>
          )}
          <div className={styles.card__footer}>
            <Button onClick={() => updateAll(!select)} variant='transparent'>
              {!select ? 'Select all' : 'Unselect all'}
            </Button>
            <div className={styles.card__footer__right}>
              <Button onClick={() => navigate('/projects')} variant='secondary'>
                Cancel
              </Button>
              <Button
                disabled={selectedXsollaProjectIds.length === 0}
                onClick={onSubmit}
                variant='primary'
              >
                Import selection
              </Button>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default ProjectsImport;
