import { VirtualItemFields, CheckboxGroupItem } from 'types/Forms';
import { PurchaseFrequencyType, StorePeriodType } from 'types/Reward';
import {
  updateUTCOffset,
  getUTCOffset,
  updateUTCOffsetDateFormat,
  getUTCOffsetForDate,
} from 'utils/utcOffset';

import { DefaultTime, DefaultTimezone } from '../mock';

export const convertFormForReq = (reward: VirtualItemFields) => {
  const timeValue = reward.limits.recurrent_schedule?.per_user?.time || DefaultTime;
  const timezone = reward.timezone || DefaultTimezone;

  const intervalType = reward.limits.recurrent_schedule?.per_user?.interval_type;
  const noneIntervalType = intervalType === PurchaseFrequencyType.none;
  const time = intervalType ? updateUTCOffset(timeValue, timezone) : null;

  const limits = {
    per_user: reward.limits.per_user,
    recurrent_schedule:
      noneIntervalType || !reward.limits.recurrent_schedule
        ? null
        : {
            per_user: {
              ...reward.limits.recurrent_schedule?.per_user,
              time: time,
            },
          },
  };

  const dateFromValue = reward.periods.length && reward.periods[0].date_from;
  const dateUntilValue = reward.periods.length && reward.periods[0].date_until;

  const storeTimezone = reward.storeTimezone || DefaultTimezone;
  const dateFrom = dateFromValue && updateUTCOffsetDateFormat(dateFromValue, storeTimezone);
  const dateUntil = dateUntilValue
    ? updateUTCOffsetDateFormat(dateUntilValue, storeTimezone)
    : null;

  let periods: StorePeriodType[];
  if (!dateFrom) {
    periods = [];
  } else {
    periods = [
      {
        date_from: dateFrom,
        date_until: dateUntil,
      },
    ];
  }

  return {
    image_url: reward.image_url,
    sku: reward.sku,
    name: reward.name,
    groups: reward.groups,
    description: reward.description,
    inventory_options: reward.inventory_options,
    is_free: reward.is_free,
    prices: reward.prices,
    vc_prices: reward.vc_prices || [],
    vc_pricing: reward.vc_pricing,
    limits_enable: reward.limits_enable,
    limits: limits,
    default_currency: reward.default_currency,
    is_show_in_store: reward.is_show_in_store,
    periods: periods,
  };
};

export const convertFormFromRes = (reward: VirtualItemFields) => {
  const prices =
    reward.prices?.map((price) => ({
      ...price,
      amount: price.amount,
    })) || [];
  const periods =
    reward.periods?.map((i) => ({ date_from: i.date_from, date_until: i.date_until })) || [];

  const timeValue = reward.limits.recurrent_schedule?.per_user?.time;
  const timezone = timeValue && getUTCOffset(timeValue);

  const storeDate = reward.periods[0].date_from;
  const storeTimezone = storeDate && getUTCOffsetForDate(storeDate);

  return {
    image_url: reward.image_url,
    sku: reward.sku,
    name: reward.name,
    groups: reward.groups,
    description: reward.description,
    inventory_options: reward.inventory_options,
    is_free: reward.is_free,
    prices,
    vc_prices: reward.vc_prices || [],
    vc_pricing: reward.vc_pricing,
    limits_enable: reward.limits_enable,
    limits: reward.limits,
    default_currency: reward.default_currency,
    is_show_in_store: reward.is_show_in_store,
    periods,
    timezone,
    storeTimezone,
  };
};

export const convertRegions = (regions: CheckboxGroupItem[]) => {
  return regions.map((region: CheckboxGroupItem) => {
    return { value: region.code, label: region.label, id: region.id };
  });
};
