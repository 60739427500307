import { CheckIcon } from '@radix-ui/react-icons';
import React from 'react';
import { Link } from 'react-router-dom';

import Button from 'components/common/Button';
import AuthLayout from 'layout/authLayout/index';

import styles from './SignUp.module.scss';

const SignUpConfirmationSuccess: React.FC = () => {
  return (
    <AuthLayout>
      <div className={styles.success__icon}>
        <CheckIcon className={styles.success__check_icon} />
      </div>
      <h1 className={styles.success__title}>Verify your email</h1>
      <div className={styles.success__description}>
        Well done! Before we get started, please, confirm your email by clicking on the link we sent
        you. You will be automatically logged in to the system
      </div>

      <Link className={styles.success__link} to='/login'>
        <Button variant='primary' className={styles.success__submit}>
          Back to Sign in
        </Button>
      </Link>
    </AuthLayout>
  );
};

export default SignUpConfirmationSuccess;
