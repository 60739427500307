import classNames from 'classnames';
import React from 'react';

import SpinnerIcon from 'assets/svg/spinner.svg';

import { LoadingBoxInt } from './interface';
import styles from './LoadingBox.module.scss';

const LoadingBox = ({ className, variant }: LoadingBoxInt) => {
  return (
    <img
      src={SpinnerIcon}
      className={classNames(styles.spinner, styles[`spinner--${variant}`], className)}
    />
  );
};

export default LoadingBox;
