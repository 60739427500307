import { yupResolver } from '@hookform/resolvers/yup';
import { TrashIcon } from '@radix-ui/react-icons';
import {
  SortingState,
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import dayjs from 'dayjs';
import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import Button from 'components/common/Button';
import ConfirmDialog from 'components/common/ConfirmDialog';
import { IIconColorTypes, IIconTypes } from 'components/common/ConfirmDialog/interface';
import DropdownButton from 'components/common/DropdownButton';
import PageTitle from 'components/common/PageTitle';
import Table from 'components/common/Table';
import Tabs from 'components/common/Tabs';
import { useAppDispatch, useAppSelector } from 'hooks/storeHooks';
import useProjectUsers from 'hooks/useProjectUsers';
import PageLayout from 'layout/PageLayout';
import { deleteUserFromProject, fetchProjects, updateUserRoleOnProject } from 'store/project';
import { inviteUser, selectProfile } from 'store/user';
import { UserRole, IUser } from 'types';
import { Fields } from 'types/Forms';

import styles from './User.module.scss';
import UsersInviteForm from './UsersInviteForm';
import Schema from './validation';

export const getUserRoleName = (role?: UserRole | null) => {
  switch (role) {
    case UserRole.OWNER:
      return 'Owner';
    case UserRole.MODERATOR:
      return 'Moderator';
    case UserRole.ADMIN:
      return 'Administrator';
    default:
      return '';
  }
};

const Users = () => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const projectId = params.id || '';
  const profile = useAppSelector(selectProfile);

  const {
    control,
    handleSubmit,
    formState: { isSubmitting, isValid },
    reset,
  } = useForm<Fields>({
    resolver: yupResolver(Schema),
    defaultValues: {
      userInvite: {
        projectId,
        users: [{ email: '', role: UserRole.MODERATOR }],
      },
    },
  });

  const { data, items, isLoading, reload, handleScrollLoad } = useProjectUsers({
    projectId,
    validation: true,
  });
  const [removePopupOpen, setRemovePopupOpen] = useState(false);
  const [invitePopupOpen, setInvitePopupOpen] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);

  const [userId, setUserId] = useState<string | undefined>('');

  const [sorting, setSorting] = useState<SortingState>([]);

  const columnHelper = createColumnHelper<IUser>();

  const handleDelete = () => {
    if (!userId) return;

    return dispatch(deleteUserFromProject({ projectId, userId })).then(() => {
      setRemovePopupOpen(false);
      reload();
    });
  };

  const closeInviteDialog = () => {
    setInvitePopupOpen(false);
    setSubmitted(false);
    reset();
  };

  const onInviteUserSubmit = handleSubmit((inviteData) => {
    return dispatch(inviteUser(inviteData.userInvite))
      .then((res: any) => {
        if (res?.payload) {
          reload();
          setSubmitted(true);
          closeInviteDialog();
        }
      })
      .catch(() => {});
  });

  const columns = [
    columnHelper.accessor('email', {
      cell: (info) => <b>{info.getValue()}</b>,
      header: () => <span>User email</span>,
      enableColumnFilter: false,
    }),
    columnHelper.accessor((row) => row.createdAt, {
      id: 'jointOn',
      cell: (info) => <span>{dayjs(info.getValue()).format('MM / DD / YYYY')}</span>,
      header: () => <span>Joint on</span>,
      enableColumnFilter: false,
    }),
    columnHelper.accessor('role', {
      header: () => <span>Access level</span>,
      cell: (info) => (
        <DropdownButton
          items={[
            {
              title: `Set as ${
                info.renderValue() !== UserRole.MODERATOR ? 'Moderator' : 'Administrator'
              }`,
              action: () => {
                dispatch(
                  updateUserRoleOnProject({
                    projectId: projectId,
                    userId: info.row.original.id || '',
                    role:
                      info.renderValue() === UserRole.MODERATOR
                        ? UserRole.ADMIN
                        : UserRole.MODERATOR,
                  }),
                ).then(() => {
                  reload();
                  dispatch(fetchProjects());
                });
              },
            },
            undefined,
            {
              title: 'Remove from project',
              right: <TrashIcon />,
              styles: { color: '#E03131' },
              action: () => {
                setRemovePopupOpen(true);
                setUserId(info.row.original.id);
              },
            },
          ]}
          title={getUserRoleName(info.renderValue() || UserRole.MODERATOR)}
          editable={
            info.getValue() !== UserRole.OWNER && profile?.email !== info.row.original.email
          }
        />
      ),
      enableColumnFilter: false,
    }),
  ];

  const table = useReactTable({
    data: items || [],
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  const inviteProps = {
    title: isSubmitted ? 'Invitations have been sent!' : 'Invite users',
    description: isSubmitted
      ? 'Projects will be available to users when they create their account'
      : 'Insert below new users’ email addresses and access levels',
    primaryButton: isSubmitted ? 'Thanks' : 'Invite',
    secondaryButton: isSubmitted ? undefined : 'Cancel',
    icon: isSubmitted ? IIconTypes.success : undefined,
    iconColor: isSubmitted ? IIconColorTypes.green : undefined,
    onSubmit: isSubmitted ? undefined : onInviteUserSubmit,
    onPrimaryButtonClick: undefined,
    removeSecondaryButton: isSubmitted ? true : false,
  };

  const list = useMemo(
    () => [
      {
        title: 'All users',
        content: (
          <div
            className={styles.content}
            onScroll={(e) => handleScrollLoad(e.target as HTMLDivElement)}
          >
            <Table emptyText='There are no users to display' isLoading={isLoading} table={table} />
          </div>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [table, items, handleScrollLoad, isLoading],
  );

  return (
    <PageLayout>
      <PageTitle
        title='Users'
        count={data?.total}
        action={
          <section>
            <Button variant='primary' onClick={() => setInvitePopupOpen(true)}>
              Invite user
            </Button>

            <ConfirmDialog
              withoutIcon={!isSubmitted}
              onClose={() => {
                setInvitePopupOpen(false);
                setUserId(undefined);
              }}
              open={invitePopupOpen}
              title={inviteProps.title}
              description={inviteProps.description}
              loading={isSubmitting}
              primaryButton={inviteProps.primaryButton}
              secondaryButton={inviteProps.secondaryButton}
              onSubmit={inviteProps.onSubmit}
              icon={inviteProps.icon}
              iconColor={inviteProps.iconColor}
              isValid={isValid}
              onPrimaryButtonClick={inviteProps.onPrimaryButtonClick}
            >
              <UsersInviteForm control={control} isSubmitted={isSubmitted} />
            </ConfirmDialog>
          </section>
        }
      />
      <ConfirmDialog
        onClose={() => setRemovePopupOpen(false)}
        open={removePopupOpen}
        title='Remove user'
        primaryButton='Remove'
        onPrimaryButtonClick={handleDelete}
        secondaryButton='Cancel'
        description='By removing this user from the project they will loose all access to this project'
      ></ConfirmDialog>
      <Tabs list={list} />
    </PageLayout>
  );
};

export default Users;
