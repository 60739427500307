import classNames from 'classnames';
import React from 'react';

import Button from '../Button';

import styles from './FooterButtons.module.scss';

type FooterButtonsProps = {
  submitTitle: string;
  rejectTitle: string;
  disabled?: boolean;
  onSubmit: () => void;
  onReject: () => void;
};

const FooterButtons: React.FC<FooterButtonsProps> = ({
  submitTitle,
  rejectTitle,
  disabled,
  onSubmit,
  onReject,
}) => {
  return (
    <div className={classNames(styles.wrapper)}>
      <Button onClick={onReject} variant='secondary'>
        {rejectTitle}
      </Button>

      <Button
        disabled={disabled}
        variant='primary'
        type='submit'
        className={styles.info__submit_button}
        onClick={onSubmit}
      >
        {submitTitle}
      </Button>
    </div>
  );
};

export default FooterButtons;
