import classnames from 'classnames';
import React, { useState } from 'react';
import { Control, UseFormReset, UseFormSetValue, UseFormWatch } from 'react-hook-form';

import Label from 'components/common/Label';
import Input from 'components/common/NewInput';
import SingleSelect from 'components/common/SingleSelect';
import { Fields } from 'types/Forms';
import { ItemProperty } from 'types/Reward';

import { ItemPropertyTypeMock, IExpirationPeriodTypeMock } from '../../mock';

import styles from './ItemProperties.module.scss';

type ItemPropertiesProps = {
  control: Control<Fields>;
  watch: UseFormWatch<Fields>;
  setValue: UseFormSetValue<Fields>;
  reset: UseFormReset<any>;
};

export const ItemProperties: React.FC<ItemPropertiesProps> = ({ control, watch, setValue }) => {
  const virtualItem = watch('virtualItem');

  const consumableState: boolean = virtualItem.inventory_options.consumable;
  const nonRenewingSubscription = Boolean(virtualItem.inventory_options.expiration_period);

  let itemPropertyDefaultState: ItemProperty;

  if (consumableState) {
    itemPropertyDefaultState = ItemProperty.consumable;
  } else if (nonRenewingSubscription) {
    itemPropertyDefaultState = ItemProperty.non_renewing_subscription;
  } else {
    itemPropertyDefaultState = ItemProperty.nonconsumable;
  }

  const [itemProperty, setItemProperty] = useState<string>(itemPropertyDefaultState);

  return (
    <div className={styles.item_property}>
      <p className={styles.item_property__title}>Item properties</p>
      <div className={styles.item_property__content}>
        <div className={styles.wrapper}>
          <Label label='Item property' />
          <SingleSelect
            options={ItemPropertyTypeMock}
            defaultValue={itemProperty}
            update={(value) => {
              let consumable;
              if (value !== ItemProperty.consumable) {
                consumable = false;
              } else {
                consumable = true;
              }
              const inventoryOptions = {
                consumable,
              };
              setValue('virtualItem.inventory_options', inventoryOptions);
              setItemProperty(value);
            }}
          />
        </div>
        {/* {сonsumable === ItemProperty.consumable && (
          <div>
            <Input
              label='Usage'
              name='virtualItem.usage'
              control={control}
              placeholder='Enter usage amount'
            />
          </div>
        )} */}

        {itemProperty === ItemProperty.non_renewing_subscription && (
          <div>
            <Label label='Number of items can be bought' />
            <div
              className={classnames(
                styles.item_property__content__inputs,
                styles['item_property__content--col-2'],
              )}
            >
              <Input
                placeholder='Number'
                control={control}
                type='number'
                name={'virtualItem.inventory_options.expiration_period.value'}
              />

              <SingleSelect
                options={IExpirationPeriodTypeMock}
                placeholder='Select'
                defaultValue={virtualItem.inventory_options?.expiration_period?.type || ''}
                update={(value) => {
                  const expirationPeriod = {
                    type: value,
                    value: virtualItem.inventory_options.expiration_period?.value,
                  };

                  setValue('virtualItem.inventory_options.expiration_period', expirationPeriod);
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
