import { AxiosResponse } from 'axios';

import { AuthApiUrlDelete, AuthApiUrlGet, AuthApiUrlPost, AuthApiUrlPut } from './Auth';

import { IErrorResponse, IOptionsMutate, Modify } from './index';

export interface IAxiosMethodsParams {
  url: ApiUrl;
}

export type IAxiosMethodsParamsWithBody = Modify<
  IAxiosMethodsParams,
  { body: Record<string, any> }
>;

export type AxiosMethodsParams = IAxiosMethodsParams;

export interface IAxiosMethods {
  get: (url: ApiUrl, options?: IOptionsMutate) => Promise<AxiosResponse | IErrorResponse>;
  post: (
    url: ApiUrl,
    body: IAxiosMethodsParamsWithBody['body'],
    options?: IOptionsMutate,
  ) => Promise<AxiosResponse | IErrorResponse>;
  patch: (
    url: ApiUrl,
    body: IAxiosMethodsParamsWithBody['body'],
    options?: IOptionsMutate,
  ) => Promise<AxiosResponse | IErrorResponse>;
  put: (
    url: ApiUrl,
    body: IAxiosMethodsParamsWithBody['body'],
    options?: IOptionsMutate,
  ) => Promise<AxiosResponse | IErrorResponse>;
  delete: (url: ApiUrl, options?: IOptionsMutate) => Promise<AxiosResponse | IErrorResponse>;
}

export type IApiMethods = IAxiosMethods;

export enum ApiMethod {
  get = 'get',
  post = 'post',
  patch = 'patch',
  put = 'put',
  delete = 'delete',
}

export enum ApiUrlGet {
  users = '/users',
  user = '/users/:id',
  projects = '/projects',
  xsollaProjects = '/projects/xsolla/projects',
  project = '/projects/:id',
  quests = '/projects/:id/quests',
  quest = '/projects/:id/quests/:questId',
  virtualItems = '/projects/:id/virtual-items',
  virtualItem = '/projects/:id/virtual-items/:sku',
  virtualCurrencies = '/projects/:id/virtual-currencies',
  virtualCurrency = '/projects/:id/virtual-currencies/:sku',
  projectApiKey = '/projects/:id/api-keys',
  projectUsers = '/projects/:id/users',
  countries = '/countries',
  userProjects = '/users/:id/projects',
  topics = '/projects/:id/topics',
  timezones = '/proxy/xsolla/timezones',
  currencies = '/proxy/xsolla/currencies',
}

export enum ApiUrlPost {
  unknown = 'unknown',
  inviteUser = '/projects/:projectId/users',
  importXsollaProjects = '/projects/xsolla/import',
  createProject = '/projects',
  createVirtualItem = '/projects/:projectId/virtual-items',
  createVirtualCurrency = '/projects/:projectId/virtual-currencies',
  uploadImage = '/images',
  createQuest = '/projects/:id/quests',
}

export enum ApiUrlPatch {
  editQuest = '/projects/:projectId/quests/:questId',
  editVirtualItem = '/projects/:projectId/virtual-items/:sku',
  editVirtualCurrency = '/projects/:projectId/virtual-currencies/:sku',
}

export enum ApiUrlPut {
  unknown = 'unknown',
  updateProject = '/projects/:id',
  updateProjectApiKey = '/projects/:id/api-keys',
  updateUserRoleOnProject = '/projects/:projectId/user/:userId',
}

export enum ApiUrlDelete {
  deleteProject = '/projects/:id',
  deleteQuest = '/projects/:projectId/quests/:questId',
  deleteVirtualItem = '/projects/:projectId/virtual-items/:rewardId',
  deleteVirtualCurrency = '/projects/:projectId/virtual-currencies/:rewardId',
  deleteUserFromProject = '/projects/:projectId/users/:userId',
  deleteUserFromAllProjects = '/users/:userId/projects',
  unknown = 'unknown',
}

export type GetUrls = ApiUrlGet | AuthApiUrlGet | string;

export type PostUrls = ApiUrlPost | AuthApiUrlPost;

export type PutUrls = ApiUrlPut | AuthApiUrlPut;

export type DeleteUrls = ApiUrlDelete | AuthApiUrlDelete;

export type ApiUrl = GetUrls | PostUrls | PutUrls | DeleteUrls;

export enum RequestStatus {
  pending = 'Pending',
  success = 'Success',
  failure = 'Failure',
}
