export enum CostType {
  paid = 'paid',
  free = 'free',
}

export enum ItemProperty {
  consumable = 'consumable',
  nonconsumable = 'nonconsumable',
  non_renewing_subscription = 'non_renewing_subscription',
}

export const ItemPropertyText = {
  [ItemProperty.consumable]: 'Consumable',
  [ItemProperty.nonconsumable]: 'Non consumable',
  [ItemProperty.non_renewing_subscription]: 'Non renewing subscription',
};

export type RewardCurrency = {
  currency: string;
  price: string;
  id?: string;
};

export type RewardCountryCurrency = {
  country: string;
  currency: string;
  price: string;
  id?: string;
};

export enum IExpirationPeriodType {
  days = 'day',
  months = 'month',
  years = 'year',
}

export const IExpirationPeriodTypeText = {
  [IExpirationPeriodType.days]: 'Dyas',
  [IExpirationPeriodType.months]: 'Weekly',
  [IExpirationPeriodType.years]: 'Monthly',
};

export enum ItemAttributeType {
  external_id = 'External ID',
  attribute_name = 'Attribute name',
}

export enum PurchaseFrequencyType {
  none = 'none',
  daily = 'daily',
  weekly = 'weekly',
  monthly = 'monthly',
}

export const PurchaseFrequencyTypeText = {
  [PurchaseFrequencyType.none]: 'No regular refresh',
  [PurchaseFrequencyType.daily]: 'Daily',
  [PurchaseFrequencyType.weekly]: 'Weekly',
  [PurchaseFrequencyType.monthly]: 'Monthly',
};

export enum PeriodType {
  always = 'always',
  time_period = 'time_period',
}

export const PeriodTypeText = {
  [PeriodType.always]: 'Always',
  [PeriodType.time_period]: 'Time period',
};

export enum DurationType {
  perpetual = 'perpetual',
  closing_date = 'closing_date',
}

export const DurationTypeText = {
  [DurationType.perpetual]: 'Perpetual',
  [DurationType.closing_date]: 'Set closing date',
};

export type PriceType = {
  id: string;
  amount: number | undefined;
  currency: string;
  country_iso?: string;
  is_enabled?: boolean;
  is_default?: boolean;
};

export type LimitsType = {
  per_user: number | null;
  recurrent_schedule?: RecurrentScheduleType | null;
};

export type RecurrentScheduleType = {
  per_user: PerUserType | null;
};

export type PerUserType = {
  day_of_month?: string | null;
  day_of_week?: string | null;
  interval_type?: PurchaseFrequencyType | string;
  time?: string | null;
};
export type StorePeriodType = {
  date_from: string | null;
  date_until: string | null;
};

export type VCPriceType = {
  id: string;
  amount: number;
  is_default: boolean;
  is_enabled: boolean;
  sku: string;
};

export type ExpirationPeriodType = {
  type: string | undefined;
  value: number | undefined;
};
