import { yupResolver } from '@hookform/resolvers/yup';
import * as Form from '@radix-ui/react-form';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

import Button from 'components/common/Button';
import PasswordInput from 'components/common/PasswordInput';
import { useAppDispatch } from 'hooks/storeHooks';
import AuthLayout from 'layout/authLayout/index';
import { verify } from 'store/user';
import { IVerifyRequestBody } from 'types/Auth';
import { passwordValidation } from 'utils/validations';

import styles from './InvitationSignUp.module.scss';

const InvitationSignUp: React.FC = () => {
  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const { inviteToken } = useParams();
  const email = new URLSearchParams(search).get('email');

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<IVerifyRequestBody>({
    resolver: yupResolver(passwordValidation),
    defaultValues: {
      email: email || undefined,
      inviteToken: inviteToken || undefined,
    },
  });

  const navigate = useNavigate();

  const onSubmit = handleSubmit((data) => {
    return dispatch(verify(data))
      .then((res: any) => {
        if (res?.payload) {
          navigate('/login');
        }
      })
      .catch(() => {});
  });

  const liItems = [<li key='1'>Password must contain at least 6 characters</li>];

  return (
    <AuthLayout loading={isSubmitting} className={styles.sign_up__layout}>
      <h1 className={styles.sign_up__title}>Welcome!</h1>
      <div className={styles.sign_up__description}>To complete your account, create a password</div>
      <Form.Root onSubmit={onSubmit} className={styles.sign_up__form}>
        <PasswordInput
          labelName='Create password'
          name='password'
          control={control}
          placeholder='......'
        ></PasswordInput>

        <ul className={styles.sign_up__helper}>{liItems}</ul>

        <PasswordInput
          labelName='Confirm password'
          name='confirmedPassword'
          placeholder='......'
          control={control}
        ></PasswordInput>

        <Form.Submit asChild>
          <Button
            disabled={!!Object.keys(errors).length}
            variant='primary'
            className={styles.sign_up__submit}
          >
            Confirm
          </Button>
        </Form.Submit>
      </Form.Root>
    </AuthLayout>
  );
};

export default InvitationSignUp;
