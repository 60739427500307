import { TrashIcon, PlusIcon } from '@radix-ui/react-icons';
import classnames from 'classnames';
import React, { useEffect } from 'react';
import { Control, UseFormWatch } from 'react-hook-form';

import Label from 'components/common/Label';
import Input from 'components/common/NewInput';
import SingleSelect from 'components/common/SingleSelect';
import useCountries from 'hooks/useCountries';
import { Fields } from 'types/Forms';
import { SelectOption } from 'types/index';
import { PriceType } from 'types/Reward';

import { convertRegions } from '../../../utils';

import styles from './CountryCurrencies.module.scss';

type CurrenciesProps = {
  control: Control<any>;
  watch: UseFormWatch<Fields>;
  update: (id: string, i: PriceType, key: string, value: string) => void;
  remove: (index: string) => void;
  addPrice: (country: boolean) => void;
  prices: PriceType[];
  currencyList: PriceType[];
  currencies: SelectOption[];
  formKey: 'virtualItem' | 'virtualCurrency';
  errors?: any;
};

export const CountryCurrencyItem: React.FC<CurrenciesProps> = ({
  update,
  remove,
  prices,
  currencyList,
  addPrice,
  control,
  currencies,
  formKey,
  errors,
}) => {
  const [countries, setCountries] = React.useState<any[]>([]);
  const { regions } = useCountries();
  const [selectedCountries, setSelectedCountries] = React.useState<Set<string>>(new Set());

  const handleRemove = (id: string) => {
    const removedCurrency = currencyList.find((item) => item.id === id);

    setSelectedCountries((prevSelectedCurrencies) => {
      const updatedSelectedCurrencies = new Set(prevSelectedCurrencies);

      if (removedCurrency) updatedSelectedCurrencies.delete(removedCurrency?.country_iso ?? '');
      return updatedSelectedCurrencies;
    });

    remove(id);
  };

  useEffect(() => {
    if (regions?.length) {
      const countriesData = convertRegions(regions || []);
      setCountries([...countriesData]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regions?.length, selectedCountries.size]);

  useEffect(() => {
    setSelectedCountries(new Set(currencyList.map(({ country_iso }) => country_iso || '')));
  }, [currencyList]);

  const renderCurrencies = () => {
    return currencyList.map((item, i) => {
      const index = prices.findIndex((price) => price.id === item.id);
      const currencyOptions = countries.filter(({ value }) => {
        return !selectedCountries.has(value) || item.country_iso === value;
      });
      const countryIso = item.country_iso || '';

      return (
        <div className={styles.currency} key={`${item.currency}_${index}`}>
          <div className={styles.currency__header}>
            <p>UNIT {i + 1}</p>
            <TrashIcon
              className={styles.currency__trash_icon}
              width={18}
              height={18}
              onClick={() => handleRemove(item.id)}
            />
          </div>
          <div className={classnames(styles.currency__content, styles['currency__content--col-2'])}>
            <SingleSelect
              update={(value) => {
                update(item.id, item, 'country_iso', value);
              }}
              options={currencyOptions}
              placeholder='Select country'
              defaultValue={countryIso}
              error={errors && errors[index]?.country_iso?.message}
            />

            <SingleSelect
              update={(value) => update(item.id, item, 'currency', value)}
              options={currencies}
              placeholder='Select currency'
              defaultValue={item.currency}
              error={errors && errors[index]?.currency?.message}
            />
            <Input
              placeholder='Amount'
              control={control}
              type='number'
              name={`${formKey}.prices.${index}.amount`}
            />
          </div>
        </div>
      );
    });
  };

  return (
    <div>
      <Label label='Country-based pricing in real currency' />

      {renderCurrencies()}

      <div className={styles.currency__add_more} onClick={() => addPrice(true)}>
        <PlusIcon className={styles.currency__add_icon} />
        <p>Add pricing</p>
      </div>
    </div>
  );
};
