import { yupResolver } from '@hookform/resolvers/yup';
import * as Form from '@radix-ui/react-form';
import React from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';

import Button from 'components/common/Button';
import Input from 'components/common/NewInput';
import { useAppDispatch } from 'hooks/storeHooks';
import AuthLayout from 'layout/authLayout/index';
import { forgotPassword } from 'store/user';
import { IForgotPasswordRequestBody } from 'types/Auth';

import styles from './ForgotPassword.module.scss';
import Schema from './validation';

const ForgotPassword: React.FC = () => {
  const dispatch = useAppDispatch();
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<IForgotPasswordRequestBody>({
    resolver: yupResolver(Schema),
  });

  const navigate = useNavigate();

  const onSubmit = handleSubmit((data) => {
    return dispatch(forgotPassword({ email: data.email })).then((res: any) => {
      if (res?.payload) {
        navigate('/forgot-password/success');
      }
    });
  });

  return (
    <AuthLayout loading={isSubmitting} className={styles.forgot_password__layout}>
      <h1 className={styles.forgot_password__title}>Forgot password</h1>
      <div className={styles.forgot_password__description}>
        Please, insert the email address linked to your account, and we will send you a link to
        reset your password
      </div>
      <Form.Root onSubmit={onSubmit} className={styles.forgot_password__form}>
        <Input
          label='Email address'
          name='email'
          control={control}
          placeholder='Enter your email'
          error={errors.email}
        />
        <Form.Submit asChild>
          <Button
            disabled={!!Object.keys(errors).length}
            variant='primary'
            className={styles.forgot_password__submit}
          >
            Reset password
          </Button>
        </Form.Submit>

        <div className={styles.forgot_password__sign_in}>
          <Link to='/login'>Back to Sign in</Link>
        </div>
      </Form.Root>
    </AuthLayout>
  );
};

export default ForgotPassword;
