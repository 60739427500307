import { QuestionMarkCircledIcon } from '@radix-ui/react-icons';
import classnames from 'classnames';
import React, { useState } from 'react';

import styles from './OptionalTooltip.module.scss';

type OptionalTooltipProps = {
  className?: string;
  textClassName?: string;
  iconWidth?: number;
  iconHeight?: number;
  text: string;
};

const OptionalTooltip: React.FC<OptionalTooltipProps> = ({
  className,
  textClassName,
  iconWidth = 18,
  iconHeight = 18,
  text,
}) => {
  const [isHover, setIsHover] = useState<boolean>(false);
  return (
    <div
      className={classnames(styles.tooltip, className)}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <QuestionMarkCircledIcon
        width={iconWidth}
        height={iconHeight}
        className={styles.tooltip__icon}
      />
      {isHover && <div className={classnames(styles.tooltip__text, textClassName)}>{text}</div>}
    </div>
  );
};

export default OptionalTooltip;
