import { ApiMethod, ApiUrlGet } from '../types/Api';

import useApi from './useApi';
import { IProject } from './useProjects';

export default function useProject(projectId: any) {
  const { data, sendRequest, error, isLoading } = useApi<IProject>({
    method: ApiMethod.get,
    url: ApiUrlGet.project.replace(':id', projectId),
  });

  return { data, sendRequest, error, isLoading };
}
