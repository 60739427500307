import React, { useEffect } from 'react';
import { UseFormSetValue, UseFormWatch } from 'react-hook-form';
import ImageUploading, { ErrorsType, ImageListType } from 'react-images-uploading';

import ImageIcon from 'assets/svg/image-icon.svg';
import showToast from 'components/Toast';
import { useAppDispatch } from 'hooks/storeHooks';
import { uploadImage } from 'store/user';
import { Fields } from 'types/Forms';

import styles from './ImageUploader.module.scss';

type ImageUploaderProps = {
  title: string;
  name: any;
  className?: string;
  watch: UseFormWatch<Fields>;
  setValue: UseFormSetValue<Fields>;
  acceptType?: string[];
  maxFileSize?: number;
};

const ImageUploader: React.FC<ImageUploaderProps> = ({
  title,
  name,
  watch,
  setValue,
  acceptType = [],
  maxFileSize,
}) => {
  const dispatch = useAppDispatch();
  const [image, setImage] = React.useState<ImageListType>([]);
  const imageField = watch(name);

  useEffect(() => {
    if (imageField) {
      setImage([{ dataURL: imageField }]);
    } else {
      setImage([]);
    }
  }, [imageField]);

  const onChange = (imageList: ImageListType) => {
    if (imageList.length > 0) {
      const file = imageList[0];
      const formData = new FormData();

      if (file.file) {
        formData.append('image', file.file);

        dispatch(uploadImage(formData)).then((res) => {
          if (res.payload) {
            setImage([{ dataURL: res.payload.image, file: file.file }]);
            setValue(name, res.payload.image);
          }
        });
      }
    }
  };

  const handleImageRemove = () => {
    setImage([]);
    setValue(name, null);
  };

  const handleError = (errors: ErrorsType) => {
    if (errors?.maxFileSize && maxFileSize) {
      showToast({
        title: 'File error',
        description: `Selected file size exceed maximum file size (${maxFileSize / 1e6}MB)`,
      });
    }

    if (errors?.acceptType && acceptType) {
      showToast({
        title: 'File error',
        description: `Your selected file type is not allow (Allowed types: ${acceptType.join(
          ', ',
        )})`,
      });
    }
  };

  return (
    <ImageUploading
      value={image}
      onChange={onChange}
      maxNumber={1}
      acceptType={acceptType}
      maxFileSize={maxFileSize}
      dataURLKey='dataURL '
      onError={handleError}
    >
      {({ imageList, onImageUpload, onImageUpdate, onImageRemove, dragProps }) => (
        <div>
          <div
            className={image.length > 0 ? styles.unvisible_uploader : styles.uploader}
            onClick={onImageUpload}
            {...dragProps}
          >
            <img src={ImageIcon} />
            <p className={styles.uploader__title}>{title}</p>
          </div>
          {imageList[0]?.dataURL || imageField ? (
            <div className={styles.uploader}>
              <img
                src={imageList[0]?.dataURL || imageField}
                alt=''
                className={styles.uploader__uploaded_image}
                onClick={() => onImageUpdate(0)}
              />
              <div className={styles.uploader__buttons_block}>
                <p className={styles.uploader__title} onClick={() => onImageUpdate(0)}>
                  Update
                </p>
                <p
                  className={styles.uploader__title}
                  onClick={() => {
                    onImageRemove(0);
                    handleImageRemove();
                  }}
                >
                  Remove
                </p>
              </div>
            </div>
          ) : null}
        </div>
      )}
    </ImageUploading>
  );
};

export default ImageUploader;
