import classnames from 'classnames';
import React, { FC } from 'react';

import { CheckboxGroupItem } from 'types/Forms';

import styles from '../NewCheckbox/NewCheckbox.module.scss';

type CheckboxGroupProps = {
  disabled?: boolean;
  readOnly?: boolean;
  className?: string;
  label: string;
  required?: boolean;
  labelClassName?: string;
  //TODO: enum for item
  onChange: (item: CheckboxGroupItem) => void;
  checked: boolean;
  id: number;
  continent?: string | undefined;
  code?: string;
};

const CheckboxGroup: FC<CheckboxGroupProps> = ({
  disabled = false,
  readOnly = false,
  className,
  label,
  onChange,
  labelClassName,
  checked,
  id,
  continent,
  code,
}) => {
  return (
    <div className={classnames(styles.wrapper)}>
      <input
        className={classnames(styles.wrapper__checkbox)}
        disabled={disabled}
        readOnly={readOnly}
        type='checkbox'
        id={`${label}_${id}`}
        onChange={() => {
          if (onChange) {
            onChange({ id, checked: !checked, label, continent, code });
          }
        }}
      />
      {Boolean(label) && (
        <label
          htmlFor={`${label}_${id}`}
          className={classnames(styles.wrapper__label, labelClassName)}
        >
          <div
            className={classnames(
              styles.wrapper__label__dot,
              { [styles['wrapper__label__dot--selected']]: checked },
              className,
            )}
          />
          {label}
        </label>
      )}
    </div>
  );
};

export default CheckboxGroup;
