import { ApiMethod, ApiUrlGet } from '../types/Api';
import { CheckboxGroupItem } from '../types/Forms';

import useApi from './useApi';

export type ITopicsResponse = {
  data: ITopicsResponseItem[];
};

export type ITopicsResponseItem = { id: string; title: string; projectId: string };

const convertTopics = (arr: ITopicsResponseItem[]): CheckboxGroupItem[] => {
  return arr.map((topic, index) => ({
    checked: false,
    label: topic.title,
    id: index,
  }));
};

export default function useTopics(projectId: string) {
  const {
    data: arr,
    error,
    isLoading,
  } = useApi<ITopicsResponse>({
    method: ApiMethod.get,
    url: ApiUrlGet.topics.replace(':id', projectId),
    options: { params: { page: 1, perPage: 100 } },
  });

  const topics = arr?.data && convertTopics(arr.data);

  return { data: arr, error, isLoading, topics };
}
