import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import LoadingBox from 'components/LoadingBox';
import { useAppDispatch, useAppSelector } from 'hooks/storeHooks';
import { fetchProfile, selectIsAuthenticated, selectIsProfileLoading } from 'store/user';

import styles from './AuthLayout.module.scss';

interface AuthLayoutProps {
  loading?: boolean;
  children?: React.ReactNode;
  className?: string;
}

const AuthLayout: React.FC<AuthLayoutProps> = ({ loading = false, children, className }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const isProfileLoading = useAppSelector(selectIsProfileLoading);

  useEffect(() => {
    if (!isAuthenticated) {
      dispatch(fetchProfile());
    } else {
      const currentUrl = window.location.pathname;
      const excludedUrls = ['/signUp/success', '/verify-user/'];

      if (!excludedUrls.includes(currentUrl)) {
        navigate('/projects');
      }
    }
  }, [isAuthenticated, dispatch, navigate]);

  return (
    <div className={styles.auth_layout}>
      <div className={classNames('auth-container', className)}>
        {loading || isProfileLoading ? <LoadingBox /> : children}
        <ToastContainer />
      </div>
    </div>
  );
};

export default AuthLayout;
