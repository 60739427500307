import { yupResolver } from '@hookform/resolvers/yup';
import * as Form from '@radix-ui/react-form';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

import Button from 'components/common/Button';
import PasswordInput from 'components/common/PasswordInput';
import { useAppDispatch } from 'hooks/storeHooks';
import AuthLayout from 'layout/authLayout/index';
import { resetPassword } from 'store/user';
import { IResetPasswordRequestBody } from 'types/Auth';
import { passwordValidation } from 'utils/validations';

import styles from './ResetPassword.module.scss';

const ResetPassword: React.FC = () => {
  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const { resetToken = '' } = useParams();

  const params = new URLSearchParams(search);
  const email = params.get('email');

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<IResetPasswordRequestBody>({
    resolver: yupResolver(passwordValidation),
    defaultValues: {
      email: email || undefined,
      resetToken: decodeURIComponent(resetToken) || undefined,
    },
  });

  const navigate = useNavigate();

  const onSubmit = handleSubmit((data) => {
    return dispatch(resetPassword(data))
      .then((res: any) => {
        if (res.payload) {
          navigate('/projects');
        }
      })
      .catch(() => {});
  });

  const liItems = [<li key='1'>Password must contain at least 6 characters</li>];

  return (
    <AuthLayout loading={isSubmitting} className={styles.reset_password__layout}>
      <h1 className={styles.reset_password__title}>Reset password</h1>
      <Form.Root onSubmit={onSubmit} className={styles.reset_password__form}>
        <PasswordInput
          placeholder='Enter your new password'
          labelName='New password'
          name='password'
          control={control}
        ></PasswordInput>

        <ul className={styles.reset_password__helper}>{liItems} </ul>

        <PasswordInput
          labelName='Confirm password'
          placeholder='Confirm your new password'
          name='confirmedPassword'
          control={control}
        ></PasswordInput>

        <Form.Submit asChild>
          <Button
            disabled={!!Object.keys(errors).length}
            variant='primary'
            className={styles.reset_password__submit}
          >
            Confirm
          </Button>
        </Form.Submit>
      </Form.Root>
    </AuthLayout>
  );
};

export default ResetPassword;
