import * as Form from '@radix-ui/react-form';
import { EyeOpenIcon, EyeNoneIcon, ExclamationTriangleIcon } from '@radix-ui/react-icons';
import React, { useState } from 'react';
import { Controller } from 'react-hook-form';

import { InputPasswordInt } from './interface';
import styles from './PasswordInput.module.scss';

const PasswordInput = (props: InputPasswordInt) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Controller
      {...props}
      render={({ field, fieldState: { invalid, error } }) => (
        <Form.Field className={styles.password__field} name={props.name}>
          <div className={styles.password__label_container}>
            <Form.Label className={styles.password__label}>{props.labelName}</Form.Label>
          </div>

          <Form.Control asChild>
            <input
              {...field}
              checked={field.value}
              onChange={field.onChange}
              className={styles.password__input}
              type={showPassword ? 'text' : 'password'}
              placeholder={props.placeholder}
              {...(!!invalid && { 'data-invalid': true })}
            />
          </Form.Control>

          <div className={styles.password__toggle} onClick={() => setShowPassword(!showPassword)}>
            {error ? (
              <ExclamationTriangleIcon className={styles.password__error_triangle} />
            ) : showPassword ? (
              <EyeNoneIcon />
            ) : (
              <EyeOpenIcon />
            )}
          </div>

          {error && <span className={styles.password__error_label}>{error?.message}</span>}
        </Form.Field>
      )}
    />
  );
};

export default PasswordInput;
