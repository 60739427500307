import classnames from 'classnames';
import React, { ChangeEvent, FC } from 'react';
import { useController, Control } from 'react-hook-form';

import styles from './NewCheckbox.module.scss';

type InputProps = {
  disabled?: boolean;
  readOnly?: boolean;
  className?: string;
  control?: Control<any>;
  name: string;
  label?: string;
  required?: boolean;
  labelClassName?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
};

const Checkbox: FC<InputProps> = ({
  disabled = false,
  readOnly = false,
  className,
  control,
  name,
  label,
  onChange,
  labelClassName,
  checked,
}) => {
  const { field } = useController<Record<string, string>>({
    control,
    name,
  });
  const isChecked = checked || Boolean(field.value);

  return (
    <div className={classnames(styles.wrapper)}>
      <input
        className={classnames(styles.wrapper__checkbox)}
        disabled={disabled}
        readOnly={readOnly}
        type='checkbox'
        id={`${name}_${label}`}
        {...field}
        checked={isChecked}
        onChange={(event) => {
          field.onChange(event);
          if (onChange) {
            onChange(event);
          }
        }}
      />
      <label
        htmlFor={`${name}_${label}`}
        className={classnames(styles.wrapper__label, labelClassName)}
      >
        <div
          className={classnames(
            styles.wrapper__label__dot,
            { [styles['wrapper__label__dot--selected']]: isChecked },
            className,
          )}
        />
        {Boolean(label) && label}
      </label>
    </div>
  );
};

export default Checkbox;
