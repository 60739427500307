import * as SwitchRadix from '@radix-ui/react-switch';
import classnames from 'classnames';
import React, { FC } from 'react';
import { useController, Control, FieldError } from 'react-hook-form';

import styles from './Switch.module.scss';

type SwitchProps = {
  disabled?: boolean;
  className?: string;
  control: Control<any>;
  error?: FieldError;
  name: string;
  label?: string;
  value?: boolean;
  onChange?: (value: boolean) => void;
};

const Switch: FC<SwitchProps> = ({ className, control, name, label, value, onChange, error }) => {
  const { field } = useController<Record<string, string>>({
    control,
    name,
  });

  const handleChange = (e: boolean) => {
    field.onChange(e);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper__control}>
        <SwitchRadix.Root
          {...field}
          onCheckedChange={onChange ? onChange : handleChange}
          checked={value}
          value={field.value}
          className={styles.switch__switch_root}
          id='airplane-mode'
        >
          <SwitchRadix.Thumb className={styles.switch__switch_thumb} />
        </SwitchRadix.Root>
        {Boolean(label) && (
          <label className={styles.wrapper__label}>
            <div className={classnames(styles.wrapper__label__dot, className)} />
            {label}
          </label>
        )}
      </div>
      {error && <span className={styles.wrapper__error_label}>{error?.message}</span>}
    </div>
  );
};
export default Switch;
