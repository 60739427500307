import { SelectOption } from 'types/index';

import { ApiMethod, ApiUrlGet } from '../types/Api';

import useApi from './useApi';

export interface ITimezonesResponse {
  offset: string;
  utc: string;
  name: string;
}

const convertTimezons = (obj: ITimezonesResponse[]): SelectOption[] => {
  return obj.map((item: ITimezonesResponse) => ({
    value: item.offset,
    label: item.utc,
  }));
};

export default function useTimezones() {
  const {
    data: obj,
    error,
    isLoading,
  } = useApi<ITimezonesResponse[]>({
    method: ApiMethod.get,
    url: ApiUrlGet.timezones,
  });
  const convertedData = obj && convertTimezons(obj);

  return { data: convertedData, error, isLoading };
}
