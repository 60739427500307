import * as yup from 'yup';

import { emailValidation } from 'utils/validations';

const schema = yup.object().shape({
  userInvite: yup.object().shape({
    projectId: yup.string(),
    users: yup.array().of(
      yup.object().shape({
        email: emailValidation,
        role: yup.string().required('Role is required'),
      }),
    ),
  }),
});

export default schema;
