import * as yup from 'yup';

const schema = yup.object().shape({
  virtualItem: yup.object().shape({
    sku: yup.string().required('Sku is equired'),
    groups: yup.array().min(0).of(yup.string()),
    name: yup.object().shape({
      en: yup.string().required('Name is required'),
    }),
    description: yup.object().shape({
      en: yup.string().required('Description is required'),
    }),
    inventory_options: yup.object().shape({
      consumable: yup.boolean().required('Item property is required'),
      expiration_period: yup.object().nullable().shape({
        type: yup.string(),
        value: yup.number(),
      }),
    }),
    is_free: yup.boolean().required(),
    prices: yup
      .array()
      .test(
        'is_free',
        'Specify the price in at least one currency for this virtual item.',
        function (prices) {
          const isFree = this.parent?.is_free;
          const vcPrices = this.parent?.vc_prices;

          if (!isFree && (!vcPrices || vcPrices.length === 0)) {
            return prices && prices.length > 0;
          }
          return true;
        },
      )
      .of(
        yup.object().shape({
          amount: yup.number().typeError('Min 1').min(1, 'Min 1').required('Amount is required'),
          currency: yup.string().required('Currency is required'),
          country_iso: yup
            .string()
            .test('country_iso', 'Country iso is required', function (value) {
              const parent = this.parent;

              if (parent && parent.country_iso !== undefined) {
                return !!value;
              }
              return true;
            }),
          is_enabled: yup.boolean().optional(),
          is_default: yup.boolean().optional(),
        }),
      ),
    vc_prices: yup
      .array()
      .test(
        'is_free',
        'Specify the price in at least one currency for this virtual item.',
        function (values) {
          const isFree = this.parent?.is_free;
          const prices = this.parent?.prices;

          if (!isFree && (!prices || prices.length === 0)) {
            return values && values.length > 0;
          }
          return true;
        },
      )
      .of(
        yup.object().shape({
          sku: yup.string().required('Sku is required'),
          amount: yup.number().typeError('Min 1').min(1, 'Min 1').required('Amount is required'),
          is_enabled: yup.boolean().optional(),
          is_default: yup.boolean().optional(),
        }),
      ),
    vc_pricing: yup.boolean(),
    limits_enable: yup.boolean(),
    limits: yup.object().shape({
      per_user: yup.number().nullable().optional(),
      recurrent_schedule: yup
        .object()
        .nullable()
        .optional()
        .shape({
          per_user: yup.object().nullable().optional().shape({
            day_of_month: yup.number().nullable().optional(),
            day_of_week: yup.number().nullable().optional(),
            interval_type: yup.string().optional(),
            time: yup.string().optional(),
          }),
        }),
    }),
    is_show_in_store: yup.boolean().required(),
    periods: yup.array().of(
      yup.object().shape({
        date_from: yup.date().optional(),
        date_until: yup.date().nullable().optional(),
      }),
    ),
  }),
});

export default schema;
