import { createAsyncThunk } from '@reduxjs/toolkit';

import api from '../API/api';
import { ApiMethod, ApiUrlPost, ApiUrlPatch } from '../types/Api';
import { VirtualCurrencyFields, VirtualItemFields } from '../types/Forms';

type CreateVirtualItemType = {
  data: VirtualItemFields;
  projectId: string;
};

export const createVirtualItem = createAsyncThunk(
  '/projects/:id/virtual-items',
  async ({ data, projectId }: CreateVirtualItemType) => {
    const response = await api[ApiMethod.post](
      ApiUrlPost.createVirtualItem.replace(':projectId', projectId),
      data,
    );

    if ('data' in response && response.status === 200) {
      return response.data;
    }
  },
);

type CreateVirtualCurrencyType = {
  data: VirtualCurrencyFields;
  projectId: string;
};

export const createVirtualCurrency = createAsyncThunk(
  '/projects/:id/virtual-items',
  async ({ data, projectId }: CreateVirtualCurrencyType) => {
    const response = await api[ApiMethod.post](
      ApiUrlPost.createVirtualCurrency.replace(':projectId', projectId),
      data,
    );

    if ('data' in response && response.status === 200) {
      return response.data;
    }
  },
);

type UpdateVirtualItemType = {
  data: VirtualItemFields;
  sku: string;
  projectId: string;
};

export const updateVirtualItem = createAsyncThunk(
  '/projects/:id/virtual-items',
  async ({ data, sku, projectId }: UpdateVirtualItemType) => {
    const response = await api[ApiMethod.patch](
      ApiUrlPatch.editVirtualItem.replace(':projectId', projectId).replace(':sku', sku),
      data,
    );

    if ('data' in response && response.status === 200) {
      return response.data;
    }
  },
);

type UpdateVirtualCurrencyType = {
  data: VirtualCurrencyFields;
  sku: string;
  projectId: string;
};

export const updateVirtualCurrency = createAsyncThunk(
  '/projects/:id/virtual-items',
  async ({ data, sku, projectId }: UpdateVirtualCurrencyType) => {
    const response = await api[ApiMethod.patch](
      ApiUrlPatch.editVirtualCurrency.replace(':projectId', projectId).replace(':sku', sku),
      data,
    );

    if ('data' in response && response.status === 200) {
      return response.data;
    }
  },
);
