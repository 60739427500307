import React from 'react';
import { Routes, Route } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

import CreateProject from 'pages/createProject/CreateProject';
import { CreateQuest } from 'pages/createQuest';
import { CreateVirtualCurrency } from 'pages/createVirtualCurrency';
import { CreateVirtualItem } from 'pages/createVirtualItem';
import { ForgotPassword, ForgotPasswordSuccess } from 'pages/forgotPassword';
import { InvitationSignUp } from 'pages/invitationSignUp';
import { Login } from 'pages/login';
import { ManageCompany } from 'pages/manageCompany';
import { ManageUser } from 'pages/manageUser';
import { Projects } from 'pages/projects';
import { ProjectsImport } from 'pages/projectsImport';
import { Quests } from 'pages/quests';
import { ResetPassword } from 'pages/resetPassword';
import { Rewards } from 'pages/rewards';
import { Settings } from 'pages/settings';
import { SignUp, SignUpVerify, SignUpSuccess } from 'pages/signUp';
import { Statistics } from 'pages/statistics';
import Users from 'pages/users/Users';

import './App.css';

function App() {
  return (
    <Routes>
      <Route path='*' element={<>Catch unmatched!</>} />
      <Route path='/' element={<Login />} />
      <Route path='/login' element={<Login />} />
      <Route path='/reset-password/:resetToken' element={<ResetPassword />} />
      <Route path='/forgot-password' element={<ForgotPassword />} />
      <Route path='/forgot-password/success' element={<ForgotPasswordSuccess />} />
      <Route path='/signUp' element={<SignUp />} />
      <Route path='/signUp/success' element={<SignUpSuccess />} />
      <Route path='/verify-user/:id' element={<SignUpVerify />} />
      <Route path='/invite-user/:inviteToken' element={<InvitationSignUp />} />
      <Route path='/manage-company' element={<ManageCompany />} />
      <Route path='/manage-user/:id' element={<ManageUser />} />
      <Route path='/projects' element={<Projects />} />
      <Route path='/projects-import' element={<ProjectsImport />} />
      <Route path='/projects/create' element={<CreateProject />} />
      <Route path='/project/:id/quests' element={<Quests />} />
      <Route path='/project/:id/quests/create' element={<CreateQuest />} />
      <Route path='/project/:id/quests/:questId' element={<CreateQuest isEdit />} />
      <Route path='/project/:id/rewards' element={<Rewards />} />
      <Route path='/project/:id/virtual-items/create' element={<CreateVirtualItem />} />
      <Route path='/project/:id/virtual-items/:sku' element={<CreateVirtualItem isEdit />} />
      <Route path='/project/:id/virtual-currencies/create' element={<CreateVirtualCurrency />} />
      <Route
        path='/project/:id/virtual-currencies/:sku'
        element={<CreateVirtualCurrency isEdit />}
      />
      <Route path='/project/:id/statistics' element={<Statistics />} />
      <Route path='/project/:id/users' element={<Users />} />
      <Route path='/project/:id/settings' element={<Settings />} />
    </Routes>
  );
}

export default App;
