import { yupResolver } from '@hookform/resolvers/yup';
import * as Form from '@radix-ui/react-form';
import React from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';

import Button from 'components/common/Button';
import Checkbox from 'components/common/NewCheckbox';
import Input from 'components/common/NewInput';
import PasswordInput from 'components/common/PasswordInput';
import { useAppDispatch } from 'hooks/storeHooks';
import AuthLayout from 'layout/authLayout/index';
import { fetchProjects } from 'store/project';
import { fetchProfile, login } from 'store/user';
import { ILoginRequestBody } from 'types/Auth';

import styles from './Login.module.scss';
import Schema from './validation';

const Login: React.FC = () => {
  const dispatch = useAppDispatch();
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<ILoginRequestBody>({
    resolver: yupResolver(Schema),
    defaultValues: {
      rememberMe: false,
    },
  });
  const navigate = useNavigate();
  const onSubmit = handleSubmit((data) => {
    return dispatch(login(data)).then((res) => {
      if (res?.payload) {
        dispatch(fetchProfile()).then((profile) => {
          if (profile?.payload?.verified) {
            dispatch(fetchProjects());
          } else {
            navigate('/forgot-password/success');
          }
        });
      }
    });
  });

  return (
    <AuthLayout loading={isSubmitting} className={styles.login__layout}>
      <h1 className={styles.login__title}>Sign in</h1>
      <Form.Root onSubmit={onSubmit} className={styles.login__form}>
        <Input
          label='Email address'
          name='email'
          control={control}
          placeholder='Enter your email'
          error={errors.email}
        />

        <PasswordInput
          labelName='Password'
          name='password'
          control={control}
          placeholder='Enter your password'
        ></PasswordInput>

        <div className={styles.login__row_container}>
          <Checkbox
            name='rememberMe'
            control={control}
            label='Remember me'
            checked={watch('rememberMe')} // @TODO: remove it
          />

          <Link to='/forgot-password' className={styles.login__forgot_password_link}>
            Forgot password?
          </Link>
        </div>

        <Form.Submit asChild>
          <Button
            disabled={!!Object.keys(errors).length}
            variant='primary'
            className={styles.login__submit_login_margin}
          >
            Sign in
          </Button>
        </Form.Submit>
      </Form.Root>

      <Button disabled={false} variant='secondary' className={styles.login__xsola_button_login}>
        Login with Xsolla Publisher Account
      </Button>

      <div className={styles.login__sign_up}>
        Don&apos;t have an account? <Link to='/signUp'>Sing up as Owner</Link>
      </div>
    </AuthLayout>
  );
};

export default Login;
