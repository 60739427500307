import { ChevronDownIcon, FileTextIcon, GearIcon } from '@radix-ui/react-icons';
// import * as Separator from '@radix-ui/react-separator';
import classNames from 'classnames';
import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

import logo from 'assets/headerLogo.svg';
import avatar from 'assets/svg/avatar.svg';
import { useAppDispatch, useAppSelector } from 'hooks/storeHooks';
import { fetchProjects, selectProjects } from 'store/project';
import { logout, selectProfile } from 'store/user';
import { UserRole } from 'types';

// import Balance from '../common/Balance';
import DropdownMenu from '../common/DropdownMenu';

import styles from './Header.module.scss';

const Header = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const profile = useAppSelector(selectProfile);
  const handleLogout = () => dispatch(logout());
  const projects = useAppSelector(selectProjects);
  const currentProject = projects.find((project) => project.id === params.id);

  useEffect(() => {
    dispatch(fetchProjects());
  }, [dispatch]);

  const projectTitles =
    projects.map((project) => ({
      title: project.title,
      id: `ID ${project.xsollaProjectId}`,
      action: () => {
        navigate(`/project/${project.id}/quests`);
        window.location.reload();
      },
    })) || [];
  const projectDropdownItems = [
    projects.length > 0 ? { label: 'Account' } : null,
    ...projectTitles,
    undefined,
    { label: 'Actions' },
    profile?.role === UserRole.OWNER
      ? {
          title: 'Create new project',
          styles: { color: '#0073F7' },
          action: () => navigate('/projects/create'),
        }
      : null,
    { title: 'Show projects', styles: { color: '#0073F7' }, action: () => navigate('/projects') },
  ].filter((i) => i !== null);

  const profileDropdownItems = [
    { label: 'Account' },
    {
      title: 'Manage account',
      styles: { color: '#0073F7' },
      action: () => navigate('/my-profile'),
    },
    { title: 'Log out', styles: { color: '#0073F7' }, action: handleLogout },
  ].filter((i) => i !== null);

  return (
    <div className={styles.header_container}>
      <div className={styles.header_left}>
        <DropdownMenu items={projectDropdownItems}>
          <div className={styles.projects_dropdown}>
            <img className={styles.projects_dropdown__logo} src={logo}></img>
            {currentProject ? (
              <div className={styles.projects_dropdown__info}>
                <div className={styles.projects_dropdown__name}>
                  <span>Project: {currentProject?.title}</span>
                  <ChevronDownIcon />
                </div>
                <div className={styles.projects_dropdown__id}>
                  ID {currentProject?.xsollaProjectId}
                </div>
              </div>
            ) : (
              <div className={styles.projects_dropdown__info}>
                <div className={styles.projects_dropdown__name}>
                  <span>Projects</span>
                  <ChevronDownIcon />
                </div>
              </div>
            )}
          </div>
        </DropdownMenu>
      </div>

      <div className={styles.header_right}>
        <a target='_blank' href={process.env.REACT_APP_DOCS_HOST} rel='noreferrer'>
          <div
            className={classNames(
              styles.header_right_item_content,
              styles.header_right_item_margin,
            )}
          >
            <FileTextIcon />
            <div className={styles.header_right_item_text}>Documentation</div>
          </div>
        </a>

        {profile?.role === UserRole.OWNER ? (
          <Link to='/manage-company'>
            <span
              className={classNames(
                styles.header_right_item_content,
                styles.header_right_item_margin,
              )}
            >
              <GearIcon className={styles.header_container__icon} />
              <div className={styles.header_right_item_text}>Manage company</div>
            </span>
          </Link>
        ) : null}

        {/* <Separator.Root orientation='vertical' className={styles.separator} />

        <Balance optional='1 completed quest = 1 credits' credits='100' /> */}

        <DropdownMenu items={profileDropdownItems}>
          <div className={classNames(styles.header_right_item, styles.header_right_item_margin)}>
            <div className={styles.header_container__avatar_container}>
              <img src={avatar} />
            </div>
          </div>
        </DropdownMenu>
      </div>
    </div>
  );
};

export default Header;
