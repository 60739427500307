import {
  CalendarIcon,
  CheckCircledIcon,
  CircleBackslashIcon,
  DotFilledIcon,
  FileIcon,
} from '@radix-ui/react-icons';
import classNames from 'classnames';
import React from 'react';

import styles from './Status.module.scss';

interface StatusProps {
  value?: TableStatus;
}

export enum TableStatus {
  active = 'active',
  disabled = 'disabled',
  draft = 'draft',
  finished = 'finished',
  planned = 'planned',
}

const getStatusIcon = (value?: TableStatus) => {
  switch (value) {
    case TableStatus.active:
      return <CheckCircledIcon />;
    case TableStatus.disabled:
      return <CircleBackslashIcon />;
    case TableStatus.draft:
      return <FileIcon />;
    case TableStatus.finished:
      return <DotFilledIcon />;
    case TableStatus.planned:
      return <CalendarIcon />;
  }
};

const getStatusText = (value?: TableStatus) => {
  switch (value) {
    case TableStatus.active:
      return 'Active';
    case TableStatus.disabled:
      return 'Disabled';
    case TableStatus.draft:
      return 'Draft';
    case TableStatus.finished:
      return 'Finished';
    case TableStatus.planned:
      return 'Planned';
  }
};

const Status: React.FC<StatusProps> = ({ value }) => {
  return (
    <div className={classNames(styles.status, styles[`status--${value}`])}>
      <span className={styles.status__icon}>{getStatusIcon(value)}</span>
      <span className={styles.status__text}>{getStatusText(value)}</span>
    </div>
  );
};

export default Status;
